import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';
import { css } from '@emotion/core';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TimelineIcon from '@material-ui/icons/Timeline';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { useStatusTrackerFeatureFlag } from '../hooks/useGetFeatureFlag';

import mixpanel from '../utils/mixpanel';
import { DRAWER_WIDTH } from '../constants/styles';
import {
  SITE_DETAILS_OVERVIEW_PATH,
  SITE_DETAILS_CONTACTS_PATH,
  SITE_DETAILS_PROJECT_STATUS_PATH,
  SERVICE_CONTRACT_OVERVIEW_PATH,
  SERVICE_CONTRACT_PROMOTIONS_PATH,
  ACTIVITY_PATH,
  BILLING_PATH,
  SYSTEM_OVERVIEW_PATH,
  SYSTEM_PERFORMANCE_PATH,
  DOCUMENTS_PATH,
  HOME_ENERGY_REPORT_PATH,
  pathToRoute,
  HOME_ENERGY_REPORT_LIST_PATH,
  LD_NOTES_PATH,
} from '../constants/routes';
import { useUserAuthInfo } from '../components/auth';
import useEnvConfig from '../hooks/useEnvConfig';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    zIndex: 100,
  },
}));

const NavLinkListItem = ({ mixpanelTabName, to, children }) => (
  <ListItem
    button
    component={NavLink}
    css={(theme) => css`
      &.active {
        border-right: 4px solid ${theme.palette.primary.main};
        background-color: ${theme.palette.type === 'dark' ? theme.palette.divider : theme.palette.sunrun.lightBlue};
      }
    `}
    to={to}
    onClick={() => mixpanel.track('Clicked Navigation Item', { 'Tab Name': mixpanelTabName })}
  >
    {children}
  </ListItem>
);

NavLinkListItem.propTypes = {
  children: PropTypes.any.isRequired,
  mixpanelTabName: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const NavGroup = ({ icon, text, to, children, isBeta }) => {
  const [isOpen, setOpen] = useState(true);
  return (
    <>
      <Divider />
      <NavLinkListItem mixpanelTabName={text} to={to}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {isBeta ? (
          <Badge badgeContent="beta" color="primary">
            <ListItemText primary={text} />
          </Badge>
        ) : (
          <ListItemText primary={text} />
        )}
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            onClick={() => {
              mixpanel.track(`Toggled ${text} navigation section`, {
                action: isOpen ? 'collapse' : 'expand',
              });
              setOpen(!isOpen);
            }}
            aria-label={`${isOpen ? 'Collapse' : 'Expand'} ${text} section`}
          >
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </NavLinkListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List disablePadding dense>
          {children}
        </List>
      </Collapse>
    </>
  );
};

NavGroup.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.node,
  isBeta: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const NavSubItem = ({ text, to, isBeta }) => (
  <NavLinkListItem mixpanelTabName={text} to={to}>
    {isBeta ? (
      <Badge badgeContent="beta" color="primary">
        <ListItemText primary={text} />
      </Badge>
    ) : (
      <ListItemText inset primary={text} />
    )}
  </NavLinkListItem>
);

NavSubItem.propTypes = {
  isBeta: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const NavItem = ({ icon, text, to, isBeta }) => (
  <>
    <Divider />
    <NavLinkListItem mixpanelTabName={text} to={to}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      {isBeta ? (
        <Badge badgeContent="beta" color="primary">
          <ListItemText primary={text} />
        </Badge>
      ) : (
        <ListItemText primary={text} />
      )}
    </NavLinkListItem>
  </>
);

NavItem.propTypes = {
  icon: PropTypes.node,
  isBeta: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const SideNav = ({ isVivintSolarPostPTO, isPrePTO }) => {
  const { isViewer, isUser } = useUserAuthInfo();
  const isProjectStatusTrackerEnabled = useStatusTrackerFeatureFlag();
  const { enableLeaseDimensions } = useEnvConfig();
  const classes = useStyles();
  const { prospectId } = useParams();
  const prospectRoute = (path) => pathToRoute(path, { prospectId });

  return (
    <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
      <div css={(theme) => theme.mixins.toolbar}></div>
      <List disablePadding>
        <NavGroup icon={<InfoIcon />} text="Site Details" to={prospectRoute(SITE_DETAILS_OVERVIEW_PATH)}>
          <NavSubItem text="Contacts" to={prospectRoute(SITE_DETAILS_CONTACTS_PATH)} />
          {isProjectStatusTrackerEnabled && isPrePTO && (
            <NavSubItem text="Project Status" to={prospectRoute(SITE_DETAILS_PROJECT_STATUS_PATH)} />
          )}
        </NavGroup>
        <NavGroup icon={<ReceiptIcon />} text="Service Contract" to={prospectRoute(SERVICE_CONTRACT_OVERVIEW_PATH)}>
          <NavSubItem text="Promotions" to={prospectRoute(SERVICE_CONTRACT_PROMOTIONS_PATH)} />
        </NavGroup>
        <NavItem icon={<EventNoteIcon />} text="Activity" to={prospectRoute(ACTIVITY_PATH)} isBeta />

        {isViewer &&
          (isVivintSolarPostPTO && enableLeaseDimensions ? (
            <NavGroup icon={<AttachMoneyIcon />} text="Billing" to={prospectRoute(BILLING_PATH)}>
              <NavSubItem text="LD Notes" to={prospectRoute(LD_NOTES_PATH)} />
            </NavGroup>
          ) : (
            <NavItem icon={<AttachMoneyIcon />} text="Billing" to={prospectRoute(BILLING_PATH)} />
          ))}
        <NavGroup icon={<TimelineIcon />} text="System" to={prospectRoute(SYSTEM_OVERVIEW_PATH)}>
          <NavSubItem text="Performance" to={prospectRoute(SYSTEM_PERFORMANCE_PATH)} />
        </NavGroup>
        <NavItem icon={<DescriptionIcon />} text="Documents" to={prospectRoute(DOCUMENTS_PATH)} />
        {isUser && (
          <NavGroup icon={<FlashOnIcon />} text="Home Energy Report" to={prospectRoute(HOME_ENERGY_REPORT_PATH)}>
            <NavSubItem text="All Reports" to={prospectRoute(HOME_ENERGY_REPORT_LIST_PATH)} />
          </NavGroup>
        )}
      </List>
    </Drawer>
  );
};

SideNav.propTypes = {
  isVivintSolarPostPTO: PropTypes.bool.isRequired,
  isPrePTO: PropTypes.bool.isRequired,
};

export default SideNav;
