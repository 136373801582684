import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import MaterialTable from 'material-table';
import tableIcons from '../constants/tableIcons';
import TableContainer from '@material-ui/core/TableContainer';

import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';
import parseInteger from '../utils/parse-int';
import { prepareMaterialTableData } from '../utils/helpers';

const ENTITLEMENTS_CARD_FRAGMENT = gql`
  fragment EntitlementsCardFields on Prospect {
    id
    entitlements {
      edges {
        node {
          id
          name
          endDate
          entitlementTerm
        }
      }
    }
  }
`;

const EntitlementsCard = ({ loading, data }) => {
  const entitlements = prepareMaterialTableData(unwrapGraphQlList(data, 'prospect.entitlements.edges'));

  return (
    <CollapsableCard title="Entitlements">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MaterialTable
            columns={[
              { title: 'Name', field: 'name' },
              {
                title: 'End Date',
                field: 'endDate',
                type: 'date',
              },
              {
                title: 'Term Length',
                field: 'entitlementTerm',
                render: (rowData) => `${parseInteger(rowData.entitlementTerm)} years`,
              },
            ]}
            isLoading={loading}
            data={entitlements}
            options={{
              toolbar: false, // we are showing our own title for better alignment
              pageSize: 5,
              paging: entitlements?.length > 5,
              pageSizeOptions: [5, 10, 25],
              draggable: false,
              sorting: false,
              search: false,
              showEmptyDataSourceMessage: !loading,
            }}
            localization={{
              body: { emptyDataSourceMessage: 'No entitlements associated with this contract' },
            }}
            components={{
              Container: TableContainer, // don't put it on Paper
            }}
            icons={tableIcons}
          />
        </Grid>
      </Grid>
    </CollapsableCard>
  );
};

EntitlementsCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      entitlements: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
              endDate: PropTypes.string,
              entitlementTerm: PropTypes.string,
              createdDate: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
};

export { ENTITLEMENTS_CARD_FRAGMENT };
export default EntitlementsCard;
