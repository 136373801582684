import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';
import DocumentsTable, { DOCUMENT_TABLE_FRAGMENT } from './DocumentsTable';

const GENERAL_DOCUMENTS_TABLE_QUERY = gql`
  query GeneralDocumentsTableQuery($prospectId: String!, $noFilter: Boolean) {
    prospect(prospectId: $prospectId) {
      id
      generalDocuments(noFilter: $noFilter) {
        edges {
          node {
            ...DocumentTableFields
          }
        }
      }
    }
  }
  ${DOCUMENT_TABLE_FRAGMENT}
`;

const GeneralDocumentsTable = ({ viewAll }) => {
  const { prospectId } = useParams();
  const { data, loading } = useQuery(GENERAL_DOCUMENTS_TABLE_QUERY, { variables: { prospectId, noFilter: viewAll } });
  const documents = unwrapGraphQlList(data, 'prospect.generalDocuments.edges');
  return <DocumentsTable documents={documents} loading={loading} documentsTab="General" />;
};

GeneralDocumentsTable.propTypes = {
  viewAll: PropTypes.bool.isRequired,
};

export { GENERAL_DOCUMENTS_TABLE_QUERY };
export default GeneralDocumentsTable;
