import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';
import DocumentsTable, { DOCUMENT_TABLE_FRAGMENT } from './DocumentsTable';

const SERVICE_CONTRACT_DOCUMENTS_TABLE_QUERY = gql`
  query ServiceContractDocumentsTableQuery($prospectId: String!, $noFilter: Boolean) {
    prospect(prospectId: $prospectId) {
      id
      serviceContractDocuments(noFilter: $noFilter) {
        edges {
          node {
            ...DocumentTableFields
          }
        }
      }
    }
  }
  ${DOCUMENT_TABLE_FRAGMENT}
`;

const ServiceContractDocumentsTable = ({ viewAll }) => {
  const { prospectId } = useParams();
  const { data, loading } = useQuery(SERVICE_CONTRACT_DOCUMENTS_TABLE_QUERY, {
    variables: { prospectId, noFilter: viewAll },
  });
  const documents = unwrapGraphQlList(data, 'prospect.serviceContractDocuments.edges');
  return <DocumentsTable documents={documents} loading={loading} documentsTab="Service Contract" />;
};

ServiceContractDocumentsTable.propTypes = {
  viewAll: PropTypes.bool.isRequired,
};

export { SERVICE_CONTRACT_DOCUMENTS_TABLE_QUERY };
export default ServiceContractDocumentsTable;
