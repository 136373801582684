import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { useParams, Route, Switch } from 'react-router-dom';
import { DateTime } from 'luxon';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BalanceAndStatus, { BALANCE_AND_STATUS_FRAGMENT } from './BalanceAndStatus';
import MostRecentInvoice, { MOST_RECENT_INVOICE_FRAGMENT } from './MostRecentInvoice';
import Autopay, { AUTOPAY_FRAGMENT } from './Autopay';
import OwnerSelectCard, { OWNER_SELECT_CARD_FRAGMENT } from './OwnerSelectCard';
import SpecialInvoices, { SPECIAL_INVOICES_FRAGMENT } from './SpecialInvoices';
import TransactionHistory, { TRANSACTION_HISTORY_FRAGMENT } from './TransactionHistory';
import CollectionsNotes, { COLLECTIONS_NOTES_FRAGMENT } from './CollectionsNotes';
import Reversals, { REVERSALS_FRAGMENT } from './Reversals';
import ConfirmationModal from './ConfirmationModal';
import { PAYMENT_CONFIRMATION_PATH, AUTOPAY_CONFIRMATION_PATH } from '../constants/routes';

const BILLING_PAGE_QUERY = gql`
  query BillingPageQuery($prospectId: String, $contactId: ID!) {
    prospect(prospectId: $prospectId) {
      id
      isVivintSolarPostPTO
      billing(contactId: $contactId) {
        id
        lastModifiedDate
      }
      ...BalanceAndStatusFields
      ...MostRecentInvoiceFields
      ...AutopayFields
      ...OwnerSelectCardFields
      ...CollectionsNotesFields
      ...SpecialInvoiceFields
      ...TransactionHistoryFields
      ...ReversalsFields
    }
  }
  ${BALANCE_AND_STATUS_FRAGMENT}
  ${MOST_RECENT_INVOICE_FRAGMENT}
  ${AUTOPAY_FRAGMENT}
  ${OWNER_SELECT_CARD_FRAGMENT}
  ${COLLECTIONS_NOTES_FRAGMENT}
  ${SPECIAL_INVOICES_FRAGMENT}
  ${TRANSACTION_HISTORY_FRAGMENT}
  ${REVERSALS_FRAGMENT}
`;

const BillingPage = () => {
  const { prospectId, contactId } = useParams();
  const { loading, data } = useQuery(BILLING_PAGE_QUERY, {
    variables: { prospectId, contactId },
    returnPartialData: true,
  });
  const lastModifiedDate = data?.prospect?.billing?.lastModifiedDate;
  const isVivintSolarPostPTO = data?.prospect?.isVivintSolarPostPTO;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={2} alignItems="stretch">
          <BalanceAndStatus data={data} loading={loading} isVivintSolarPostPTO={isVivintSolarPostPTO} />
          <MostRecentInvoice data={data} />
          <Autopay data={data} loading={loading} isVivintSolarPostPTO={isVivintSolarPostPTO} />
          <OwnerSelectCard data={data} loading={loading} />
        </Grid>
        {lastModifiedDate && (
          <Typography variant="caption">
            Last Updated {DateTime.fromISO(lastModifiedDate, { setZone: true }).toLocaleString(DateTime.DATETIME_FULL)}
          </Typography>
        )}
      </Grid>
      <CollectionsNotes data={data} />
      <SpecialInvoices data={data} loading={loading} />
      <TransactionHistory data={data} loading={loading} />
      <Reversals data={data} />
      <Switch>
        <Route path={PAYMENT_CONFIRMATION_PATH}>
          <ConfirmationModal />
        </Route>
        <Route path={AUTOPAY_CONFIRMATION_PATH}>
          <ConfirmationModal />
        </Route>
      </Switch>
    </Grid>
  );
};

export { BILLING_PAGE_QUERY };
export default BillingPage;
