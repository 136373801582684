import memoizeOne from 'memoize-one';

import { LOCAL, DEVELOP, STAGE, PRODUCTION } from '../constants/environments';

export const envConfigMapping = {
  [LOCAL]: {
    oktaConfig: {
      issuer: 'https://sunrun.okta.com/oauth2/ausmczuf18aibgEGl0x7',
      redirectUri: 'https://customer-spotlight.sunrundev.com:6002/implicit/callback',
      clientId: '0oamc9ceokBeyJWGJ0x7',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
    },
    enableRunningBalance: true,
    enableLeaseDimensions: true,
    enableInverterPortal: true,
    enableSystemStatus: false,
    performanceChartsBaseURL: 'https://gateway.sunrundev.com/devmaj-performance-api',
    paymentEnv: 'devmaj',
    homeEnergyReportAPIUrl: 'https://gateway.sunrundev.com/devmaj-energy-report-service/',
    questionAnswerAPIUrl: 'https://gateway.sunrundev.com/local-question-answer',
    statusTrackerAPIUrl: 'https://gateway.sunrundev.com/devmaj-status-tracker',
    userAPIUrl: 'https://gateway.sunrundev.com/local-portal-user',
    customerSystemStatusServiceAPIUrl: 'https://gateway.sunrundev.com/devmaj-customer-system-status',
    flagsmithEnvironmentID: 'KWbiYDFLCbXWuHChKnJfez',
  },
  [DEVELOP]: {
    oktaConfig: {
      issuer: 'https://sunrun.okta.com/oauth2/ausmczuf18aibgEGl0x7',
      redirectUri: 'https://devmaj-customer-spotlight.sunrundev.com/implicit/callback',
      clientId: '0oamc9dvmb6gIednp0x7',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
    },
    enableRunningBalance: true,
    enableLeaseDimensions: true,
    enableInverterPortal: true,
    enableSystemStatus: true,
    performanceChartsBaseURL: 'https://gateway.sunrundev.com/devmaj-performance-api',
    paymentEnv: 'devmaj',
    homeEnergyReportAPIUrl: 'https://gateway.sunrundev.com/devmaj-energy-report-service/',
    questionAnswerAPIUrl: 'https://gateway.sunrundev.com/devmaj-question-answer',
    statusTrackerAPIUrl: 'https://gateway.sunrundev.com/devmaj-status-tracker',
    userAPIUrl: 'https://gateway.sunrundev.com/devmaj-portal-user',
    customerSystemStatusServiceAPIUrl: 'https://gateway.sunrundev.com/devmaj-customer-system-status',
    flagsmithEnvironmentID: 'AkyHP2wBjtmfaSzjCEvGNE',
  },
  [STAGE]: {
    oktaConfig: {
      issuer: 'https://sunrun.okta.com/oauth2/ausmczu54o7q9kVnC0x7',
      redirectUri: 'https://majstg-customer-spotlight.sunrun.com/implicit/callback',
      clientId: '0oamc9b5gkow1ewNj0x7',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
    },
    enableRunningBalance: false,
    enableLeaseDimensions: true,
    enableInverterPortal: true,
    enableMaps: true,
    enableSystemStatus: true,
    mapsAPIKey: 'AIzaSyBxiZUa6et6MO5-VCEBTH6UmjvqqaC95h8',
    performanceChartsBaseURL: 'https://gateway.sunrun.com/majstg-performance-api',
    paymentEnv: 'majstg',
    homeEnergyReportAPIUrl: 'https://gateway.sunrun.com/majstg-energy-report-service/',
    questionAnswerAPIUrl: 'https://gateway.sunrun.com/majstg-question-answer',
    statusTrackerAPIUrl: 'https://gateway.sunrun.com/majstg-status-tracker',
    userAPIUrl: 'https://gateway.sunrun.com/majstg-portal-user',
    customerSystemStatusServiceAPIUrl: 'https://gateway.sunrun.com/majstg-customer-system-status',
    flagsmithEnvironmentID: 'KWbiYDFLCbXWuHChKnJfez',
  },
  [PRODUCTION]: {
    oktaConfig: {
      issuer: 'https://sunrun.okta.com/oauth2/ausmczu5aa5Hf6LIY0x7',
      redirectUri: 'https://customer-spotlight.sunrun.com/implicit/callback',
      clientId: '0oamc9cemoAJdgpWL0x7',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
    },
    enableRunningBalance: false,
    enableLeaseDimensions: true,
    enableInverterPortal: true,
    enableMaps: true,
    enableSystemStatus: true,
    mapsAPIKey: 'AIzaSyBoEuqgP5CRCNr3Al7Tk0W_qZZteUaaX18',
    performanceChartsBaseURL: 'https://gateway.sunrun.com/performance-api',
    paymentEnv: 'prd',
    homeEnergyReportAPIUrl: 'https://gateway.sunrun.com/energy-report-service/',
    questionAnswerAPIUrl: 'https://gateway.sunrun.com/question-answer',
    statusTrackerAPIUrl: 'https://gateway.sunrun.com/status-tracker',
    userAPIUrl: 'https://gateway.sunrun.com/portal-user',
    customerSystemStatusServiceAPIUrl: 'https://gateway.sunrun.com/customer-system-status',
    flagsmithEnvironmentID: 'noF7ZmcLCLxn7D5csG4Xyh',
  },
};

const getConfig = (env) => envConfigMapping[env] || envConfigMapping[LOCAL];

export default memoizeOne(getConfig);
