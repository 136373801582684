import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import { css } from '@emotion/core';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';
import Link from '@material-ui/core/Link';

import formatPhone from '../utils/format-phone';

const PROJECT_CARD_FRAGMENT = gql`
  fragment ProjectCardFields on Prospect {
    id
    salesBranch
    installPartner
    salesPartner
    installBranch
    buildPartner
    designEngineerName
    installCoordinatorName
    projectCoordinatorName
    salesPersonName
    salesRepWorkPhone
    projectCoordinatorPhone
    salesRepEmail
    installCoordinatorEmail
    projectCoordinatorEmail
    oracleProjectNumber
  }
`;

const getInitials = (name) => {
  if (!name) return '';

  const names = name.split(' ');
  let initials = names[0].substring(0, 1);

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1);
  }
  return initials.toUpperCase();
};

const TeamCard = ({ name, title, sunrunColor, email, phone }) => {
  return (
    <Grid item xs={12} md={4} lg={3} xl={2}>
      <Paper
        css={(theme) => css`
          height: 100%;
          padding: ${theme.spacing(1)}px;
        `}
        variant="outlined"
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          css={(theme) => css`
            margin-bottom: ${theme.spacing(0.5)}px;
          `}
          wrap="nowrap"
        >
          <Grid item>
            <Avatar
              css={(theme) => css`
                color: ${theme.palette.common.white};
                background-color: ${theme.palette.sunrun[sunrunColor]};
              `}
            >
              {getInitials(name)}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h6">{title}</Typography>
          </Grid>
        </Grid>
        <Typography noWrap>{name}</Typography>
        {email && (
          <Typography noWrap>
            <Link href={`mailto:${email}`} target="_blank" rel="noreferrer noopener">
              {email}
            </Link>
          </Typography>
        )}
        <Typography noWrap>{formatPhone(phone)}</Typography>
      </Paper>
    </Grid>
  );
};

TeamCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sunrunColor: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
};

const ProjectCard = ({ loading, data }) => {
  const {
    salesBranch,
    installPartner,
    salesPartner,
    installBranch,
    buildPartner,
    designEngineerName,
    installCoordinatorName,
    projectCoordinatorName,
    salesPersonName,
    oracleProjectNumber,
    salesRepWorkPhone,
    projectCoordinatorPhone,
    salesRepEmail,
    installCoordinatorEmail,
    projectCoordinatorEmail,
  } = data?.prospect || {};

  const hasATeam = designEngineerName || installCoordinatorName || projectCoordinatorName || salesPersonName;

  return (
    <CollapsableCard
      title={
        oracleProjectNumber ? (
          <Grid container spacing={1}>
            <Grid item>Project: {oracleProjectNumber}</Grid>
            <Grid item>
              <Tooltip title="Oracle project number" placement="top">
                <HelpIcon fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          'Project'
        )
      }
      mixpanelEventName="Toggled Site Details > Project card expand/collapse"
    >
      <Grid container spacing={hasATeam ? 4 : 0} direction="column">
        <Grid item container spacing={1} alignItems="stretch">
          {designEngineerName && <TeamCard title="Design Engineer" sunrunColor="red" name={designEngineerName} />}
          {installCoordinatorName && (
            <TeamCard
              title="Install Coordinator"
              sunrunColor="blue"
              name={installCoordinatorName}
              email={installCoordinatorEmail}
            />
          )}
          {projectCoordinatorName && (
            <TeamCard
              title="Project Coordinator"
              sunrunColor="green"
              name={projectCoordinatorName}
              phone={projectCoordinatorPhone}
              email={projectCoordinatorEmail}
            />
          )}
          {salesPersonName && (
            <TeamCard
              title="Sales Person"
              sunrunColor="yellow"
              name={salesPersonName}
              phone={salesRepWorkPhone}
              email={salesRepEmail}
            />
          )}
        </Grid>
        {hasATeam && (
          <Divider
            css={(theme) => css`
              margin-left: ${theme.spacing(-1)}px;
              margin-right: ${theme.spacing(-1)}px;
            `}
          />
        )}
        <Grid item container spacing={1}>
          <LabeledValue loading={loading} label="Sales Branch" text={salesBranch} />
          <LabeledValue loading={loading} label="Sales Partner" text={salesPartner} />
          <LabeledValue loading={loading} label="Install Branch" text={installBranch} />
          <LabeledValue loading={loading} label="Install Partner" text={installPartner} />
          <LabeledValue loading={loading} label="Build Partner" text={buildPartner} />
        </Grid>
      </Grid>
    </CollapsableCard>
  );
};

ProjectCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      salesBranch: PropTypes.string,
      installPartner: PropTypes.string,
      buildPartner: PropTypes.string,
      installCoordinatorName: PropTypes.string,
      projectCoordinatorName: PropTypes.string,
      salesPersonName: PropTypes.string,
      oracleProjectNumber: PropTypes.string,
      salesRepWorkPhone: PropTypes.string,
      projectCoordinatorPhone: PropTypes.string,
      salesRepEmail: PropTypes.string,
      installCoordinatorEmail: PropTypes.string,
      projectCoordinatorEmail: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
};

export { PROJECT_CARD_FRAGMENT, getInitials };
export default ProjectCard;
