import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import MaterialTable from 'material-table';
import tableIcons from '../constants/tableIcons';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { prepareMaterialTableData, formatDateField } from '../utils/helpers';

const REVERSALS_FRAGMENT = gql`
  fragment ReversalsFields on Prospect {
    id
    billing(contactId: $contactId) {
      id
      reversals {
        id
        amount
        date
        reversalDate
        reason
        comments
      }
    }
  }
`;

const Reversals = ({ data }) => {
  const reversals = prepareMaterialTableData(data?.prospect?.billing?.reversals);
  if (!reversals || reversals.length === 0) return null;
  const numberOfReversalsInTheLast30Days = reversals.filter(
    (reversal) => DateTime.fromISO(reversal.reversalDate, { setZone: true }).diffNow('days').values.days > -30,
  ).length;
  return (
    <Grid item xs={12}>
      <MaterialTable
        title={
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="h6">Reversals</Typography>
            </Grid>
            {numberOfReversalsInTheLast30Days > 0 && (
              <Grid item>
                <Chip color="primary" label={`${numberOfReversalsInTheLast30Days} New Reversals`} />
              </Grid>
            )}
          </Grid>
        }
        columns={[
          {
            title: 'Receipt Date',
            field: 'date',
            type: 'date',
            render: (reversal) => formatDateField(reversal?.date),
          },
          {
            title: 'Reversal Date',
            field: 'reversalDate',
            type: 'date',
            defaultSort: 'desc',
            render: (reversal) => formatDateField(reversal?.reversalDate),
          },
          { title: 'Amount', field: 'amount', type: 'currency', sorting: false, cellStyle: { textAlign: 'left' } },
          { title: 'Reason', field: 'reason' },
          { title: 'Comments', field: 'comments' },
        ]}
        data={reversals}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          emptyRowsWhenPaging: false,
          paging: reversals.length > 10,
          draggable: false,
        }}
        icons={tableIcons}
      />
    </Grid>
  );
};

Reversals.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      billing: PropTypes.shape({
        reversals: PropTypes.arrayOf(
          PropTypes.shape({
            amount: PropTypes.number,
            date: PropTypes.string,
            reversalDate: PropTypes.string,
            reason: PropTypes.string,
            comments: PropTypes.string,
          }),
        ),
      }),
    }),
  }),
};

export { REVERSALS_FRAGMENT };
export default Reversals;
