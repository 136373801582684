import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';

const BATTERY_INFORMATION_CARD_FRAGMENT = gql`
  fragment BatteryInformationCardFields on Prospect {
    id
    batteries {
      edges {
        node {
          id
          vendorName
          serialNumber
          manufacturer
        }
      }
    }
  }
`;

const SingleBattery = ({ loading, batteryChars, batteryName }) => (
  <Grid container direction="row" spacing={1} item xs={12}>
    <Grid item xs={12}>
      <Typography variant="h6">{batteryName}</Typography>
    </Grid>
    <LabeledValue loading={loading} label="Manufacturer" text={batteryChars.manufacturer} />
    <LabeledValue loading={loading} label="Serial Number" text={batteryChars.serialNumber} />
    <LabeledValue loading={loading} label="Vendor" text={batteryChars.vendorName} />
  </Grid>
);

SingleBattery.propTypes = {
  loading: PropTypes.bool.isRequired,
  batteryName: PropTypes.string.isRequired,
  batteryChars: PropTypes.shape({
    vendorName: PropTypes.string,
    serialNumber: PropTypes.string,
    manufacturer: PropTypes.string,
  }).isRequired,
};

const BatteryInformationCard = ({ loading, data }) => {
  const batteries = unwrapGraphQlList(data, 'prospect.batteries.edges');
  return (
    <CollapsableCard title="Battery Information">
      <Grid container direction="column" spacing={2}>
        {batteries.length ? (
          batteries.map((battery, i) => (
            <SingleBattery
              key={`battery-${battery.serialNumber}`}
              loading={loading}
              batteryChars={battery}
              batteryName={`Battery ${i + 1}`}
            />
          ))
        ) : loading ? (
          <SingleBattery
            key={`battery-information-loading`}
            loading={true}
            batteryChars={{}} // show a loading state
            batteryName={`Battery ?`}
          />
        ) : (
          <Typography align="center">No battery information found</Typography>
        )}
      </Grid>
    </CollapsableCard>
  );
};

BatteryInformationCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      batteries: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: SingleBattery.propTypes.batteryChars,
          }),
        ),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
};

export { BATTERY_INFORMATION_CARD_FRAGMENT };
export default BatteryInformationCard;
