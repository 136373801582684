import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import { useUserAuthInfo } from '../components/auth';

import ServiceContractGeneralCard, { SERVICE_CONTRACT_GENERAL_CARD_FRAGMENT } from './ServiceContractGeneralCard';
import PerformanceGuaranteeCard, { PERFORMANCE_GUARANTEE_CARD_FRAGMENT } from './PerformanceGuaranteeCard';
import FinancialInformationCard, { FINANCIAL_INFORMATION_CARD_FRAGMENT } from './FinancialInformationCard';
import PricingInformationCard, { PRICING_INFORMATION_CARD_FRAGMENT } from './PricingInformationCard';
import EntitlementsCard, { ENTITLEMENTS_CARD_FRAGMENT } from './EntitlementsCard';

const SERVICE_CONTRACT_QUERY = gql`
  query ServiceContractQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      id
      serviceContract {
        id
        ...ServiceContractGeneralCardFields
        ...PerformanceGuaranteeCardFields
        ...FinancialInformationCardFields
        ...PricingInformationCardFields
      }
      ...EntitlementsCardFields
    }
  }
  ${SERVICE_CONTRACT_GENERAL_CARD_FRAGMENT}
  ${PERFORMANCE_GUARANTEE_CARD_FRAGMENT}
  ${FINANCIAL_INFORMATION_CARD_FRAGMENT}
  ${PRICING_INFORMATION_CARD_FRAGMENT}
  ${ENTITLEMENTS_CARD_FRAGMENT}
`;

const ServiceContractOverview = () => {
  const { isAdmin } = useUserAuthInfo();
  const { prospectId } = useParams();
  const { loading, data } = useQuery(SERVICE_CONTRACT_QUERY, { variables: { prospectId }, returnPartialData: true });
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ServiceContractGeneralCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <EntitlementsCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <PerformanceGuaranteeCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <FinancialInformationCard loading={loading} data={data} />
      </Grid>
      {isAdmin && (
        <Grid item xs={12}>
          <PricingInformationCard loading={loading} data={data} />
        </Grid>
      )}
    </Grid>
  );
};

export { SERVICE_CONTRACT_QUERY };
export default ServiceContractOverview;
