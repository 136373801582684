import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

import { css } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MaterialTable from 'material-table';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import tableIcons from '../constants/tableIcons';
import { useTheme } from '@material-ui/core/styles';

import formatPhone from '../utils/format-phone';
import mixpanel from '../utils/mixpanel';
import SpotlightIcon from '../components/SpotlightIcon';
import useSearchParams from '../hooks/useSearchParams';
import { SITE_DETAILS_PATH, pathToRoute } from '../constants/routes';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';
import { prepareMaterialTableData } from '../utils/helpers';
import { useUserAuthInfo } from '../components/auth';

const SEARCH_QUERY = gql`
  query SearchContainerQuery($searchTerm: String!) {
    prospectSearch(searchTerm: $searchTerm) {
      id
      numberOfResults
      results {
        edges {
          node {
            id
            prospectId
            serviceContractNumber
            name
            address
            email
            phoneNumber
            serviceContractStatus
          }
        }
      }
    }
  }
`;

const LOCAL_STORAGE_KEY = 'recentlyViewedProspectSearchList';

const getRecentlyViewed = () => {
  const recentlyViewedStr = localStorage.getItem(LOCAL_STORAGE_KEY);
  return recentlyViewedStr && JSON.parse(recentlyViewedStr);
};

const setRecentlyViewed = (data) => {
  const currentListStr = localStorage.getItem(LOCAL_STORAGE_KEY);
  const currentList = currentListStr ? JSON.parse(currentListStr) : [];
  const indexOfExistingElement = currentList.findIndex((item) => item.prospectId === data.prospectId);
  if (indexOfExistingElement > -1) {
    currentList.splice(indexOfExistingElement, 1);
  }
  currentList.unshift(data);
  if (currentList.length > 10) currentList.length = 10; // truncate to at most 10 items
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currentList));
};

const SearchContainer = () => {
  const history = useHistory();
  const searchParams = useSearchParams();
  const searchTerm = searchParams.searchTerm ?? null;
  const [getSearchResults, { loading, error, data }] = useLazyQuery(SEARCH_QUERY);
  const [recentList, setRecentList] = useState(null);
  const { isBillingEscalations } = useUserAuthInfo();
  const theme = useTheme();

  useEffect(() => {
    if (data) mixpanel.track('Executed Search', { hits: data.prospectSearch.numberOfResults });
  }, [data]);

  useEffect(() => {
    if (searchTerm) {
      getSearchResults({ variables: { searchTerm } });
    } else {
      const recent = getRecentlyViewed();
      if (recent) setRecentList(recent);
    }
  }, [searchTerm, getSearchResults]);

  const showTable = !error && (searchTerm || recentList);

  const isBankruptcyAndRestricted = (rowData) =>
    rowData.serviceContractStatus === 'Bankruptcy' && !isBillingEscalations;

  const navigateToProspect = (event, rowData) => {
    mixpanel.track(searchTerm ? 'Search Item Clicked' : 'Recently viewed item clicked');
    // Only billing escalations team members can interact with bankruptcy customers
    if (isBankruptcyAndRestricted(rowData)) {
      return;
    }
    setRecentlyViewed(rowData);
    history.push(pathToRoute(SITE_DETAILS_PATH, { prospectId: rowData.prospectId }));
  };

  const results = prepareMaterialTableData(
    searchTerm ? unwrapGraphQlList(data, 'prospectSearch.results.edges') : recentList,
  );
  const numberOfResults = data?.prospectSearch?.numberOfResults ?? '?';

  const HelpIconToolTip = () => (
    <Tooltip
      // eslint-disable-next-line max-len
      title="Account flagged for bankruptcy - you or the customer can reach out to bankruptcy@sunrun.com for info or assistance"
      placement="top"
    >
      <HelpIcon fontSize="small" htmlColor={theme.palette.type === 'dark' ? '#DBDCE5' : '#6F6D68'} />
    </Tooltip>
  );

  return (
    <Container maxWidth="xl">
      <Box paddingTop={10} textAlign="center">
        {error && (
          <Typography variant="h6" gutterBottom>
            There was an error with your Search. Support team has been notified. Please try another search.
          </Typography>
        )}
        {showTable ? (
          <MaterialTable
            title={searchTerm ? `Search Results (${numberOfResults} results)` : 'Recent views'}
            columns={[
              { title: 'SCN', field: 'serviceContractNumber' },
              { title: 'Customer Name', field: 'name' },
              { title: 'Address', field: 'address' },
              { title: 'Email', field: 'email' },
              { title: 'Phone', field: 'phoneNumber', render: ({ phoneNumber }) => formatPhone(phoneNumber) },
            ]}
            actions={[
              (rowData) => ({
                icon: rowData.serviceContractStatus === 'Bankruptcy' ? HelpIconToolTip : OpenInNewIcon,
                onClick: navigateToProspect,
              }),
            ]}
            isLoading={loading}
            data={results}
            localization={{
              body: {
                emptyDataSourceMessage: `We couldn't find a match for "${searchTerm}". Please try another search.`,
              },
            }}
            icons={tableIcons}
            options={{
              pageSize: 10,
              emptyRowsWhenPaging: false,
              paging: false,
              search: false,
              sorting: false,
              draggable: false,
              actionsColumnIndex: -1,
              showEmptyDataSourceMessage: !loading,
              rowStyle: (rowData) => ({
                color: isBankruptcyAndRestricted(rowData) && (theme.palette.type === 'dark' ? '#A6A6A6' : '#8A8A8A'),
                backgroundColor:
                  isBankruptcyAndRestricted(rowData) && (theme.palette.type === 'dark' ? '#3D3D3D' : '#FBFBFB'),
              }),
            }}
            onRowClick={navigateToProspect}
          />
        ) : (
          <div>
            <SpotlightIcon
              width="256"
              height="256"
              css={css`
                opacity: 0.5;
              `}
            />
            <Typography variant="h3" gutterBottom>
              Welcome to Spotlight
            </Typography>
            <Typography variant="body1" gutterBottom>
              To get started enter search criteria above. Search by SCN, Customer Name, Address, Email, or Phone Number.
            </Typography>
          </div>
        )}
      </Box>
    </Container>
  );
};

export { SEARCH_QUERY, getRecentlyViewed, setRecentlyViewed };
export default SearchContainer;
