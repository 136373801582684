import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { Duration } from 'luxon';
import mixpanel from '../utils/mixpanel';
import useNewVersionDetector from '../hooks/useNewVersionDetector';

const NewVersionReloadNotification = ({ waitTimeMillis = 300000 }) => {
  const newVersionDetected = useNewVersionDetector();
  const [timeLeft, setTimeLeft] = useState(waitTimeMillis);
  useEffect(() => {
    if (newVersionDetected) {
      mixpanel.track('New version detected');
      const timerStarted = Date.now();
      const reloadTimerId = setTimeout(() => {
        mixpanel.track('User took no action, page will auto reload for new version', undefined, {
          send_immediately: true,
        });
        location.reload();
      }, waitTimeMillis); // force a reload in 5 min
      const timeLeftTimerId = setInterval(() => setTimeLeft(waitTimeMillis - (Date.now() - timerStarted)), 900); // less than 1 sec so it never skips
      return () => {
        clearTimeout(reloadTimerId);
        clearInterval(timeLeftTimerId);
      };
    }
  }, [newVersionDetected, waitTimeMillis]);

  if (!newVersionDetected) return null;

  const timeLeftStr = Duration.fromMillis(timeLeft).toFormat('m:ss');
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={true}
      message={`New version detected. This page will automatically refresh in ${timeLeftStr}`}
      action={
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            mixpanel.track(
              'User elected to manually refresh to upgrade to new version',
              { 'Time left': timeLeftStr },
              { send_immediately: true },
            );
            location.reload();
          }}
        >
          RELOAD NOW
        </Button>
      }
    />
  );
};

NewVersionReloadNotification.propTypes = {
  waitTimeMillis: PropTypes.number,
};

export { useNewVersionDetector };
export default NewVersionReloadNotification;
