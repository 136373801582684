import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';

const LEAD_INFO_CARD_FRAGMENT = gql`
  fragment LeadInfoCardFields on Prospect {
    id
    purchasedThru
    leadType
    leadGeneratedBy
    leadConvertedDate
    leadSource
    channel
    acquisitionChannel
    productOffering
  }
`;

const LeadInfoCard = ({ loading, data }) => {
  const {
    purchasedThru,
    leadType,
    leadGeneratedBy,
    leadConvertedDate,
    leadSource,
    channel,
    acquisitionChannel,
    productOffering,
  } = data?.prospect || {};
  return (
    <CollapsableCard title="Lead Info">
      <Grid container spacing={1}>
        <LabeledValue loading={loading} label="Purchased Thru" text={purchasedThru} md={6} lg={4} />
        <LabeledValue loading={loading} label="Source" text={leadSource} md={6} lg={4} />
        <LabeledValue loading={loading} label="Type" text={leadType} md={6} lg={4} />
        <LabeledValue loading={loading} label="Channel" text={channel} md={6} lg={4} />
        <LabeledValue loading={loading} label="Generated By" text={leadGeneratedBy} md={6} lg={4} />
        <LabeledValue loading={loading} label="Generated Date" text={leadConvertedDate} isISODate md={6} lg={4} />
        <LabeledValue loading={loading} label="Acquisition Channel" text={acquisitionChannel} md={6} lg={4} />
        <LabeledValue loading={loading} label="Product Offering" text={productOffering} md={6} lg={4} />
      </Grid>
    </CollapsableCard>
  );
};

LeadInfoCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      purchasedThru: PropTypes.string,
      leadType: PropTypes.string,
      leadGeneratedBy: PropTypes.string,
      leadConvertedDate: PropTypes.string,
      leadSource: PropTypes.string,
      channel: PropTypes.string,
      acquisitionChannel: PropTypes.string,
      productOffering: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
};

export { LEAD_INFO_CARD_FRAGMENT };
export default LeadInfoCard;
