import Rollbar from 'rollbar';
import getEnv from './get-env';
import { LOCAL, STAGE, PRODUCTION } from '../constants/environments';

const HOSTNAME = window.location.hostname;
const environment = getEnv(HOSTNAME);

let rollbar;
if (environment === LOCAL) {
  rollbar = {
    configure: () => null,
    log: (...args) => console.log('[ROLLBAR]', ...args), // eslint-disable-line no-console
    debug: (...args) => console.debug('[ROLLBAR]', ...args), // eslint-disable-line no-console
    info: (...args) => console.info('[ROLLBAR]', ...args), // eslint-disable-line no-console
    warn: (...args) => console.warn('[ROLLBAR]', ...args), // eslint-disable-line no-console
    warning: (...args) => console.warn('[ROLLBAR]', ...args), // eslint-disable-line no-console
    error: (...args) => console.error('[ROLLBAR]', ...args), // eslint-disable-line no-console
    critical: (...args) => console.error('[ROLLBAR]', ...args), // eslint-disable-line no-console
  };
} else {
  rollbar = new Rollbar({
    accessToken: '14b3c128663a47e28f3e709d256564c6', // post_client_item
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: environment === STAGE || environment === PRODUCTION,
    payload: {
      environment,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.CODE_VERSION,
          guess_uncaught_frames: true,
        },
      },
    },
    // mostly copy paste from https://docs.rollbar.com/docs/source-maps/#section-using-source-maps-on-many-domains
    // enables source maps across domains sunrundev, sunrunstage and sunrun
    transform(payload) {
      const { trace } = payload.body;
      const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.(sunrundev|sunrunstage|sunrun)\.com(.*)/;
      if (trace?.frames) {
        for (let i = 0; i < trace.frames.length; i++) {
          const { filename } = trace.frames[i];
          if (filename) {
            const m = filename.match(locRegex);
            // changed m[1] => m[3] because (sunrundev|sunrunstage|sunrun) creates a new match group
            trace.frames[i].filename = `${m[1]}://dynamichost${m[3]}`;
            // m[1] = (https?), m[2] = (sunrundev|sunrunstage|sunrun), m[3] = bundle name (everything after .com)
            // example result: https://dynamichost/bundle.app.8as232as.js
          }
        }
      }
    },
  });
}

const trackUser = (user) => rollbar.configure({ payload: { person: { id: user.email } } });

export default rollbar;
export { trackUser };
