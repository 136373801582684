import React from 'react';
import PropTypes from 'prop-types';

const ArrowCircleRightIcon = ({ height = 24, width = 24, className, htmlColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    className={className}
  >
    <g>
      <rect fill="none" height={height} width={width} />
    </g>
    <g>
      <path
        fill={htmlColor}
        /* eslint-disable max-len */
        d="M22,12c0-5.52-4.48-10-10-10S2,6.48,2,12c0,5.52,4.48,10,10,10S22,17.52,22,12z M12,13l-4,0v-2l4,0V8l4,4l-4,4V13z"
      />
    </g>
  </svg>
);

ArrowCircleRightIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  htmlColor: PropTypes.string,
};

ArrowCircleRightIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default ArrowCircleRightIcon;
