import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';

const SERVICE_CONTRACT_GENERAL_CARD_FRAGMENT = gql`
  fragment ServiceContractGeneralCardFields on ServiceContract {
    id
    financingInstitution
    productionEstimateSource
    agreementType
    assetPurchaseDate
    endDate
    tax
    agreementTermYears
    monitoringSiteId
    paperStatements
    serviceContractStatus
    utilityCompany
    impairedOnAcquisition
    homeType
    term
    installedByName
    dealId
    homeTypeOnTitle
    teFundName
    brightBox
    masterIdM
    installationAge
    inTeFund
    backlogAsset
    additionalSystem
    legacyAssetId
  }
`;

const ServiceContractGeneralCard = ({ loading, data }) => {
  const {
    financingInstitution,
    productionEstimateSource,
    agreementType,
    assetPurchaseDate,
    endDate,
    tax,
    agreementTermYears,
    monitoringSiteId,
    paperStatements,
    serviceContractStatus,
    utilityCompany,
    impairedOnAcquisition,
    homeType,
    term,
    installedByName,
    dealId,
    homeTypeOnTitle,
    teFundName,
    brightBox,
    masterIdM,
    installationAge,
    inTeFund,
    backlogAsset,
    additionalSystem,
    legacyAssetId,
  } = data?.prospect?.serviceContract || {};

  return (
    <CollapsableCard title="General" mixpanelEventName="Toggled Service Contract > General card expand/collapse">
      <Grid container direction="row" spacing={1}>
        <LabeledValue loading={loading} label="Financing Institution" text={financingInstitution} />
        <LabeledValue loading={loading} label="Production Estimate Source" text={productionEstimateSource} />
        <LabeledValue loading={loading} label="Agreement Type" text={agreementType} />
        <LabeledValue loading={loading} label="Asset Purchase Date" text={assetPurchaseDate} isISODate />
        <LabeledValue loading={loading} label="End Date" text={endDate} isISODate />
        <LabeledValue loading={loading} label="Tax" text={tax} />
        <LabeledValue loading={loading} label="Agreement Term Years" text={agreementTermYears} />
        <LabeledValue loading={loading} label="Monitoring Site ID" text={monitoringSiteId} />
        <LabeledValue loading={loading} label="Paper Statements" text={paperStatements} />
        <LabeledValue loading={loading} label="Service Contract Status" text={serviceContractStatus} />
        <LabeledValue loading={loading} label="Utility Company" text={utilityCompany} />
        <LabeledValue loading={loading} label="Impaired on Acquisition" text={impairedOnAcquisition} />
        <LabeledValue loading={loading} label="Home Type" text={homeType} />
        <LabeledValue loading={loading} label="Term (months)" text={term} />
        <LabeledValue loading={loading} label="Installed By Name" text={installedByName} />
        <LabeledValue loading={loading} label="Deal Type" text={dealId} />
        <LabeledValue loading={loading} label="Home Type on Title" text={homeTypeOnTitle} />
        <LabeledValue loading={loading} label="TE Fund Name" text={teFundName} />
        <LabeledValue loading={loading} label="BrightBox" text={brightBox} />
        <LabeledValue loading={loading} label="Master ID-M" text={masterIdM} />
        <LabeledValue loading={loading} label="Installation age" text={installationAge} />
        <LabeledValue loading={loading} label="In TE Fund" text={inTeFund} isISODate />
        <LabeledValue loading={loading} label="Backlog Asset" text={backlogAsset} />
        <LabeledValue loading={loading} label="Additional System" text={additionalSystem} />
        <LabeledValue loading={loading} label="Legacy Asset ID" text={legacyAssetId} />
      </Grid>
    </CollapsableCard>
  );
};

ServiceContractGeneralCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      serviceContract: PropTypes.shape({
        financingInstitution: PropTypes.string,
        productionEstimateSource: PropTypes.string,
        agreementType: PropTypes.string,
        assetPurchaseDate: PropTypes.string,
        endDate: PropTypes.string,
        tax: PropTypes.string,
        agreementTermYears: PropTypes.number,
        monitoringSiteId: PropTypes.string,
        paperStatements: PropTypes.bool,
        serviceContractStatus: PropTypes.string,
        utilityCompany: PropTypes.string,
        impairedOnAcquisition: PropTypes.bool,
        homeType: PropTypes.string,
        term: PropTypes.number,
        installedByName: PropTypes.string,
        dealId: PropTypes.string,
        homeTypeOnTitle: PropTypes.string,
        teFundName: PropTypes.string,
        brightBox: PropTypes.bool,
        masterIdM: PropTypes.string,
        installationAge: PropTypes.string,
        inTeFund: PropTypes.string,
        backlogAsset: PropTypes.bool,
        additionalSystem: PropTypes.bool,
        legacyAssetId: PropTypes.string,
      }),
    }),
  }),
  loading: PropTypes.bool,
};

export { SERVICE_CONTRACT_GENERAL_CARD_FRAGMENT };
export default ServiceContractGeneralCard;
