import React from 'react';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import useEnvConfig from '../hooks/useEnvConfig';
import PropTypes from 'prop-types';

const FlagsmithProviderContainer = ({ children }) => {
  const { flagsmithEnvironmentID } = useEnvConfig();
  const flagsmithOptions = {
    environmentID: flagsmithEnvironmentID,
    cacheFlags: true,
    enableLogs: true,
    realtime: true,
  };

  return (
    <FlagsmithProvider flagsmith={flagsmith} options={{ ...flagsmithOptions }}>
      {children}
    </FlagsmithProvider>
  );
};

FlagsmithProviderContainer.propTypes = {
  children: PropTypes.any,
};

export default FlagsmithProviderContainer;
