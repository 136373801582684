import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams, Route, Redirect, Switch } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiSwitch from '@material-ui/core/Switch';
import {
  pathToRoute,
  PROPOSAL_DOCUMENTS_PATH,
  GENERAL_DOCUMENTS_PATH,
  SOW_DOCUMENTS_PATH,
  SERVICE_CONTRACT_DOCUMENTS_PATH,
} from '../constants/routes';
import ServiceContractDocumentsTable from './ServiceContractDocumentsTable';
import ProposalDocumentsTable from './ProposalDocumentsTable';
import SOWDocumentsTable from './SOWDocumentsTable';
import GeneralDocumentsTable from './GeneralDocumentsTable';
import mixpanel from '../utils/mixpanel';

const DocumentNavButton = ({ to, title }) => (
  <Button
    component={NavLink}
    to={to}
    activeClassName="MuiButton-containedPrimary"
    onClick={() => {
      mixpanel.track('Clicked Document Tab', { 'Tab Name': title });
    }}
  >
    {title}
  </Button>
);

DocumentNavButton.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const DocumentsPage = () => {
  const { prospectId } = useParams();
  const [viewAllVersions, setViewAllVersions] = useState(false);
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container>
        <Grid item xs>
          <ButtonGroup color={theme.palette.type === 'dark' ? 'default' : 'primary'}>
            <DocumentNavButton title="Proposal" to={pathToRoute(PROPOSAL_DOCUMENTS_PATH, { prospectId })} />
            <DocumentNavButton title="General" to={pathToRoute(GENERAL_DOCUMENTS_PATH, { prospectId })} />
            <DocumentNavButton
              title="Service Contract"
              to={pathToRoute(SERVICE_CONTRACT_DOCUMENTS_PATH, { prospectId })}
            />
            <DocumentNavButton title="SOW" to={pathToRoute(SOW_DOCUMENTS_PATH, { prospectId })} />
          </ButtonGroup>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <MuiSwitch
                checked={viewAllVersions}
                onChange={() => {
                  mixpanel.track('Toggled documents view all versions', { viewAll: !viewAllVersions });
                  setViewAllVersions(!viewAllVersions);
                }}
                name="View all versions"
                color="primary"
              />
            }
            label="View all versions"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Switch>
          <Route path={PROPOSAL_DOCUMENTS_PATH}>
            <ProposalDocumentsTable viewAll={viewAllVersions} />
          </Route>
          <Route path={GENERAL_DOCUMENTS_PATH}>
            <GeneralDocumentsTable viewAll={viewAllVersions} />
          </Route>
          <Route path={SERVICE_CONTRACT_DOCUMENTS_PATH}>
            <ServiceContractDocumentsTable viewAll={viewAllVersions} />
          </Route>
          <Route path={SOW_DOCUMENTS_PATH}>
            <SOWDocumentsTable viewAll={viewAllVersions} />
          </Route>
          <Redirect to={pathToRoute(PROPOSAL_DOCUMENTS_PATH, { prospectId })} />
        </Switch>
      </Grid>
    </Grid>
  );
};

export default DocumentsPage;
