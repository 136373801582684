import memoizeOne from 'memoize-one';

import { LOCAL, DEVELOP, STAGE, PRODUCTION } from '../constants/environments';

const hostNameEnvMapping = {
  'customer-spotlight.sunrundev.com': LOCAL,
  'devmaj-customer-spotlight.sunrundev.com': DEVELOP,
  'majstg-customer-spotlight.sunrun.com': STAGE,
  'customer-spotlight.sunrun.com': PRODUCTION,
};

const getEnv = (url) => hostNameEnvMapping[url] || LOCAL;

export default memoizeOne(getEnv);
