import React, { useEffect, useState } from 'react';

import CollapsableCard from '../components/CollapsableCard';
import useEnvConfig from '../hooks/useEnvConfig';
import axios from 'axios';
import { css } from '@emotion/core';
import { Divider, Link, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

const SystemStatus = ({ prospectId, hybridAuthToken }) => {
  const { customerSystemStatusServiceAPIUrl } = useEnvConfig();
  const [systemStatus, setSystemStatus] = useState(null);
  const [systemStatusError, setSystemStatusErrorError] = useState(null);
  const { enableSystemStatus } = useEnvConfig();

  const infoLink = 'https://sunrun.lightning.force.com/lightning/articles/Knowledge/Spotlight-System-Health-Status';

  useEffect(() => {
    (async () => {
      try {
        const customerSystemStatus = await axios.get(
          `${customerSystemStatusServiceAPIUrl}/system-status/${prospectId}`,
          {
            headers: {
              Authorization: hybridAuthToken,
            },
          },
        );

        setSystemStatus(customerSystemStatus.data.systemStatus);
      } catch (error) {
        setSystemStatusErrorError(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const solarStatusMapping = {
    HEALTHY: { icon: CheckCircleOutlineIcon, color: '#038761' },
    WARNING: { icon: ReportProblemOutlinedIcon, color: '#FFD35C' },
    PENDING: { icon: SmsFailedIcon, color: '#0073E0' },
    FAILURE: { icon: ErrorOutlineIcon, color: '#CB2B51' },
  };

  if (!enableSystemStatus) {
    return null;
  }

  // loading
  if (!systemStatusError && !systemStatus) {
    return (
      <CollapsableCard
        title="Solar Health Status"
        css={(theme) =>
          css`
            margin-bottom: ${theme.spacing(2)}px;
          `
        }
      >
        <LinearProgress />
      </CollapsableCard>
    );
  }

  // error
  if (systemStatusError) {
    return (
      <CollapsableCard
        title="Solar Health Status"
        css={(theme) =>
          css`
            margin-bottom: ${theme.spacing(2)}px;
          `
        }
      >
        <Grid container alignItems="center" direction="column">
          <Grid item xs>
            <ErrorOutlineIcon
              css={css`
                color: #cb2b51;
              `}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6">{systemStatusError.message || 'Something has gone wrong'}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1">
              Something has gone wrong in calling the customer system status service
            </Typography>
          </Grid>
        </Grid>
      </CollapsableCard>
    );
  }

  const NoStatusFound = () => {
    return (
      <Grid container direction="column" data-testid="no-status-found">
        <Grid item xs>
          <Typography
            variant="h6"
            css={(theme) =>
              css`
                margin-bottom: ${theme.spacing(2)}px;
              `
            }
          >
            Cannot Retrieve Status
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="paragraph">
            Solar Health Status is not available at the moment. Please check back later.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const CustomerFacingStatusCard = () => {
    return (
      <Grid item xs={12} lg={6} data-testid="customer-facing-status-card">
        <CollapsableCard
          title="Solar Health Status: Customer-Facing"
          css={(theme) =>
            css`
              margin-bottom: ${theme.spacing(2)}px;
            `
          }
        >
          {!systemStatus?.solarStatus?.state ? (
            <NoStatusFound />
          ) : (
            <Grid container direction="row" spacing={3} justifyContent="space-around">
              <Grid item xs>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs>
                    <Grid container direction="row">
                      <Grid
                        container
                        direction="row"
                        css={css`
                          width: auto;
                        `}
                      >
                        <Grid item>
                          <Icon
                            component={solarStatusMapping[systemStatus?.solarStatus?.state]?.icon}
                            css={css`
                              color: ${solarStatusMapping[systemStatus?.solarStatus?.state]?.color};
                              height: 32px;
                              width: 32px;
                              margin-right: 4px;
                            `}
                          ></Icon>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            {systemStatus?.solarStatus?.customerMessaging?.detail?.title ||
                              systemStatus?.solarStatus?.customerMessaging?.overview?.text}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Divider
                          orientation="vertical"
                          css={css`
                            margin: 0 10px;
                          `}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h6"
                          css={css`
                            font-weight: 400;
                          `}
                        >
                          {systemStatus?.solarStatus?.customerMessaging?.detail?.previewText ||
                            systemStatus?.solarStatus?.state}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body1">
                      {systemStatus?.solarStatus?.customerMessaging?.detail?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Grid container direction="row">
                      <Typography
                        css={css`
                          font-size: 16px;
                          font-weight: 700;
                        `}
                      >
                        As of Date:
                      </Typography>
                      <Typography>
                        &nbsp;{DateTime.fromISO(systemStatus?.solarStatus?.lastUpdatedDate).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CollapsableCard>
      </Grid>
    );
  };

  const InternalUseStatusCard = () => {
    return (
      <Grid item xs={12} lg={6} data-testid="internal-use-status-card">
        <CollapsableCard
          title="Solar Health Status: Internal Only"
          css={(theme) =>
            css`
              margin-bottom: ${theme.spacing(2)}px;
            `
          }
        >
          <Grid container direction="row" spacing={3} justifyContent="space-around">
            <Grid item xs>
              {!systemStatus?.solarStatus?.state ? (
                <NoStatusFound />
              ) : (
                <Grid container direction="column" spacing={2}>
                  <Grid item xs>
                    <Grid container direction="row" alignItems="baseline">
                      <Typography
                        variant="h6"
                        css={css`
                          margin-right: 16px;
                        `}
                      >
                        System State
                      </Typography>
                      <Link
                        href={infoLink}
                        target="_blank"
                        css={css`
                          color: #0073e0;
                          font-size: 16px;
                          font-weight: 500;
                          text-decoration: none;
                        `}
                      >
                        View Knowledge Article
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container direction="row">
                      <Typography
                        css={css`
                          font-size: 16px;
                          font-weight: 700;
                        `}
                      >
                        Status:
                      </Typography>
                      <Typography>&nbsp;{systemStatus?.solarStatus?.sourceData?.pvState}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container direction="row">
                      <Typography
                        css={css`
                          font-size: 16px;
                          font-weight: 700;
                        `}
                      >
                        Detail:
                      </Typography>
                      <Typography> &nbsp;{systemStatus?.solarStatus?.sourceData?.pvStateSummaryCategory}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CollapsableCard>
      </Grid>
    );
  };

  // happy response
  return (
    <Grid container spacing={2} data-testid="system-status-cards">
      <CustomerFacingStatusCard />
      <InternalUseStatusCard />
    </Grid>
  );
};

SystemStatus.propTypes = {
  prospectId: PropTypes.string.isRequired,
  hybridAuthToken: PropTypes.string.isRequired,
};

export default SystemStatus;
