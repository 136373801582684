import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorIcon from '@material-ui/icons/Error';
import { useParams } from 'react-router';
import { BILLING_CONTACT_PATH, pathToRoute } from '../constants/routes';
import formatCurrency from '../utils/format-currency';
import { DateTime } from 'luxon';
import useSearchParams from '../hooks/useSearchParams';
import mixpanel from '../utils/mixpanel';
import rollbar from '../utils/rollbar';

const getBankAccountType = (type) => {
  if (type === 'S') return 'savings account';
  if (type === 'X') return 'corporate checking account';
  return 'checking account';
};

const getBankNumber = (urlParams) => {
  // return either the account or routing number
  // if the account number doesn't have any digits
  const hasNumber = /\d/;
  if (hasNumber.test(urlParams.req_echeck_account_number)) {
    return urlParams.req_echeck_account_number;
  }
  return urlParams.req_echeck_routing_number;
};

const getConfirmationNumber = (req_reference_number) => {
  try {
    return req_reference_number.split(':')[1];
  } catch (e) {
    rollbar.error(
      `failed to parse req_reference_number with req_reference_number: ${req_reference_number} with error: ${e}`,
    );
    return null;
  }
};

const ConfirmationModal = () => {
  const history = useHistory();
  const { prospectId, contactId } = useParams();
  const urlParams = useSearchParams();
  const {
    decision,
    invalid_fields: invalidFields,
    message,
    req_amount: paymentAmount,
    req_transaction_type: type,
  } = urlParams;
  const paymentType =
    urlParams.req_payment_method === 'card' ? 'credit card' : getBankAccountType(urlParams.req_echeck_account_type);
  const paymentNumber = paymentType === 'credit card' ? urlParams.req_card_number : getBankNumber(urlParams);
  const endingInNumber = paymentNumber?.substr(paymentNumber.length - 4);
  const confirmationNumber = getConfirmationNumber(urlParams.req_reference_number);

  useEffect(() => {
    mixpanel.track(type === 'sale' ? 'Payment Processed' : 'Enrolled in Autopay', {
      'Payment Type': paymentType,
      success: decision === 'ACCEPT',
    });
  }, [type, decision, paymentType]);

  const nowStr = DateTime.local().toLocaleString();
  const closeModal = () => history.replace(pathToRoute(BILLING_CONTACT_PATH, { prospectId, contactId }));
  return (
    <Dialog
      open={true}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      {decision === 'ACCEPT' ? (
        type === 'sale' ? (
          <>
            <DialogTitle id="form-dialog-title">Payment Processed</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {formatCurrency(paymentAmount)} was paid on {nowStr} from the {paymentType} ending in {endingInNumber}
              </DialogContentText>
              <DialogContentText>A receipt was sent to {urlParams.req_bill_to_email}</DialogContentText>
              <DialogContentText>
                <i>Processing may take 24-48 hours.</i>
              </DialogContentText>
              {confirmationNumber && (
                <DialogContentText
                  css={css`
                    font-weight: bold;
                    color: #000000;
                  `}
                >
                  Confirmation Number: {confirmationNumber}
                </DialogContentText>
              )}
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle id="form-dialog-title">Autopay Confirmed</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enrolled with {paymentType} ending in {endingInNumber}
              </DialogContentText>
            </DialogContent>
          </>
        )
      ) : (
        <>
          <DialogTitle id="form-dialog-title">Oops!</DialogTitle>
          <DialogContent>
            <ErrorIcon color="error" />
            <DialogContentText>
              We could not process the {paymentType} ending in {endingInNumber}
            </DialogContentText>
            <DialogContentText>{`${message}${invalidFields ? ` ${invalidFields}` : ''}`}</DialogContentText>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
