import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {
  SITE_DETAILS_PATH,
  SERVICE_CONTRACT_PATH,
  ACTIVITY_PATH,
  BILLING_PATH,
  BILLING_CONTACT_PATH,
  SYSTEM_PATH,
  DOCUMENTS_PATH,
  PAYMENT_PATH,
  HOME_ENERGY_REPORT_PATH,
  HOME_ENERGY_REPORT_CONTACT_PATH,
  HOME_ENERGY_REPORT_LIST_PATH,
  pathToRoute,
  LD_NOTES_PATH,
} from '../constants/routes';
import Header from '../components/Header';
import SideNav from './SideNav';
import SiteSummaryCard from './SiteSummaryCard';
import SiteDetailsPage from '../siteDetails';
import ServiceContractPage from '../serviceContract';
import ActivityPage from '../activity';
import BillingPage from '../billing';
import SystemPage from '../system';
import DocumentsPage from '../documents';
import PaymentPage from '../payment';
import HomeEnergyReportPage from '../homeEnergyReport';
import HomeEnergyReportListPage from '../homeEnergyReport/HomeEnergyReportListPage';
import mixpanel from '../utils/mixpanel';
import { useUserAuthInfo } from '../components/auth';
import LDNotesPage from '../ldNotes';

const SitePage = ({ loading, data }) => {
  const { prospectId } = useParams();
  const { isViewer, isUser } = useUserAuthInfo();
  const scn = data?.prospect?.serviceContractNumber;
  const currentOwnerContactId = data?.prospect?.currentOwner?.contactId;
  const isVivintSolarPostPTO = !!data?.prospect?.isVivintSolarPostPTO;
  const PTODate = data?.prospect?.PTO;

  // Either PTO is undefined OR PTO date less than 100 days
  const meetsPrePTORequirements = () => {
    if (!PTODate) {
      return true;
    }

    const millisecondsIn100Days = 100 * 24 * 60 * 60 * 1000;
    const PTODateLessThan100DaysAgo = new Date(data?.prospect?.PTO) > new Date(Date.now() - millisecondsIn100Days);
    return PTODateLessThan100DaysAgo;
  };
  const isPrePTO = meetsPrePTORequirements();

  useLayoutEffect(() => {
    const fieldsToRegister = { prospectId };
    if (scn) fieldsToRegister['Service Contract Number'] = scn;
    mixpanel.register(fieldsToRegister);
    return () => {
      mixpanel.unregister('prospectId');
      mixpanel.unregister('Service Contract Number');
    };
  }, [prospectId, scn]);

  return (
    <div css={{ display: 'flex' }}>
      <Header />
      <SideNav isVivintSolarPostPTO={isVivintSolarPostPTO} isPrePTO={isPrePTO} />
      <main css={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
        <div css={(theme) => theme.mixins.toolbar} />
        <Container maxWidth="xl" css={(theme) => ({ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) })}>
          <SiteSummaryCard />
          <Switch>
            <Route path={SITE_DETAILS_PATH}>
              <SiteDetailsPage />
            </Route>
            <Route path={SERVICE_CONTRACT_PATH}>
              <ServiceContractPage />
            </Route>
            <Route path={ACTIVITY_PATH}>
              <ActivityPage />
            </Route>
            {isViewer && isVivintSolarPostPTO && (
              <Route path={LD_NOTES_PATH} exact>
                <LDNotesPage />
              </Route>
            )}
            {isViewer && (
              <Route path={BILLING_CONTACT_PATH}>
                <BillingPage />
              </Route>
            )}
            {isViewer && (
              <Route path={BILLING_PATH}>
                {currentOwnerContactId ? (
                  <Redirect to={pathToRoute(BILLING_CONTACT_PATH, { prospectId, contactId: currentOwnerContactId })} />
                ) : loading ? (
                  <CircularProgress css={{ margin: 'auto', display: 'block' }} />
                ) : (
                  <Typography align="center">No Billing information found</Typography>
                )}
              </Route>
            )}
            <Route path={SYSTEM_PATH}>
              <SystemPage />
            </Route>
            <Route path={DOCUMENTS_PATH}>
              <DocumentsPage />
            </Route>
            <Route path={PAYMENT_PATH}>
              <PaymentPage />
            </Route>
            <Route path={HOME_ENERGY_REPORT_LIST_PATH}>
              <HomeEnergyReportListPage />
            </Route>
            <Route path={HOME_ENERGY_REPORT_CONTACT_PATH}>
              <HomeEnergyReportPage />
            </Route>
            <Route path={HOME_ENERGY_REPORT_PATH}>
              {isUser && currentOwnerContactId ? (
                <Redirect
                  to={pathToRoute(HOME_ENERGY_REPORT_CONTACT_PATH, {
                    prospectId,
                    contactId: currentOwnerContactId,
                  })}
                />
              ) : loading ? (
                <CircularProgress css={{ margin: 'auto', display: 'block' }} />
              ) : (
                <Typography align="center">
                  No contact associated with this prospect, unable to create Home Energy Report.
                </Typography>
              )}
            </Route>
            <Redirect to={pathToRoute(SITE_DETAILS_PATH, { prospectId })} />
          </Switch>
        </Container>
      </main>
    </div>
  );
};

SitePage.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      serviceContractNumber: PropTypes.string,
      isVivintSolarPostPTO: PropTypes.bool,
      currentOwner: PropTypes.shape({
        contactId: PropTypes.string,
      }),
      PTO: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
};

export default SitePage;
