import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { downloadReportButtonText, downloadTooltipMessage } from '../constants/transactions';

const TransactionHistoryToolbar = ({ title, variant, onclick }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Typography
      variant={variant}
      style={{
        overflow: 'show',
      }}
    >
      <span>{title}</span>
    </Typography>
    <div id="download-container" style={{ display: 'flex', alignItems: 'center' }}>
      <Button variant="text" onClick={onclick} color="primary" data-testid="enroll-autopay-btn">
        {downloadReportButtonText}
      </Button>
      <Tooltip data-testid="download-help-icon" title={downloadTooltipMessage} style={{ width: 'max-content' }}>
        <HelpIcon color="disabled" fontSize="small" />
      </Tooltip>
    </div>
  </div>
);

TransactionHistoryToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  onclick: PropTypes.func,
};

export default TransactionHistoryToolbar;
