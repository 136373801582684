import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, { useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useParams, useHistory } from 'react-router';
import { OneTimePaymentForm } from '@sunrun/ui-cybersource-payments';
import { PAYMENT_CONFIRMATION_PATH, CYBERSOURCE_IFRAME_EVENTS_PATH, pathToRoute } from '../constants/routes';
import useEnvConfig from '../hooks/useEnvConfig';
import { useHybridAuthToken } from '../components/hybridAuthToken';
import mixpanel from '../utils/mixpanel';

const OneTimePayment = ({
  userInfo,
  totalAmountDue,
  specialInvoiceAmount,
  referenceNumberPrefix,
  isBillingEscalations,
}) => {
  const { paymentEnv } = useEnvConfig();
  const hybridAuth = useHybridAuthToken();
  const { prospectId, contactId } = useParams();
  const history = useHistory();
  const purchasedThru = userInfo?.purchasedThru || '';
  const isCostco = purchasedThru.toLowerCase().includes('costco');
  useEffect(() => {
    const redirectToConfirmation = (detail) => {
      window.location = `${pathToRoute(PAYMENT_CONFIRMATION_PATH, { prospectId, contactId })}?${QueryString.stringify(
        detail,
      )}`;
    };

    const paymentFailure = (e) => {
      redirectToConfirmation(e.detail);
    };

    const paymentSuccess = (e) => {
      // Check and see if we need to wait for the enroll in autopay
      if (!e.detail.req_merchant_defined_data9) {
        redirectToConfirmation(e.detail);
      }
    };

    window.addEventListener('PAYMENT_FAILURE_ONE_TIME', paymentFailure, false);
    window.addEventListener('PAYMENT_SUCCESS_ONE_TIME', paymentSuccess, false);

    return () => {
      window.removeEventListener('PAYMENT_FAILURE_ONE_TIME', paymentFailure);
      window.removeEventListener('PAYMENT_SUCCESS_ONE_TIME', paymentSuccess);
    };
  }, [history, prospectId, contactId]);

  if (!hybridAuth) return <LinearProgress />;

  return (
    <OneTimePaymentForm
      analytics={mixpanel}
      envName={paymentEnv}
      userInfo={userInfo}
      billingInfo={{ totalAmountDue: specialInvoiceAmount || totalAmountDue }}
      redirectUrl={CYBERSOURCE_IFRAME_EVENTS_PATH}
      hybridAuthToken={hybridAuth}
      showConsentScript
      showEmail
      canOverPay={!referenceNumberPrefix}
      showInvoiceType
      renderReviewError={(error) => {
        if (error) {
          return <div>{error.message}</div>;
        }
        return 'Error creating payment request. Please try again.';
      }}
      referenceNumberPrefix={referenceNumberPrefix}
      allowSpecialInvoicePartialPay={specialInvoiceAmount && isBillingEscalations}
      hideCCMethod={specialInvoiceAmount && !isBillingEscalations && !isCostco}
      inputValuesHidden
    />
  );
};

OneTimePayment.propTypes = {
  userInfo: PropTypes.shape({
    prospectId: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    mobilePhoneNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    lastDueDate: PropTypes.string,
    purchasedThru: PropTypes.string,
  }),
  specialInvoiceAmount: PropTypes.number,
  referenceNumberPrefix: PropTypes.string,
  totalAmountDue: PropTypes.number,
  isBillingEscalations: PropTypes.bool,
};

export default OneTimePayment;
