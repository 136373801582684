import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { QuestionAnswerContainer } from '@sunrun/ui-question-answer';
import { Alert } from '@sunrun/component-library';
import { css } from '@emotion/core';
import mixpanel from '../utils/mixpanel';
import axios from 'axios';
import useEnvConfig from '../hooks/useEnvConfig';
import { CircularProgress } from '@material-ui/core';

const SurveyContainer = ({ authToken, surveyId, prospectId, projectId, onBack, onFinish, onError }) => {
  const { questionAnswerAPIUrl, statusTrackerAPIUrl, userAPIUrl } = useEnvConfig();

  const [error, setError] = useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [loading, setLoading] = useState(!prospectId);
  const [userProjectId, setUserProjectId] = useState(projectId);

  const contentStyle = {
    paddingLeft: 16,
    paddingRight: 16,
  };

  const onCompleted = useCallback(async () => {
    try {
      setIsLoadingRequest(true);
      await axios.post(
        `${statusTrackerAPIUrl}/evInstallTask/${prospectId}`,
        {},
        {
          headers: {
            Authorization: authToken,
          },
        },
      );

      setIsLoadingRequest(false);
      onFinish();
    } catch (e) {
      setIsLoadingRequest(false);
      setError(e);
      mixpanel.track(`Survey completion error`);
    }
  }, [statusTrackerAPIUrl, prospectId, authToken, onFinish]);

  const onErrorAlertClose = () => {
    setError(null);
  };

  useEffect(() => {
    (async () => {
      if (!userProjectId) {
        try {
          const res = await axios.get(`${userAPIUrl}/project-id/${prospectId}`, {
            headers: {
              Authorization: authToken,
            },
          });

          if (res.status === 200) {
            const latestProjectId = res.data.projectId;
            setUserProjectId(latestProjectId);
            setLoading(false);
          } else {
            throw new Error('Could not get prospectId from portal-user');
          }
        } catch (e) {
          window.Rollbar.error(e);
          onError();
        }
      }
    })();
  }, [userProjectId, userAPIUrl, onError, prospectId, authToken]);

  if (loading || !userProjectId) {
    return (
      <div
        css={css`
          margin-top: 20px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {isLoadingRequest && (
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <CircularProgress />
        </div>
      )}
      {error && (
        <Alert
          title="Sorry, we are having trouble."
          text="We are having trouble submitting your Home Assessment. Please try again"
          type="error"
          snackbar
          snackbarAutoHideDuration={7000}
          onClickClose={onErrorAlertClose}
        />
      )}
      <QuestionAnswerContainer
        analytics={mixpanel}
        analyticsType="EV Charger Install"
        questionAnswerUrl={questionAnswerAPIUrl}
        authToken={authToken}
        surveyId={surveyId}
        SFProjectId={userProjectId}
        prospectId={prospectId}
        loading={
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <CircularProgress />
          </div>
        }
        contentStyle={contentStyle}
        buttonsStyle={contentStyle}
        onBack={onBack}
        onFinish={onCompleted}
      />
    </>
  );
};

SurveyContainer.propTypes = {
  surveyId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  prospectId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default SurveyContainer;
