import React, { useContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import App from '../App';
import rollbar, { trackUser as rollbarTrackUser } from '../utils/rollbar';
import { trackUser as mixpanelTrackUser } from '../utils/mixpanel';
import getEnv from '../utils/get-env';
import { PRODUCTION } from '../constants/environments';
import flagsmith from 'flagsmith';

const PROD_VIEWER = 'Spotlight Viewer';
const PROD_USER = 'Spotlight User';
const PROD_ADMIN = 'Spotlight Admin';
const PROD_BILLING_ESCALATIONS = 'Spotlight Billing Escalations';
const DEV_VIEWER = 'Spotlight Viewer Dev';
const DEV_USER = 'Spotlight User Dev';
const DEV_ADMIN = 'Spotlight Admin Dev';
const DEV_BILLING_ESCALATIONS = 'Spotlight Billing Escalations Dev';

const getGroupNames = () => {
  const envName = getEnv(window?.location?.hostname);
  return {
    ADMIN: envName === PRODUCTION ? PROD_ADMIN : DEV_ADMIN,
    USER: envName === PRODUCTION ? PROD_USER : DEV_USER,
    VIEWER: envName === PRODUCTION ? PROD_VIEWER : DEV_VIEWER,
    BILLING_ESCALATIONS: envName === PRODUCTION ? PROD_BILLING_ESCALATIONS : DEV_BILLING_ESCALATIONS,
  };
};

const UserAuthContext = React.createContext(() => {
  throw new Error('Forgot to wrap component in `UserAuthContext`');
});

const calculateAuthFromUser = (user) => {
  const { ADMIN, USER, VIEWER, BILLING_ESCALATIONS } = getGroupNames();
  const isAdmin = user?.groups?.includes(ADMIN) || false;
  const isUser = isAdmin || isBillingEscalations || user?.groups?.includes(USER) || false;
  const isViewer = isAdmin || isBillingEscalations || isUser || user?.groups?.includes(VIEWER) || false;
  const isBillingEscalations = isAdmin || user?.groups?.includes(BILLING_ESCALATIONS) || false;

  return { isViewer, isUser, isAdmin, isBillingEscalations };
};

/* The following function is to workaround when Okta is in an invalid
 * state. It happens often that Okta will fail to refresh the user's access
 * token and also remove it from localStorage. This function checks for the
 * existence of this access token, and if it doesn't exist at this point then
 * we know that the user is most likely in an invalid state. We have to use
 * window.location.reload here because the logout provided by Okta will
 * intermittently fail and using login provided by Okta causes a race
 * condition and fails to log the user in completely. The one nice thing about
 * a full page reload in this instance is that Okta will redirect the user to
 * the route that they previously tried to acces with an invalid token.
 */
const checkExistenceOfOktaAccessToken = () => {
  try {
    const oktaTokenStorage = localStorage.getItem('okta-token-storage');
    JSON.parse(oktaTokenStorage).accessToken;
  } catch (error) {
    rollbar.warn(
      `Okta access token could not be read from localStorage with path: ${window.location.pathname} error: ${error}`,
    );
    window.location.reload();
  }
};

const UserGateAndAuthProvider = () => {
  const [user, setUser] = useState(null);
  const { oktaAuth, authState } = useOktaAuth();
  const { isAuthenticated } = authState || {};
  const {
    token: { getUserInfo },
    signInWithRedirect,
  } = oktaAuth;
  checkExistenceOfOktaAccessToken();
  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const oktaUser = await getUserInfo();
        if (oktaUser) {
          rollbarTrackUser(oktaUser);
          mixpanelTrackUser(oktaUser);
          setUser(oktaUser);

          const flagsmithIdentifier = `employee_${oktaUser.email}`;
          const flagsmithTraits = {
            email: oktaUser.email,
            client: 'Spotlight',
            clientPlatform: 'web',
          };
          void flagsmith.identify(flagsmithIdentifier, flagsmithTraits);
        } else {
          rollbar.warn('Failed to retrieve user info from token. Signing back in.');
          await signInWithRedirect(); // something is wrong, so re auth. this will send em back to okta if necessary
        }
      })();
    }
  }, [isAuthenticated, getUserInfo, signInWithRedirect]);

  if (!user) return null;

  return (
    <UserAuthContext.Provider value={calculateAuthFromUser(user)}>
      <App />
    </UserAuthContext.Provider>
  );
};

const useUserAuthInfo = () => useContext(UserAuthContext);

export {
  UserAuthContext as UserAuthContext_FOR_TESTING,
  calculateAuthFromUser,
  UserGateAndAuthProvider,
  useUserAuthInfo,
};
