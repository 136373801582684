import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { css } from '@emotion/core';
import { DateTime } from 'luxon';
import formatPhone from '../utils/format-phone';
import CollapsableCard from '../components/CollapsableCard';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MuiAlert from '@material-ui/lab/Alert';
import humanizeDuration from 'humanize-duration';
import { BLUE } from '../constants/billing-status';

const SITE_SUMMARY_CARD_QUERY = gql`
  query SiteSummaryCardQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      id
      address
      name
      email
      phoneNumber
      mobilePhoneNumber
      serviceContractNumber
      agreementType
      PTO
      fundBridge
      isVivintSolarPostPTO
      billingSystem
    }
  }
`;

const SiteSummaryCard = () => {
  const { prospectId } = useParams();
  const { loading, data } = useQuery(SITE_SUMMARY_CARD_QUERY, {
    variables: { prospectId },
    returnPartialData: true,
  });
  const {
    address,
    name,
    email,
    phoneNumber,
    mobilePhoneNumber,
    serviceContractNumber,
    agreementType,
    PTO,
    fundBridge,
    isVivintSolarPostPTO,
    billingSystem,
  } = data?.prospect || {};
  const isBlueBillingSystem = billingSystem === BLUE;

  let ptoDisplayText;
  if (PTO) {
    const ptoDateTime = DateTime.fromISO(PTO, { setZone: true });
    const msSincePTO = ptoDateTime.diffNow().valueOf();
    ptoDisplayText = `PTO ${ptoDateTime.toLocaleString()} (${humanizeDuration(msSincePTO, {
      units: ['y', 'd'],
      round: true,
    })})`;
  }

  return (
    <CollapsableCard
      title={address ?? (loading && <Skeleton width={500} />)}
      mixpanelEventName="Toggled details summary card expand/collapse"
      css={(theme) =>
        css`
          margin-bottom: ${theme.spacing(2)}px;
        `
      }
    >
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} md={6} lg={4}>
          <Typography>{name ?? (loading && <Skeleton width={150} />)}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography>
            {serviceContractNumber ? `SCN # ${serviceContractNumber}` : loading && <Skeleton width={300} />}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography>
            <Link href={email && `mailto:${email}`} target="_blank" rel="noreferrer noopener">
              {email ?? (loading && <Skeleton width={250} />)}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography>{agreementType ?? (loading && <Skeleton width={200} />)}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography
            noWrap
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {phoneNumber || mobilePhoneNumber ? (
              <>
                {phoneNumber && (
                  <>
                    <PhoneIcon />
                    {formatPhone(phoneNumber)}
                  </>
                )}
                {mobilePhoneNumber && (
                  <>
                    <PhoneAndroidIcon />
                    {formatPhone(mobilePhoneNumber)}
                  </>
                )}
              </>
            ) : (
              loading && <Skeleton width={200} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography>{ptoDisplayText ?? (loading && <Skeleton width={250} />)}</Typography>
        </Grid>
        {fundBridge && (
          <Grid item xs={12} md={12} lg={12}>
            <MuiAlert
              elevation={6}
              variant="filled"
              severity="info"
              css={css`
                justify-content: center;
              `}
            >
              This is a fund bridge project. This project started in former Vivint systems, but has been transferred.
            </MuiAlert>
          </Grid>
        )}
        {isVivintSolarPostPTO && !isBlueBillingSystem && (
          <Grid item xs={12} md={12} lg={12} data-testid="orange-post-pto-banner">
            <MuiAlert
              elevation={6}
              variant="filled"
              severity="info"
              css={css`
                justify-content: center;
              `}
            >
              This is an Orange Post PTO account. It started in former Vivint Solar systems but was transferred to
              Sunrun. Billing is still processed by Vivint Solar. For assistance, see &quot;Info | Blue, Bridge, and
              Orange Post PTO Accounts&quot;
            </MuiAlert>
          </Grid>
        )}
      </Grid>
    </CollapsableCard>
  );
};

export { SITE_SUMMARY_CARD_QUERY };
export default SiteSummaryCard;
