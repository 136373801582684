import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';

const SITE_DETAILS_CARD_FRAGMENT = gql`
  fragment SiteDetailsCardFields on Prospect {
    id
    squareFootage
    roofingType
    yearHomeBuilt
    ageOfRoof
    numberOfStories
    shading
  }
`;

const SiteDetailsCard = ({ loading, data }) => {
  const { squareFootage, roofingType, yearHomeBuilt, ageOfRoof, numberOfStories, shading } = data?.prospect || {};

  return (
    <CollapsableCard title="Site Details">
      <Grid container direction="row" spacing={1}>
        <LabeledValue loading={loading} label="Square Footage" text={squareFootage} />
        <LabeledValue loading={loading} label="Year Built" text={yearHomeBuilt} />
        <LabeledValue loading={loading} label="Number of Stories" text={numberOfStories} />
        <LabeledValue loading={loading} label="Roof Type" text={roofingType} />
        <LabeledValue loading={loading} label="Age of Roof" text={ageOfRoof} />
        <LabeledValue loading={loading} label="Shading" text={shading} />
      </Grid>
    </CollapsableCard>
  );
};

SiteDetailsCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      squareFootage: PropTypes.number,
      roofingType: PropTypes.string,
      yearHomeBuilt: PropTypes.string,
      ageOfRoof: PropTypes.string,
      numberOfStories: PropTypes.string,
      shading: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
};

export { SITE_DETAILS_CARD_FRAGMENT };
export default SiteDetailsCard;
