import { useState, useEffect } from 'react';
import rollbar from '../utils/rollbar';

const fetchRootHTML = () => fetch('/', { cache: 'no-cache' }).then((response) => response.text());

const versionHashRegExp = /<!-- VERSION: (\S+) -->/;
const parseVersionHashFromHTML = (htmlStr) => htmlStr.match(versionHashRegExp)[1]; // get the first capture group

/**
 * repeatedly check for a new version of the main page
 * @param delay millisecond delay between checks, defaults to 5 min
 */
const useNewVersionDetector = (delay = 300000) => {
  const [newVersionDetected, setNewVersionDetected] = useState(false);
  useEffect(() => {
    let timerId;
    if (!newVersionDetected) {
      const checkForNewVersionOnRepeat = async () => {
        let nextHTML;
        let nextHash;
        try {
          nextHTML = await fetchRootHTML();
          nextHash = parseVersionHashFromHTML(nextHTML);
        } catch (error) {
          // not much we can do except try again in a bit
          rollbar.error(error, nextHTML);
        }
        if (nextHash && nextHash !== process.env.CODE_VERSION) {
          setNewVersionDetected(true);
        } else {
          timerId = setTimeout(checkForNewVersionOnRepeat, delay); // check again after delay
        }
      };
      timerId = setTimeout(checkForNewVersionOnRepeat, delay); // initial check after delay
    }
    return () => timerId && clearTimeout(timerId); // cleanup
  }, [delay, newVersionDetected]);
  return newVersionDetected;
};

export default useNewVersionDetector;
