/* eslint-disable no-case-declarations */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { css } from '@emotion/core';
import { DateTime } from 'luxon';
import { useHistory, useParams } from 'react-router-dom';
import humanizeDuration from 'humanize-duration';
import memoizeOne from 'memoize-one';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Skeleton from 'react-loading-skeleton';
import Button from '@material-ui/core/Button';

import { PAYMENT_ONE_TIME_PATH, pathToRoute } from '../constants/routes';
import mixpanel from '../utils/mixpanel';
import parseInteger from '../utils/parse-int';
import formatCurrency from '../utils/format-currency';
import { useUserAuthInfo } from '../components/auth';

import {
  friendlyBillingStatuses,
  ON_TIME,
  PAID_IN_FULL,
  CREDIT,
  OVERDUE,
  IN_COLLECTIONS,
  BLUE,
} from '../constants/billing-status';

/**
 * calculate the billing status
 * @param {string} lastDueDate
 * @param {string} amountOverdue
 * @param {string} totalAmountDue
 * @returns {string} status or null if no lastDueDate
 */
const getBillingStatus = memoizeOne((lastDueDate, amountOverdue, totalAmountDue, inCollections, dateOverdue) => {
  if (!lastDueDate) return null;
  const now = DateTime.local();
  if (inCollections) {
    const overdueDateTime = DateTime.fromISO(dateOverdue, { setZone: true });
    const msSinceOverdue = overdueDateTime.diffNow().valueOf();
    return msSinceOverdue / 1000 > 86400 * 31 ? IN_COLLECTIONS : null;
  }
  if (parseInteger(amountOverdue) > 0) return OVERDUE;
  if (parseInteger(totalAmountDue) === 0) return PAID_IN_FULL;
  if (parseInteger(totalAmountDue) < 0) return CREDIT;
  if (DateTime.fromISO(lastDueDate, { setZone: true }) >= now.toUTC()) return ON_TIME;
});

/**
 * return status text based on status
 * @param {string} status
 * @param {string} lastDueDate
 * @param {string} amountOverdue
 * @returns {string} statusText
 */
const getBillingStatusText = memoizeOne((status, lastDueDate, amountOverdue, isVivintSolarPostPTO) => {
  switch (status) {
    case IN_COLLECTIONS:
      return 'In Collections';
    case OVERDUE:
      return isVivintSolarPostPTO ? null : `${formatCurrency(amountOverdue)} Overdue`;
    case PAID_IN_FULL:
      return 'Paid in Full';
    case CREDIT:
      return 'Credit';
    case ON_TIME:
      const dateText = DateTime.fromISO(lastDueDate, { setZone: true }).toLocaleString();
      return `Due ${dateText}`;
    default:
      return null;
  }
});

/**
 * return status sub text based on status
 * @param {string} status
 * @param {string} dateOverdue
 * @returns {string} statusSubText or null
 */
const getBillingStatusSubText = memoizeOne((status, dateOverdue) => {
  switch (status) {
    case OVERDUE: {
      const overdueDateTime = DateTime.fromISO(dateOverdue, { setZone: true });
      const msSinceOverdue = overdueDateTime.diffNow().valueOf();
      return `since ${overdueDateTime.toLocaleString()} (${humanizeDuration(msSinceOverdue, {
        units: ['y', 'd'],
        round: true,
      })})`;
    }
    case IN_COLLECTIONS:
      const overdueDateTime = DateTime.fromISO(dateOverdue, { setZone: true });
      const msSinceOverdue = overdueDateTime.diffNow().valueOf();
      return `since ${overdueDateTime.toLocaleString()} (${humanizeDuration(msSinceOverdue, {
        units: ['y', 'd'],
        round: true,
      })})`;
    case PAID_IN_FULL:
    case CREDIT:
    case ON_TIME:
    default:
      return null;
  }
});

const BALANCE_AND_STATUS_FRAGMENT = gql`
  fragment BalanceAndStatusFields on Prospect {
    id
    billingSystem
    billing(contactId: $contactId) {
      id
      lastDueDate
      totalAmountDue
      amountOverdue
      dateOverdue
      inCollections
    }
  }
`;

const BalanceAndStatus = ({ data, loading, isVivintSolarPostPTO }) => {
  const { isUser } = useUserAuthInfo();
  const history = useHistory();
  const { prospectId, contactId } = useParams();
  const { lastDueDate, totalAmountDue, amountOverdue, dateOverdue, inCollections } = data?.prospect?.billing || {};
  const { billingSystem } = data?.prospect || {};
  const status = getBillingStatus(lastDueDate, amountOverdue, totalAmountDue, inCollections, dateOverdue);
  const statusText = getBillingStatusText(status, lastDueDate, amountOverdue, isVivintSolarPostPTO);
  const statusSubText = getBillingStatusSubText(status, dateOverdue);
  const formattedTotalAmountDue = (totalAmountDue || totalAmountDue === 0) && formatCurrency(totalAmountDue);
  const isBlueBillingSystem = billingSystem === BLUE;

  const navigateToOneTimePayment = () => {
    history.push(pathToRoute(PAYMENT_ONE_TIME_PATH, { prospectId, contactId }));
  };

  useEffect(() => {
    if (!loading) {
      mixpanel.track('View Billing Data', {
        Status: friendlyBillingStatuses[status] ?? 'Unknown',
      });
    }
  }, [loading, status]);

  return (
    <Grid item xs>
      <Card
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <CardHeader
          title={
            <span>
              Balance{' '}
              {statusText ? (
                <Chip
                  label={statusText}
                  color="primary"
                  css={(theme) => css`
                    ${(status === OVERDUE || status === IN_COLLECTIONS) &&
                    `background-color: ${theme.palette.sunrun.red}`}
                  `}
                />
              ) : (
                loading && <Skeleton width={120} height={32} />
              )}
              {statusSubText && <Typography variant="body2">{statusSubText}</Typography>}
            </span>
          }
        />
        <CardContent
          css={css`
            flex-grow: 1;
          `}
        >
          <Typography variant="h4">{formattedTotalAmountDue ?? (loading && <Skeleton width={175} />)}</Typography>
        </CardContent>
        {isUser && (
          <CardActions>
            <Button
              variant="text"
              color="primary"
              onClick={navigateToOneTimePayment}
              disabled={isVivintSolarPostPTO && !isBlueBillingSystem}
              data-testid="take-a-payment-btn"
            >
              Take a Payment
            </Button>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

BalanceAndStatus.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      billing: PropTypes.shape({
        lastDueDate: PropTypes.string,
        totalAmountDue: PropTypes.number,
        amountOverdue: PropTypes.number,
        dateOverdue: PropTypes.string,
        inCollections: PropTypes.bool,
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
  isVivintSolarPostPTO: PropTypes.bool,
};

BalanceAndStatus.defaultProps = {
  isVivintSolarPostPTO: false,
};

export { getBillingStatus, getBillingStatusText, getBillingStatusSubText, BALANCE_AND_STATUS_FRAGMENT };
export default BalanceAndStatus;
