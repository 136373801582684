import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useTheme } from '@material-ui/core/styles';
import tableIcons from '../constants/tableIcons';
import { gql } from '@apollo/client';
import ViewDocumentButton from './ViewDocumentButton';
import mixpanel from '../utils/mixpanel';
import { prepareMaterialTableData } from '../utils/helpers';

const DOCUMENT_TABLE_FRAGMENT = gql`
  fragment DocumentTableFields on Document {
    id
    fileName
    type
    createdDate
    s3Path
  }
`;

const columnNamesByIndex = ['Document Name', 'Type', 'Created Date', 'Actions'];

const DocumentsTable = ({ documents: docs, loading, documentsTab, emptyDataMessage = 'No documents found' }) => {
  const documents = prepareMaterialTableData(docs);
  const theme = useTheme();
  useEffect(() => {
    if (!loading && documents.length === 0) {
      mixpanel.track('No Documents returned', { 'Document Tab Name': documentsTab });
    }
  }, [loading, documents, documentsTab]);
  return (
    <MaterialTable
      columns={[
        { title: 'Document Name', field: 'fileName' },
        { title: 'Type', field: 'type' },
        { title: 'Created Date', field: 'createdDate', type: 'date', width: 150 },
        {
          title: 'Actions',
          render: (documentData) =>
            documentData.s3Path && (
              <ViewDocumentButton
                key={documentData.s3Path} // force unmount, otherwise it just updates the s3Path prop and attempts to reopen the doc
                s3Path={documentData.s3Path}
                documentName={documentData.fileName}
                documentsTab={documentsTab}
              />
            ),
          width: 30, // https://github.com/mbrn/material-table/issues/291#issuecomment-589621139
        },
      ]}
      isLoading={loading}
      data={documents}
      title={null}
      icons={tableIcons}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 25, 50],
        emptyRowsWhenPaging: false,
        paging: documents.length > 10,
        actionsColumnIndex: -1, // move actions column to the right
        draggable: false,
        showEmptyDataSourceMessage: !loading,
        rowStyle: (_rowData, rowNum) => ({
          backgroundColor: rowNum % 2 === 0 ? theme.palette.background.default : theme.palette.background.paper,
        }),
      }}
      localization={{ body: { emptyDataSourceMessage: emptyDataMessage } }}
      onSearchChange={(searchText) => mixpanel.track('Filtered document list', { 'Filter text': searchText })}
      onOrderChange={(orderBy, orderDirection) =>
        mixpanel.track('Sort document list', {
          'Column header': orderBy === -1 ? 'None (removed sort)' : columnNamesByIndex[orderBy],
          Direction: orderDirection || 'none', // asc, desc, none
        })
      }
    />
  );
};

DocumentsTable.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      createdDate: PropTypes.string.isRequired,
      s3Path: PropTypes.string.isRequired,
    }),
  ),
  loading: PropTypes.bool.isRequired,
  documentsTab: PropTypes.string.isRequired,
  emptyDataMessage: PropTypes.string,
};

export { DOCUMENT_TABLE_FRAGMENT };
export default DocumentsTable;
