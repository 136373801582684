import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import tableIcons from '../constants/tableIcons';
import { prepareMaterialTableData } from '../utils/helpers';

const SERVICE_CONTRACT_PROMOTIONS_QUERY = gql`
  query ServiceContractPromotionsQuery($prospectId: String!) {
    prospect(prospectId: $prospectId) {
      id
      promotions {
        id
        type
        promotionName
        offerAmount
        stackable
        applicationSource
        status
      }
    }
  }
`;

const PromotionsTable = ({ title, data: dt, loading }) => {
  const data = prepareMaterialTableData(dt);
  return (
    <MaterialTable
      title={title}
      columns={[
        { title: 'Promotion Name', field: 'promotionName' },
        { title: 'Offer Amount', field: 'offerAmount', type: 'currency', cellStyle: { textAlign: 'left' } },
        { title: 'Stackable', field: 'stackable', lookup: { true: 'Yes', false: 'No' } },
        { title: 'Auto Applied', field: 'applicationSource', lookup: { 'Auto Applied': 'Yes' }, emptyValue: 'No' },
        { title: 'Status', field: 'status' },
      ]}
      data={data}
      isLoading={loading}
      options={{
        paging: data.length > 5,
        draggable: false,
        showEmptyDataSourceMessage: !loading,
      }}
      icons={tableIcons}
    />
  );
};

PromotionsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      promotionName: PropTypes.string,
      offerAmount: PropTypes.number,
      stackable: PropTypes.bool,
      applicationSource: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

const ServiceContractPromotions = () => {
  const { prospectId } = useParams();
  const { loading, data } = useQuery(SERVICE_CONTRACT_PROMOTIONS_QUERY, {
    variables: { prospectId },
    returnPartialData: true,
  });
  const promotions = data?.prospect?.promotions || [];
  const appliedPromotions = promotions.filter(({ type, status }) => type === 'Offer' && status === 'Applied');
  const appeasements = promotions.filter(({ type, status }) => type === 'Customer' && status === 'Applied');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PromotionsTable title="Applied Promotions" data={appliedPromotions} loading={loading} />
      </Grid>
      <Grid item xs={12}>
        <PromotionsTable title="Appeasements" data={appeasements} loading={loading} />
      </Grid>
    </Grid>
  );
};

export { SERVICE_CONTRACT_PROMOTIONS_QUERY };
export default ServiceContractPromotions;
