import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';

const SITE_DETAILS_GENERAL_CARD_FRAGMENT = gql`
  fragment SiteDetailsGeneralCardFields on Prospect {
    id
    primaryTitleHolder
    county
    utilityCompany
    congressionalDistrictNumber
    latitude
    longitude
    municipality
    utilityMetroMarket
    HOAName
    timeZone
    assessorParcelNumber
    jurisdictionName
    hasEnergyEfficiencyProgram
  }
`;

const SiteDetailsGeneralCard = ({ loading, data }) => {
  const {
    primaryTitleHolder,
    county,
    utilityCompany,
    congressionalDistrictNumber,
    latitude,
    longitude,
    municipality,
    utilityMetroMarket,
    HOAName,
    timeZone,
    assessorParcelNumber,
    jurisdictionName,
    hasEnergyEfficiencyProgram,
  } = data?.prospect || {};

  return (
    <CollapsableCard title="General" mixpanelEventName="Toggled Site Details > General card expand/collapse">
      <Grid container direction="row" spacing={1}>
        <LabeledValue loading={loading} label="Title Holder" text={primaryTitleHolder} />
        <LabeledValue loading={loading} label="Lat/Lon" text={latitude && longitude && `${latitude}, ${longitude}`} />
        <LabeledValue loading={loading} label="Site Time Zone" text={timeZone} />
        <LabeledValue loading={loading} label="County" text={county} />
        <LabeledValue loading={loading} label="Municipality" text={municipality} />
        <LabeledValue
          loading={loading}
          label="APN"
          tooltip="Assessor Parcel Number - a unique number assigned to each parcel of land by a county tax assessor"
          text={assessorParcelNumber}
        />
        <LabeledValue loading={loading} label="Utility" text={utilityCompany} />
        <LabeledValue loading={loading} label="Utility Metro Market" text={utilityMetroMarket} />
        <LabeledValue
          loading={loading}
          label="AHJ"
          tooltip={
            'Authority Having Jurisdiction - the organization, office, or individual ' +
            'responsible for approving layout drawings, equipment or an installation'
          }
          text={jurisdictionName}
        />
        <LabeledValue loading={loading} label="Congress District Number" text={congressionalDistrictNumber} />
        <LabeledValue loading={loading} label="HOA Name" text={HOAName} />
        <LabeledValue loading={loading} label="Energy Efficiency Program" text={hasEnergyEfficiencyProgram} />
      </Grid>
    </CollapsableCard>
  );
};

SiteDetailsGeneralCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      primaryTitleHolder: PropTypes.string,
      county: PropTypes.string,
      utilityCompany: PropTypes.string,
      congressionalDistrictNumber: PropTypes.string,
      latitude: PropTypes.string,
      longitude: PropTypes.string,
      municipality: PropTypes.string,
      utilityMetroMarket: PropTypes.string,
      HOAName: PropTypes.string,
      timeZone: PropTypes.string,
      assessorParcelNumber: PropTypes.string,
      jurisdictionName: PropTypes.string,
      hasEnergyEfficiencyProgram: PropTypes.bool,
    }),
  }),
  loading: PropTypes.bool,
};

export { SITE_DETAILS_GENERAL_CARD_FRAGMENT };
export default SiteDetailsGeneralCard;
