import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { useParams } from 'react-router-dom';
import { HomeEnergyReport } from '@sunrun/ui-home-energy';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

import { useHybridAuthToken } from '../components/hybridAuthToken';
import useEnvConfig from '../hooks/useEnvConfig';

const HER_PAGE_QUERY = gql`
  query GetFieldsForHomeEnergyReportQuery($prospectId: String!, $contactId: ID!) {
    contact(id: $contactId) {
      id
      firstName
      lastName
    }
    prospect(prospectId: $prospectId) {
      id
      zipCode
      agreementType
      PTO
    }
  }
`;

const HomeEnergyReportPage = () => {
  const { homeEnergyReportAPIUrl } = useEnvConfig();
  const { prospectId, contactId } = useParams();
  const hybridAuth = useHybridAuthToken();
  const { loading, data } = useQuery(HER_PAGE_QUERY, { variables: { prospectId, contactId } });

  if (loading) return <LinearProgress />;

  const { zipCode, agreementType, PTO } = data?.prospect ?? {};
  const { firstName, lastName } = data?.contact ?? {};

  if (!zipCode || !agreementType || !PTO) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <Typography>
          Unable to run Home Energy Report for this customer because they are missing one of the following:
        </Typography>
        <Typography>Zip Code, Agreement Type, PTO Date</Typography>
      </Alert>
    );
  }

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <HomeEnergyReport
              prospectId={prospectId}
              authToken={hybridAuth}
              apiUrl={homeEnergyReportAPIUrl}
              zipCode={zipCode}
              firstName={firstName}
              lastName={lastName}
              agreementType={agreementType}
              isLeasePPA={false}
              pto={PTO}
              contactId={contactId}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HomeEnergyReportPage;
