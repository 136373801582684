import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import useEnvConfig from '../hooks/useEnvConfig';
import { useHybridAuthToken } from '../components/hybridAuthToken';
import { ProjectStatusStepCard } from './ProjectStatusStepCard';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LabeledValue from '../components/LabeledValue';
import { css } from '@emotion/core';
import { getDisplaySubTitle } from '../utils/helpers';
import { useUserAuthInfo } from '../components/auth';
import { CircularProgress } from '@material-ui/core';

const ProjectFetchState = {
  Inert: 'inert',
  Fetching: 'fetching',
  Error: 'error',
  Success: 'success',
};

function useProjectStatusOverview(prospectId) {
  const [status, setStatus] = useState(ProjectFetchState.Inert);
  const [data, setData] = useState();

  const hybridAuthToken = useHybridAuthToken();
  const { statusTrackerAPIUrl } = useEnvConfig();

  useEffect(() => {
    if (!hybridAuthToken || !prospectId) {
      return;
    }
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const response = await axios.get(`${statusTrackerAPIUrl}/project/status-overview/${prospectId}`, {
          signal: controller.signal,
          headers: {
            Authorization: hybridAuthToken,
          },
        });

        setStatus(ProjectFetchState.Success);
        setData(response.data);
      } catch (error) {
        setStatus(ProjectFetchState.Error);
      }
    };

    if (status === ProjectFetchState.Inert) {
      void fetchData();
    }

    return () => {
      controller.abort();
    };
  }, [hybridAuthToken, prospectId, status, statusTrackerAPIUrl]);

  return { data, status };
}

const ProjectStatusOverview = ({ prospectId }) => {
  const { isAdmin } = useUserAuthInfo();

  const { data: projectData, status } = useProjectStatusOverview(prospectId);

  if (status === ProjectFetchState.Error) {
    return (
      <div data-testid="responseErrorMessage">
        There was an error retrieving project data for prospectId {prospectId}
      </div>
    );
  }

  if (status === ProjectFetchState.Fetching || status === ProjectFetchState.Inert) {
    return (
      <div data-testid="loader" css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!projectData && (status === ProjectFetchState.Success || status === ProjectFetchState.Error)) {
    return <div data-testid="noProjectDataMessage">No Project Data</div>;
  }

  if (status === ProjectFetchState.Error) {
    return <div data-testid="noProjectDataMessage">Error fetching project data. Try refreshing the page.</div>;
  }

  if (!projectData.projectStatusOverview) {
    return <div data-testid="noProjectStatusOverviewMessage">No Project Overview</div>;
  }

  if (!projectData.projectStatusOverview.steps) {
    return (
      <div data-testid="noProjectStepsMessage">
        <div>Project id: {projectData.projectStatusOverview.projectId}</div>
        <div>Status: {projectData.projectStatusOverview.status}</div>
        <div>No Project Steps Exist</div>
      </div>
    );
  }

  const ProjectOverview = () => (
    <div
      data-testid="projectOverviewContainer"
      css={css`
        margin: 36px 0;
      `}
    >
      <Typography
        variant="h5"
        css={css`
          display: block;
          color: #818589;
        `}
      >
        Project Overview
      </Typography>
      <Grid
        container
        direction="row"
        spacing={1}
        css={css`
          margin-top: 24px;
        `}
      >
        <LabeledValue label="Project ID" text={projectData.projectStatusOverview.projectId} loading={false} />
        <LabeledValue label="Status" text={projectData.projectStatusOverview.status} loading={false} />
        <LabeledValue
          label="Fulfillment Path"
          text={projectData.projectStatusOverview.fulfillmentPath}
          loading={false}
        />
      </Grid>
    </div>
  );

  const ProjectSteps = () => (
    <div
      data-testid="projectStepsContainer"
      css={css`
        margin: 36px 0;
      `}
    >
      <Typography
        variant="h5"
        css={css`
          display: block;
          color: #818589;
        `}
      >
        Project Steps
      </Typography>
      <div
        css={css`
          margin-top: 16px;
        `}
      >
        {projectData.projectStatusOverview.steps.map((step, index) => (
          <ProjectStatusStepCard step={step} key={index} />
        ))}
      </div>
    </div>
  );

  const ProjectTasks = () => {
    const tasks = projectData.projectStatusOverview.wfmTasks;

    if (!tasks) {
      return null;
    }

    return (
      <div
        data-testid="projectTasksContainer"
        css={css`
          margin: 36px 0;
        `}
      >
        <Typography
          variant="h5"
          css={css`
            display: block;
            color: #818589;
          `}
        >
          WFM Tasks
        </Typography>
        <ol
          css={css`
            margin-top: 16px;
          `}
        >
          {tasks.map((task, index) => (
            <li
              key={index}
              css={css`
                margin-bottom: 16px;
              `}
            >
              <div>
                <span
                  css={css`
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-right: 12px;
                  `}
                >
                  Name:
                </span>
                {task.name}
              </div>
              <div>
                <span
                  css={css`
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-right: 12px;
                  `}
                >
                  Status:
                </span>
                {task.status}
              </div>
              <div>
                <span
                  css={css`
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-right: 12px;
                  `}
                >
                  Due Date:
                </span>
                {task.dueDate}
              </div>
              <div>
                <span
                  css={css`
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-right: 12px;
                  `}
                >
                  First Time End:
                </span>
                {task.firstTimeEnd}
              </div>
            </li>
          ))}
        </ol>
      </div>
    );
  };

  const SupportReps = () => {
    const reps = projectData.projectStatusOverview.currentCustomerSupportReps;

    if (!reps) {
      return null;
    }

    return (
      <div
        data-testid="projectSupportRepsContainer"
        css={css`
          margin: 36px 0;
        `}
      >
        <Typography
          variant="h5"
          css={css`
            display: block;
            color: #818589;
          `}
        >
          Support Reps
        </Typography>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid
              container
              spacing={4}
              css={css`
                margin-top: 16px;
              `}
            >
              {reps.map((rep, index) => (
                <Grid key={index} item xs={12} md={6}>
                  <div
                    css={css`
                      margin-bottom: 16px;
                    `}
                  >
                    <span
                      css={css`
                        text-transform: uppercase;
                        font-weight: 500;
                        display: block;
                        margin-right: 12px;
                      `}
                    >
                      Type
                    </span>
                    {getDisplaySubTitle(rep.type) || '--'}
                  </div>
                  <div
                    css={css`
                      margin-bottom: 16px;
                    `}
                  >
                    <span
                      css={css`
                        text-transform: uppercase;
                        font-weight: 500;
                        display: block;
                        margin-right: 12px;
                      `}
                    >
                      Name
                    </span>
                    {rep.name || '--'}
                  </div>
                  <div
                    css={css`
                      margin-bottom: 16px;
                    `}
                  >
                    <span
                      css={css`
                        text-transform: uppercase;
                        font-weight: 500;
                        display: block;
                        margin-right: 12px;
                      `}
                    >
                      Email
                    </span>
                    {rep.email || '--'}
                  </div>
                  <div
                    css={css`
                      margin-bottom: 16px;
                    `}
                  >
                    <span
                      css={css`
                        text-transform: uppercase;
                        font-weight: 500;
                        display: block;
                        margin-right: 12px;
                      `}
                    >
                      Phone
                    </span>
                    {rep.phone || '--'}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <ProjectOverview />
      <SupportReps />
      <Grid container>
        <Grid item sm={12} md={6}>
          <ProjectSteps />
        </Grid>
      </Grid>
      {isAdmin && <ProjectTasks />}
    </>
  );
};

ProjectStatusOverview.propTypes = {
  prospectId: PropTypes.string.isRequired,
};

export default ProjectStatusOverview;
