import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@emotion/core';
import { UIPaymentMethodEnum, PayMethodEnum, PaymentFormLabels } from './paymentConstants';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography } from '@material-ui/core';

const PaymentMethodOptions = ({ selectedPaymentMethod, isCardEligible, handlePaymentMethodOnChange }) => {
  const handleOnChange = (event) => {
    handlePaymentMethodOnChange(event.target.value);
  };

  return (
    <FormControl
      fullWidth={true}
      data-testid="payment-method-container"
      css={css`
        margin: 24px 0;
      `}
    >
      <FormLabel id="payment-method-label">
        <Typography
          variant="h5"
          component="span"
          css={css`
            color: #000;
          `}
        >
          {PaymentFormLabels.PAYMENT_METHOD}
        </Typography>
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="payment-method-label"
        value={selectedPaymentMethod}
        name="payment-method-group"
        onChange={handleOnChange}
      >
        <FormControlLabel value={PayMethodEnum.CHECKING} control={<Radio />} label={UIPaymentMethodEnum.echeck} />
        <FormControlLabel value={PayMethodEnum.SAVINGS} control={<Radio />} label={UIPaymentMethodEnum.savings} />
        {isCardEligible && (
          <FormControlLabel value={PayMethodEnum.CARD} control={<Radio />} label={UIPaymentMethodEnum.card} />
        )}
      </RadioGroup>
    </FormControl>
  );
};

PaymentMethodOptions.propTypes = {
  selectedPaymentMethod: PropTypes.string.isRequired,
  isCardEligible: PropTypes.bool,
  handlePaymentMethodOnChange: PropTypes.func.isRequired,
};

export default PaymentMethodOptions;
