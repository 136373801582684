import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import MaterialTable, { MTableToolbar } from 'material-table';
import Papa from 'papaparse';
import tableIcons from '../constants/tableIcons';
import Grid from '@material-ui/core/Grid';
import ViewBillButton from './ViewBillButton';
import useEnvConfig from '../hooks/useEnvConfig';
import useGetReportTransactions from '../hooks/useGetReportTransactions';
import { useParams } from 'react-router';
import { prepareMaterialTableData, formatDateField } from '../utils/helpers';
import { transactionType, transactionDescriptionByType, sectionTitle } from '../constants/transactions';
import TransactionHistoryToolbar from './TransactionHistoryToolbar';

const TRANSACTION_HISTORY_FRAGMENT = gql`
  fragment TransactionHistoryFields on Prospect {
    id
    name
    serviceContractNumber
    billing(contactId: $contactId) {
      id
      transactions {
        id
        date
        dueDate
        type
        amount
        runningBalance
        status
        comments
        invoiceKey
        isSpecialInvoice
      }
    }
  }
`;

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters > :nth-child(1)': {
      flexGrow: '1',
    },
    '& .MuiToolbar-gutters > :nth-child(2)': {
      flex: 'unset',
    },
    '& .MuiToolbar-gutters > :nth-child(3)': {
      paddingLeft: '8px',
    },
  },
});

const TransactionHistory = ({ data, loading }) => {
  const { enableRunningBalance } = useEnvConfig();
  const { contactId } = useParams();
  const billingTransactions = data?.prospect?.billing?.transactions || [];
  const transactions = prepareMaterialTableData(billingTransactions);
  const { name, serviceContractNumber } = data?.prospect || {};
  const reportElements = useGetReportTransactions(billingTransactions);

  const handleDownloadReport = () => {
    const csv = Papa.unparse(reportElements, {
      columns: ['month', 'year', 'invoiceAmount', 'paid', 'credit'],
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name} ${serviceContractNumber}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <MaterialTable
        title={<TransactionHistoryToolbar variant="h6" title={sectionTitle} onclick={handleDownloadReport} />}
        components={{
          Toolbar: (props) => (
            <div id="transaction-history-toolbar" className={classes.toolbarWrapper}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        columns={[
          {
            title: 'Date',
            field: 'date',
            type: 'date',
            defaultSort: 'desc',
            render: (transaction) => formatDateField(transaction?.date),
          },
          {
            title: 'Transaction',
            sorting: false,
            customFilterAndSearch: (term, { comments, type }) =>
              (comments ?? transactionDescriptionByType[type] ?? '').toUpperCase().includes(term.toUpperCase()),
            render: ({ comments, type }) => comments ?? transactionDescriptionByType[type],
          },
          {
            title: 'Invoice',
            sorting: false,
            searchable: false,
            // eslint-disable-next-line react/prop-types
            render: ({ date, invoiceKey }) => (
              <ViewBillButton small invoiceDate={date} invoiceKey={invoiceKey} contactId={contactId} />
            ),
          },
          {
            title: 'Status',
            sorting: false,
            field: 'status',
            // eslint-disable-next-line react/prop-types
            render: ({ type, status }) => {
              const label = type === transactionType.CREDIT ? 'CREDIT' : status;
              return (
                label && (
                  <Chip
                    label={label}
                    color={status === 'PAID' ? 'primary' : 'secondary'}
                    variant="outlined"
                    size="small"
                  />
                )
              );
            },
          },
          { title: 'Amount', field: 'amount', type: 'currency', sorting: false, cellStyle: { textAlign: 'right' } },
          {
            title: 'Balance',
            field: 'runningBalance',
            type: 'currency',
            sorting: false,
            cellStyle: { textAlign: 'right' }, // https://github.com/mbrn/material-table/issues/1369#issuecomment-605737295
            hidden: !enableRunningBalance,
          },
        ]}
        isLoading={loading}
        data={transactions}
        localization={{ body: { emptyDataSourceMessage: "Customer doesn't have any transactions yet" } }}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          emptyRowsWhenPaging: false,
          paging: transactions.length > 10,
          draggable: false,
          showEmptyDataSourceMessage: !loading,
        }}
        icons={tableIcons}
      />
    </Grid>
  );
};

TransactionHistory.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      name: PropTypes.string,
      serviceContractNumber: PropTypes.string,
      billing: PropTypes.shape({
        transactions: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.oneOf(['INV', 'CM', 'DM', 'PAYMENT', 'CREDIT_REFUND', 'PAYMENT_REFUND']),
            date: PropTypes.string,
            dueDate: PropTypes.string,
            comments: PropTypes.string,
            amount: PropTypes.number,
            status: PropTypes.string,
            runningBalance: PropTypes.number,
            invoiceKey: PropTypes.string,
          }),
        ),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
};

export { TRANSACTION_HISTORY_FRAGMENT };
export default TransactionHistory;
