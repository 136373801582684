import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import ArrayInformationCard, { ARRAY_INFORMATION_CARD_FRAGMENT } from './ArrayInformationCard';
import MeterInformationCard, { METER_INFORMATION_CARD_FRAGMENT } from './MeterInformationCard';
import BatteryInformationCard, { BATTERY_INFORMATION_CARD_FRAGMENT } from './BatteryInformationCard';
import GatewayInformationCard, { GATEWAY_INFORMATION_CARD_FRAGMENT } from './GatewayInformationCard';
import SiteDesignImageCard from './SiteDesignImageCard';
import useEnvConfig from '../hooks/useEnvConfig';

const SYSTEM_OVERVIEW_QUERY = gql`
  query SystemOverviewQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      id
      ...MeterInformationCardFields
      ...ArrayInformationCardFields
      ...BatteryInformationCardFields
      ...GatewayInformationCardFields
    }
  }
  ${METER_INFORMATION_CARD_FRAGMENT}
  ${ARRAY_INFORMATION_CARD_FRAGMENT}
  ${BATTERY_INFORMATION_CARD_FRAGMENT}
  ${GATEWAY_INFORMATION_CARD_FRAGMENT}
`;

const SystemOverview = () => {
  const { prospectId } = useParams();
  const { loading, data } = useQuery(SYSTEM_OVERVIEW_QUERY, {
    variables: { prospectId },
    fetchPolicy: 'no-cache',
  });

  const { enableInverterPortal } = useEnvConfig();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MeterInformationCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <ArrayInformationCard loading={loading} data={data} enableInverterPortal={enableInverterPortal} />
      </Grid>
      <Grid item xs={12}>
        <BatteryInformationCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <GatewayInformationCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <SiteDesignImageCard />
      </Grid>
    </Grid>
  );
};

export { SYSTEM_OVERVIEW_QUERY };
export default SystemOverview;
