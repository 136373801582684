import React, { useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import rollbar from '../utils/rollbar';
import mixpanel from '../utils/mixpanel';
import { DASH_BOARD_PATH } from '../constants/routes';

export const InvalidRoute = () => {
  const location = useLocation();
  useEffect(() => {
    rollbar.warn(`A user tried to access an invalid route: ${location.pathname}`);
    mixpanel.track('Invalid Route Attempted', { path: location.pathname });
  }, [location]);

  return <Redirect to={{ pathname: DASH_BOARD_PATH, state: { invalidRoute: true } }} />;
};
