import memoizeOne from 'memoize-one';

const DEFAULT_CURRENCY_CODE = 'USD';

const supportedCurrencies = ['USD'];

const formatCurrency = (number, currency, precision = 2) => {
  const currencyCode = supportedCurrencies.includes(currency) ? currency : DEFAULT_CURRENCY_CODE;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return currencyFormatter.format(number);
};

export default memoizeOne(formatCurrency);
