import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { css } from '@emotion/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { BILLING_CONTACT_PATH, pathToRoute } from '../constants/routes';

import formatPhone from '../utils/format-phone';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

const OWNER_SELECT_CARD_FRAGMENT = gql`
  fragment OwnerSelectCardContactFields on Contact {
    contactId
    name
    email
    phone
    mobilePhone
  }
  fragment OwnerSelectCardFields on Prospect {
    id
    currentOwner {
      ...OwnerSelectCardContactFields
    }
    reassignments {
      contactId
      effectiveDate
      contact {
        ...OwnerSelectCardContactFields
      }
    }
  }
`;

const OwnerSelectCard = ({ loading, data }) => {
  const { prospectId, contactId } = useParams();
  const history = useHistory();
  const { currentOwner, reassignments } = data?.prospect || {};

  if (!reassignments?.length) return null; // only show this card if there are previous owners

  const ownersList = [];
  if (currentOwner) {
    ownersList.push({
      displayText: currentOwner?.name ? `${currentOwner.name} (Current Owner)` : 'Current Owner',
      contactId: currentOwner.contactId,
    });
  }
  reassignments.forEach((reassignment) =>
    ownersList.push({
      displayText: `${reassignment.contact?.name} (until ${reassignment.effectiveDate})`,
      contactId: reassignment.contactId,
    }),
  );

  const selectedContact =
    contactId && contactId === currentOwner?.contactId
      ? currentOwner
      : reassignments?.find((reassignment) => contactId === reassignment.contactId)?.contact;
  return (
    <Grid item xs>
      <Card
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <CardHeader
          title={!currentOwner || contactId === currentOwner?.contactId ? 'Current Owner' : 'Previous Owner'}
        />
        <CardContent
          css={css`
            flex-grow: 1;
          `}
        >
          <Typography>{selectedContact?.name ?? (loading && <Skeleton width={150} />)}</Typography>
          <Typography>
            <Link
              href={selectedContact?.email && `mailto:${selectedContact?.email}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {selectedContact?.email ?? (loading && <Skeleton width={250} />)}
            </Link>
          </Typography>
          <Typography
            noWrap
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {selectedContact?.phone || selectedContact?.mobilePhone ? (
              <>
                {selectedContact?.phone && (
                  <>
                    <PhoneIcon />
                    {formatPhone(selectedContact?.phone)}
                  </>
                )}
                {selectedContact?.mobilePhone && (
                  <>
                    <PhoneAndroidIcon />
                    {formatPhone(selectedContact?.mobilePhone)}
                  </>
                )}
              </>
            ) : (
              loading && <Skeleton width={200} />
            )}
          </Typography>
        </CardContent>
        <CardActions>
          {ownersList.length > 0 && (
            <FormControl>
              <Select
                value={contactId}
                onChange={(e) =>
                  history.push(pathToRoute(BILLING_CONTACT_PATH, { prospectId, contactId: e.target.value }))
                }
              >
                {ownersList.map((owner) => (
                  <MenuItem key={owner.contactId} value={owner.contactId}>
                    {owner.displayText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

OwnerSelectCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      currentOwner: PropTypes.shape({
        contactId: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        mobilePhone: PropTypes.string,
      }),
      reassignments: PropTypes.arrayOf(
        PropTypes.shape({
          contactId: PropTypes.string,
          effectiveDate: PropTypes.string,
          contact: PropTypes.shape({
            contactId: PropTypes.string,
            name: PropTypes.string,
            email: PropTypes.string,
            phone: PropTypes.string,
            mobilePhone: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
};

export { OWNER_SELECT_CARD_FRAGMENT };
export default OwnerSelectCard;
