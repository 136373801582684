import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import MaterialTable from 'material-table';
import tableIcons from '../constants/tableIcons';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import LabeledValue from '../components/LabeledValue';
import { prepareMaterialTableData } from '../utils/helpers';

const PERFORMANCE_GUARANTEE_CARD_FRAGMENT = gql`
  fragment PerformanceGuaranteeCardFields on ServiceContract {
    id
    yearlyPerformanceInfo {
      id
      year
      guaranteedOutput
      refundRate
      TEYearlyDegradation
    }
    monthlySolarProductionRatio
  }
`;

const smallerBrkPts = {
  xs: 6,
  sm: 3,
  md: 2,
  lg: 1,
  xl: 1,
};

const PerformanceGuaranteeCard = ({ loading, data }) => {
  const { yearlyPerformanceInfo: YPI, monthlySolarProductionRatio } = data?.prospect?.serviceContract || {};
  const yearlyPerformanceInfo = prepareMaterialTableData(YPI);
  return (
    <CollapsableCard title="Performance Guarantee">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Yearly Production Information</Typography>
          <MaterialTable
            columns={[
              { title: 'Year Number', field: 'year' },
              { title: 'Guaranteed production (kWh)', field: 'guaranteedOutput' },
              { title: 'Refund Rate', field: 'refundRate' },
              { title: 'TEYearlyDegradation', field: 'TEYearlyDegradation' },
            ]}
            isLoading={loading}
            data={yearlyPerformanceInfo}
            options={{
              toolbar: false, // we are showing our own title for better alignment
              pageSize: 5,
              paging: yearlyPerformanceInfo?.length > 5,
              pageSizeOptions: [5, 10, 25],
              draggable: false,
              sorting: false,
              search: false,
              showEmptyDataSourceMessage: !loading,
            }}
            localization={{
              body: { emptyDataSourceMessage: 'No yearly production guarantee is associated with this contract' },
            }}
            components={{
              Container: TableContainer, // don't put it on Paper
            }}
            icons={tableIcons}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Monthly Solar Production Ratio</Typography>
        </Grid>
        <LabeledValue label="January" text={monthlySolarProductionRatio?.[0]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="February" text={monthlySolarProductionRatio?.[1]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="March" text={monthlySolarProductionRatio?.[2]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="April" text={monthlySolarProductionRatio?.[3]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="May" text={monthlySolarProductionRatio?.[4]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="June" text={monthlySolarProductionRatio?.[5]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="July" text={monthlySolarProductionRatio?.[6]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="August" text={monthlySolarProductionRatio?.[7]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="September" text={monthlySolarProductionRatio?.[8]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="October" text={monthlySolarProductionRatio?.[9]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="November" text={monthlySolarProductionRatio?.[10]} loading={loading} {...smallerBrkPts} />
        <LabeledValue label="December" text={monthlySolarProductionRatio?.[11]} loading={loading} {...smallerBrkPts} />
      </Grid>
    </CollapsableCard>
  );
};

PerformanceGuaranteeCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      serviceContract: PropTypes.shape({
        yearlyPerformanceInfo: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            year: PropTypes.number.isRequired,
            guaranteedOutput: PropTypes.number,
            refundRate: PropTypes.number,
            TEYearlyDegradation: PropTypes.number,
          }),
        ),
        monthlySolarProductionRatio: PropTypes.arrayOf(PropTypes.number),
      }),
    }),
  }),
  loading: PropTypes.bool,
};

export { PERFORMANCE_GUARANTEE_CARD_FRAGMENT };
export default PerformanceGuaranteeCard;
