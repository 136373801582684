import React from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { SERVICE_CONTRACT_OVERVIEW_PATH, SERVICE_CONTRACT_PROMOTIONS_PATH, pathToRoute } from '../constants/routes';
import ServiceContractOverview from './ServiceContractOverview';
import ServiceContractPromotions from './ServiceContractPromotions';

const ServiceContractPage = () => {
  const { prospectId } = useParams();
  return (
    <Switch>
      <Route path={SERVICE_CONTRACT_OVERVIEW_PATH}>
        <ServiceContractOverview />
      </Route>
      <Route path={SERVICE_CONTRACT_PROMOTIONS_PATH}>
        <ServiceContractPromotions />
      </Route>
      <Redirect to={pathToRoute(SERVICE_CONTRACT_OVERVIEW_PATH, { prospectId })} />
    </Switch>
  );
};

export default ServiceContractPage;
