import { transactionType, reportLabel } from '../constants/transactions';

const useGetReportTransactions = (transactions) => {
  // Filter out debit (DM) and any other transaction types that are not included in the report.
  const filteredTransactions = transactions
    ? transactions.filter((transaction) => Object.values(transactionType).includes(transaction.type))
    : [];
  const groupedTransactions = filteredTransactions.reduce((acc, transaction) => {
    const dateString = transaction.type === transactionType.INVOICE ? transaction.dueDate : transaction.date;
    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    if (!acc[year]) acc[year] = {};
    if (!acc[year][month]) acc[year][month] = { invoiceAmount: 0, paid: 0, credit: 0 };
    // CM, CREDIT_REFUND and PAYMENT_REFUND amounts are consolidated as credit.
    if (transaction.type === transactionType.INVOICE) {
      acc[year][month].invoiceAmount += transaction.amount;
    } else if (transaction.type === transactionType.PAYMENT) {
      acc[year][month].paid += transaction.amount;
    } else {
      acc[year][month].credit += transaction.amount;
    }

    return acc;
  }, {});

  let invoiceSum = 0;
  let paidSum = 0;
  let creditSum = 0;

  const reportRow = {
    month: '',
    year: '',
    invoiceAmount: '',
    paid: '',
    credit: '',
  };
  const reportElements = Object.keys(groupedTransactions).flatMap((year) =>
    Object.keys(groupedTransactions[year]).map((month) => {
      const { invoiceAmount, paid, credit } = groupedTransactions[year][month];
      invoiceSum += invoiceAmount;
      paidSum += paid;
      creditSum += credit;
      return {
        month,
        year,
        invoiceAmount: invoiceAmount ? invoiceAmount.toFixed(2) : '',
        paid: paid ? paid.toFixed(2) : '',
        credit: credit ? credit.toFixed(2) : '',
      };
    }),
  );

  reportElements.push({
    ...reportRow,
    invoiceAmount: invoiceSum ? invoiceSum.toFixed(2) : '0',
    paid: paidSum ? paidSum.toFixed(2) : '0',
    credit: creditSum ? creditSum.toFixed(2) : '0',
  });
  const pastDueAmount = invoiceSum - paidSum + creditSum;
  reportElements.push({ ...reportRow });
  reportElements.push({ ...reportRow, year: reportLabel, paid: pastDueAmount.toFixed(2) });

  return reportElements;
};

export default useGetReportTransactions;
