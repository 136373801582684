import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import MaterialTable from 'material-table';
import tableIcons from '../constants/tableIcons';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import formatCurrency from '../utils/format-currency';
import { prepareMaterialTableData, formatDateField } from '../utils/helpers';

const COLLECTIONS_NOTES_FRAGMENT = gql`
  fragment CollectionsNotesFields on Prospect {
    id
    billing(contactId: $contactId) {
      id
      inCollections
      amountInCollections
      enteredCollectionsDate
      collectionsNotes {
        id
        author
        enteredDate
        note
      }
    }
  }
`;

const CollectionsNotes = ({ data }) => {
  const { inCollections, amountInCollections, enteredCollectionsDate } = data?.prospect?.billing || {};
  if (!inCollections) return null;

  const notes = prepareMaterialTableData(data?.prospect?.billing?.collectionsNotes || []);
  const enteredCollectionsDateText = enteredCollectionsDate
    ? DateTime.fromISO(enteredCollectionsDate, { setZone: true }).toLocaleString()
    : '';
  const sinceText = enteredCollectionsDate ? ` since ${enteredCollectionsDateText}` : '';
  const title = `Collections Notes (${formatCurrency(amountInCollections)} overdue${sinceText})`;
  return (
    <Grid item xs={12}>
      <MaterialTable
        title={title}
        columns={[
          {
            title: 'Date',
            field: 'enteredDate',
            type: 'date',
            width: 1,
            render: (note) => formatDateField(note?.enteredDate),
          },
          { title: 'Author', field: 'author', width: 1, cellStyle: { whiteSpace: 'nowrap' } },
          {
            title: 'Preview',
            field: 'note',
            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100 },
          },
        ]}
        data={notes}
        localization={{ body: { emptyDataSourceMessage: "Customer doesn't have any collections notes" } }}
        detailPanel={({ note }) => (
          <Box padding={2}>
            <Typography>{note}</Typography>
          </Box>
        )}
        options={{
          pageSize: 5,
          pageSizeOptions: [1, 5, 10],
          emptyRowsWhenPaging: false,
          paging: notes.length > 1,
          draggable: false,
        }}
        icons={tableIcons}
      />
    </Grid>
  );
};

CollectionsNotes.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      billing: PropTypes.shape({
        inCollections: PropTypes.bool,
        amountInCollections: PropTypes.number,
        enteredCollectionsDate: PropTypes.string,
        collectionsNotes: PropTypes.arrayOf(
          PropTypes.shape({
            author: PropTypes.string,
            enteredDate: PropTypes.string,
            note: PropTypes.string,
          }),
        ),
      }),
    }),
  }),
};

export { COLLECTIONS_NOTES_FRAGMENT };
export default CollectionsNotes;
