import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

/**
 * Get query parameters from the current URL
 * @returns {object} key value pairs of query parameters and their values
 */
const useSearchParams = () => queryString.parse(useLocation().search);

export default useSearchParams;
