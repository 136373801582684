export const transactionType = {
  INVOICE: 'INV',
  CREDIT: 'CM',
  PAYMENT: 'PAYMENT',
  CREDIT_REFUND: 'CREDIT_REFUND',
  PAYMENT_REFUND: 'PAYMENT_REFUND',
};

export const transactionDescriptionByType = {
  PAYMENT: 'Payment Received',
  CM: 'Credit Received',
  INV: 'Invoice',
};

export const downloadReportButtonText = 'DOWNLOAD REPORT';
export const downloadTooltipMessage =
  'Download a .csv file including all invoice amounts, payments, and credits by month';
export const reportLabel = 'Past Due Amount';
export const sectionTitle = 'Transaction History';
