import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { css } from '@emotion/core';
import CollapsableCard from '../components/CollapsableCard';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const SITE_DESIGN_IMAGE_CARD_QUERY = gql`
  query SiteDesignImageCardQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      id
      siteDesignImages {
        id
        small
        large
        pins
      }
    }
  }
`;

const SiteDesignCardImage = () => {
  const { prospectId } = useParams();
  const { loading, data } = useQuery(SITE_DESIGN_IMAGE_CARD_QUERY, {
    variables: { prospectId },
    returnPartialData: true,
  });
  const { small, large, pins } = data?.prospect?.siteDesignImages || {};
  const imgSource = pins || small || large;
  return (
    <CollapsableCard title="Site Design Image">
      {imgSource ? (
        <img
          src={imgSource}
          alt="Site design image"
          css={css`
            margin: auto;
            display: block;
          `}
        />
      ) : loading ? (
        <Skeleton
          variant="rect"
          height={484}
          width={580}
          css={css`
            margin: auto;
          `}
        />
      ) : (
        <Typography align="center">Site design image not available</Typography>
      )}
    </CollapsableCard>
  );
};

export { SITE_DESIGN_IMAGE_CARD_QUERY };
export default SiteDesignCardImage;
