import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';
import { css } from '@emotion/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CardTravel from '@material-ui/icons/CardTravel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';

const ACTIVITY_PAGE_QUERY = gql`
  query ActivityPageQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      id
      cases {
        edges {
          node {
            id
            caseId
            origin
            createdDate
            number
            details
            status
            subject
            priority
            closedDate
            lastModifiedDate
            ownerName
          }
        }
      }
      dispatches {
        edges {
          node {
            id
            dispatchId
            createdDate
            number
            status
            reason
            priority
            details
            lastModifiedDate
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  oppositeContent: {
    flex: 'unset',
    width: '100px',
  },
  missingOppositeContent: {
    width: '0%',
    flex: 0,
  },
}));

const ActivityPage = () => {
  const { prospectId } = useParams();
  const classes = useStyles();
  const [expandedByActivityId, setExpandedByActivityId] = useState({});
  const [filters, setFilters] = useState(['Cases', 'Dispatches']);

  const filterByType = (event) => {
    let newFilters = event.target.value;
    setFilters(newFilters);
  };

  const { loading, data } = useQuery(ACTIVITY_PAGE_QUERY, {
    variables: { prospectId },
    returnPartialData: true,
  });

  const cases = unwrapGraphQlList(data, 'prospect.cases.edges');
  const dispatches = unwrapGraphQlList(data, 'prospect.dispatches.edges');
  let activities = [];

  if (filters.includes('Cases')) {
    activities = activities.concat(cases);
  }

  if (filters.includes('Dispatches')) {
    activities = activities.concat(dispatches);
  }

  activities = activities.sort((a, b) => {
    if (a.createdDate > b.createdDate) return -1;
    if (a.createdDate < b.createdDate) return 1;
    return 0;
  });
  const areAllExpanded =
    Boolean(activities.length) && activities.every((activity) => expandedByActivityId[activity.id] === true);

  const getStatusColor = (theme, status) => {
    const lowStatus = status?.toLowerCase();
    if (lowStatus) {
      if (lowStatus === 'open') {
        return theme.palette.sunrun['red'];
      }
      if (lowStatus.includes('in progress')) {
        return theme.palette.sunrun['yellow'];
      }
    }
    return theme.palette.grey[500];
  };

  const getActivityColor = (theme, activity) => {
    if (activity.caseId) {
      return theme.palette.sunrun['blue'];
    }

    if (activity.dispatchId) {
      return theme.palette.sunrun['green'];
    }

    return theme.palette.sunrun['yellow'];
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name="checkedB"
            color="primary"
            checked={areAllExpanded}
            onChange={() => {
              const nextExpandedByActivityId = {};
              activities.forEach((activity) => (nextExpandedByActivityId[activity.id] = !areAllExpanded));
              setExpandedByActivityId(nextExpandedByActivityId);
            }}
          />
        }
        label="Expand All"
        data-testid="expandAll"
      />
      <FormControl>
        <Select
          labelId="mutiple-checkbox-select"
          id="mutiple-checkbox"
          multiple
          value={filters}
          onChange={filterByType}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          data-testid="multiselect"
          // workaround/bug in material ui V4 where the popout will move on selecting the first item
          // https://stackoverflow.com/questions/61356503/material-ui-multiselect-popup-anchor-moving-on-select
          MenuProps={{
            getContentAnchorEl: () => null,
          }}
        >
          <MenuItem key="Cases" value="Cases">
            <Checkbox checked={filters.includes('Cases')} data-testid="casesFilter" />
            <ListItemText primary="Cases" />
          </MenuItem>
          <MenuItem key="Dispatches" value="Dispatches">
            <Checkbox checked={filters.includes('Dispatches')} data-testid="dispatchesFilter" />
            <ListItemText primary="Dispatches" />
          </MenuItem>
        </Select>
      </FormControl>
      <Timeline align="left">
        {activities.length ? (
          activities.map((activity) => {
            // eslint-disable-next-line
            const lastModifiedDateText = activity.lastModifiedDate
              ? DateTime.fromISO(activity.lastModifiedDate, { setZone: true }).toLocaleString()
              : '';
            return (
              <TimelineItem
                key={activity.id}
                classes={{
                  oppositeContent: classes.oppositeContent,
                  missingOppositeContent: classes.missingOppositeContent,
                }}
              >
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {activity.createdDate && DateTime.fromISO(activity.createdDate).toLocaleString()}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    css={(theme) => css`
                      color: ${theme.palette.common.white};
                      background-color: ${getActivityColor(theme, activity)};
                    `}
                  >
                    {activity.caseId && <CardTravel />}
                    {activity.dispatchId && <DirectionsRun />}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Accordion
                    expanded={Boolean(expandedByActivityId[activity.id])}
                    onChange={() =>
                      setExpandedByActivityId({
                        ...expandedByActivityId,
                        [activity.id]: Boolean(!expandedByActivityId[activity.id]),
                      })
                    }
                    css={css`
                      width: 100%;
                    `}
                  >
                    <AccordionSummary
                      aria-controls={`panel${activity.id}-content`}
                      id={`panel${activity.id}-header`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-label={expandedByActivityId[activity.id] ? 'collaspe' : 'expand'}
                    >
                      <div
                        css={css`
                          flex-direction: column;
                          text-align: left;
                        `}
                      >
                        {activity.number && (
                          <Typography variant="h6">
                            {activity.caseId ? 'Case' : activity.dispatchId ? 'Dispatch' : 'Activity'}:{' '}
                            {activity.number}
                          </Typography>
                        )}
                        {activity.subject && <Typography>Subject: {activity.subject}</Typography>}
                        {activity.reason && <Typography>Reason: {activity.reason}</Typography>}
                        {activity.priority && <Typography>Priority: {activity.priority}</Typography>}
                        {activity.status && (
                          <Typography component={'span'}>
                            Status:&nbsp;
                            <Chip
                              css={(theme) => css`
                                background-color: ${getStatusColor(theme, activity.status)};
                              `}
                              size="small"
                              label={activity.status}
                              color="primary"
                            />
                          </Typography>
                        )}
                        {!activity.closedDate && lastModifiedDateText && (
                          <Typography> Last Modified: {lastModifiedDateText} </Typography>
                        )}
                        {activity.closedDate && (
                          <Typography>Closed Date: {DateTime.fromISO(activity.closedDate).toLocaleString()}</Typography>
                        )}
                        {activity.origin && <Typography>Created By: {activity.origin}</Typography>}
                        {activity.ownerName && <Typography>Owner: {activity.ownerName}</Typography>}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      css={(theme) => css`
                        border-top: 1px solid ${theme.palette.divider};
                      `}
                    >
                      <Typography
                        css={css`
                          white-space: pre-wrap;
                          text-align: left;
                          width: 100%;
                        `}
                      >
                        {activity.details}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </TimelineContent>
              </TimelineItem>
            );
          })
        ) : loading ? (
          <CircularProgress css={{ margin: 'auto' }} />
        ) : (
          <Typography align="center">No activity found</Typography>
        )}
      </Timeline>
    </>
  );
};

export { ACTIVITY_PAGE_QUERY };
export default ActivityPage;
