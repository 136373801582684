import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';

const CONTRACT_DETAILS_CARD_FRAGMENT = gql`
  fragment ContactInfoCardFields on Prospect {
    id
    agreementType
    billingMethod
    billingType
    serviceContractStatus
    agreementTermInYears
    taxEquityFundName
    financingInstitution
  }
`;

const ContractDetailsCard = ({ loading, data }) => {
  const {
    agreementType,
    billingMethod,
    billingType,
    serviceContractStatus,
    agreementTermInYears,
    taxEquityFundName,
    financingInstitution,
  } = data?.prospect || {};

  return (
    <CollapsableCard title="Contract Details">
      <Grid container spacing={1}>
        <LabeledValue loading={loading} label="Status" text={serviceContractStatus} md={6} lg={4} />
        <LabeledValue loading={loading} label="Agreement Type" text={agreementType} md={6} lg={4} />
        <LabeledValue loading={loading} label="Term (yrs)" text={agreementTermInYears} md={6} lg={4} />
        <LabeledValue loading={loading} label="Billing Method" text={billingMethod} md={6} lg={4} />
        <LabeledValue loading={loading} label="Tax Equity Fund" text={taxEquityFundName} md={6} lg={4} />
        <LabeledValue loading={loading} label="Billing Type" text={billingType} md={6} lg={4} />
        <LabeledValue loading={loading} label="Financing Institution" text={financingInstitution} md={6} lg={4} />
      </Grid>
    </CollapsableCard>
  );
};

ContractDetailsCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      agreementType: PropTypes.string,
      billingMethod: PropTypes.string,
      billingType: PropTypes.string,
      serviceContractStatus: PropTypes.string,
      agreementTermInYears: PropTypes.number,
      taxEquityFundName: PropTypes.string,
      financingInstitution: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
};

export { CONTRACT_DETAILS_CARD_FRAGMENT };
export default ContractDetailsCard;
