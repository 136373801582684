import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

import rollbar from '../utils/rollbar';
import mixpanel from '../utils/mixpanel';

const LoginCallback = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { error, isAuthenticated } = authState || {};
  const authStateReady = !!authState;

  useEffect(() => {
    oktaAuth.handleLoginRedirect();
  }, [oktaAuth]);

  useEffect(() => {
    if (isAuthenticated) mixpanel.track('Logged In');
  }, [isAuthenticated]);

  useEffect(() => {
    if (error) {
      rollbar.error(error);
      mixpanel.track('User encountered a login error');
    }
  }, [error]);

  if (authStateReady && error) {
    return (
      <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                mixpanel.track('User attempted to clear a login error');
                oktaAuth.tokenManager.clear();
                location.assign('/');
              }}
            >
              RESET
            </Button>
          }
        >
          {error.name && error.message ? `${error.name}: ${error.message}` : `Error: ${error.toString()}`}
        </Alert>
      </Snackbar>
    );
  }

  return null;
};

export default LoginCallback;
