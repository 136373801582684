import React from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import {
  SITE_DETAILS_OVERVIEW_PATH,
  SITE_DETAILS_CONTACTS_PATH,
  SITE_DETAILS_PROJECT_STATUS_PATH,
  pathToRoute,
} from '../constants/routes';
import SiteDetailsOverview from './SiteDetailsOverview';
import SiteDetailsContacts from './SiteDetailsContacts';
import ProjectStatusOverview from './ProjectStatusOverview';
import { useStatusTrackerFeatureFlag } from '../hooks/useGetFeatureFlag';

const SiteDetailsPage = () => {
  const isProjectStatusTrackerEnabled = useStatusTrackerFeatureFlag();
  const { prospectId } = useParams();
  return (
    <Switch>
      <Route path={SITE_DETAILS_OVERVIEW_PATH}>
        <SiteDetailsOverview />
      </Route>
      <Route path={SITE_DETAILS_CONTACTS_PATH}>
        <SiteDetailsContacts />
      </Route>
      {isProjectStatusTrackerEnabled && (
        <Route path={SITE_DETAILS_PROJECT_STATUS_PATH}>
          <ProjectStatusOverview prospectId={prospectId} />
        </Route>
      )}
      <Redirect to={pathToRoute(SITE_DETAILS_OVERVIEW_PATH, { prospectId })} />
    </Switch>
  );
};

export default SiteDetailsPage;
