import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';
import DocumentsTable, { DOCUMENT_TABLE_FRAGMENT } from './DocumentsTable';

const SOW_DOCUMENTS_TABLE_QUERY = gql`
  query SOWDocumentsTableQuery($prospectId: String!, $noFilter: Boolean) {
    prospect(prospectId: $prospectId) {
      id
      approvedSOWId
      sowDocuments(noFilter: $noFilter) {
        edges {
          node {
            ...DocumentTableFields
          }
        }
      }
    }
  }
  ${DOCUMENT_TABLE_FRAGMENT}
`;

const SOWDocumentsTable = ({ viewAll }) => {
  const { prospectId } = useParams();
  const { data, loading } = useQuery(SOW_DOCUMENTS_TABLE_QUERY, { variables: { prospectId, noFilter: viewAll } });
  const documents = unwrapGraphQlList(data, 'prospect.sowDocuments.edges');
  const doesNotHaveSOW = data?.prospect?.approvedSOWId === null;
  return (
    <DocumentsTable
      documents={documents}
      loading={loading}
      documentsTab="SOW"
      emptyDataMessage={doesNotHaveSOW ? 'No Scope of Work performed for the Customer' : undefined}
    />
  );
};

SOWDocumentsTable.propTypes = {
  viewAll: PropTypes.bool.isRequired,
};

export { SOW_DOCUMENTS_TABLE_QUERY };
export default SOWDocumentsTable;
