import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';

const FINANCIAL_INFORMATION_CARD_FRAGMENT = gql`
  fragment FinancialInformationCardFields on ServiceContract {
    id
    propertyTaxAbatementPurchased
    creditPass
    pgThreshold
    yearlyPaymentCadence
    rateAfterStepDown
    stepDownMonth
    stepDownProduct
    costStack
    financeType
    maxLoanAmount
    fmvValuationDate
    assetTaxType
    contractSoldType
    localRebate
    localRebateAmount
    transferPrice
    specialTerms
    retailLoanDrawAmount
    requiredLoanAmount
    sunrunLoanDrawAmount
  }
`;

const FinancialInformationCard = ({ loading, data }) => {
  const {
    propertyTaxAbatementPurchased,
    creditPass,
    pgThreshold,
    yearlyPaymentCadence,
    rateAfterStepDown,
    stepDownMonth,
    stepDownProduct,
    costStack,
    financeType,
    maxLoanAmount,
    fmvValuationDate,
    assetTaxType,
    contractSoldType,
    localRebate,
    localRebateAmount,
    transferPrice,
    specialTerms,
    retailLoanDrawAmount,
    requiredLoanAmount,
    sunrunLoanDrawAmount,
  } = data?.prospect?.serviceContract || {};

  return (
    <CollapsableCard title="Financial Information">
      <Grid container direction="row" spacing={1}>
        <LabeledValue loading={loading} label="Property Tax Abatement Purchased" text={propertyTaxAbatementPurchased} />
        <LabeledValue loading={loading} label="Credit Pass" text={creditPass} />
        <LabeledValue loading={loading} label="PG Threshold %" text={pgThreshold} />
        <LabeledValue loading={loading} label="PG Yearly Payment Cadence" text={yearlyPaymentCadence} />
        <LabeledValue loading={loading} label="Rate After Step Down" text={rateAfterStepDown} />
        <LabeledValue loading={loading} label="Step Down Month" text={stepDownMonth} />
        <LabeledValue loading={loading} label="Step Down Product" text={stepDownProduct} />
        <LabeledValue loading={loading} label="Cost Stack" text={costStack} />
        <LabeledValue loading={loading} label="Finance Type" text={financeType} />
        <LabeledValue loading={loading} label="Max Loan Amount" text={maxLoanAmount} />
        <LabeledValue loading={loading} label="FMV Valuation Date" text={fmvValuationDate} isISODate />
        <LabeledValue loading={loading} label="Asset Tax Type" text={assetTaxType} />
        <LabeledValue loading={loading} label="Contract Sold Type" text={contractSoldType} />
        <LabeledValue loading={loading} label="Local Rebate" text={localRebate} />
        <LabeledValue loading={loading} label="Local Rebate Amount" text={localRebateAmount} />
        <LabeledValue loading={loading} label="Transfer Price" text={transferPrice} />
        <LabeledValue loading={loading} label="Special Terms" text={specialTerms} />
        <LabeledValue loading={loading} label="Retail Loan Draw Amount" text={retailLoanDrawAmount} />
        <LabeledValue loading={loading} label="Required Loan Amount" text={requiredLoanAmount} />
        <LabeledValue loading={loading} label="Sunrun Loan Draw Amount" text={sunrunLoanDrawAmount} />
      </Grid>
    </CollapsableCard>
  );
};

FinancialInformationCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      serviceContract: PropTypes.shape({
        propertyTaxAbatementPurchased: PropTypes.bool,
        creditPass: PropTypes.string,
        pgThreshold: PropTypes.string,
        yearlyPaymentCadence: PropTypes.string,
        rateAfterStepDown: PropTypes.string,
        stepDownMonth: PropTypes.string,
        stepDownProduct: PropTypes.string,
        costStack: PropTypes.string,
        financeType: PropTypes.string,
        maxLoanAmount: PropTypes.string,
        fmvValuationDate: PropTypes.string,
        assetTaxType: PropTypes.string,
        contractSoldType: PropTypes.string,
        localRebate: PropTypes.string,
        localRebateAmount: PropTypes.string,
        transferPrice: PropTypes.string,
        specialTerms: PropTypes.string,
        retailLoanDrawAmount: PropTypes.string,
        requiredLoanAmount: PropTypes.string,
        sunrunLoanDrawAmount: PropTypes.string,
      }),
    }),
  }),
  loading: PropTypes.bool,
};

export { FINANCIAL_INFORMATION_CARD_FRAGMENT };
export default FinancialInformationCard;
