export const billingMethodEnum = {
  FULL_UPFRONT: 'Full Upfront',
  PAY_BY_CHECK: 'Pay by Check',
  RECURRING: 'Recurring',
  RECURRING_CC: 'Recurring CC',
};

export const autopayEnrollmentStatusEnum = {
  ENROLLED: 'Enrolled',
  NOT_ENROLLED: 'Not Enrolled',
};

export const autopayButtonTextEnum = {
  ENROLL: 'Enroll in autopay',
  UNENROLL: 'Unenroll from autopay',
};

export const ACHFeeTypeEnum = {
  DOLLAR: 'DOLLAR',
  PERCENT: 'PERCENT',
};

// {{MONTHLY_SAVINGS}} represents percent or dollar values ie. 5% / $5.00
export const savingsMessageEnum = {
  BANK_ENROLLED: 'Savings {{MONTHLY_SAVINGS}}',
  CARD_ENROLLED_OR_UNENROLLED:
    'Enroll this customer in autopay with a bank account to save {{MONTHLY_SAVINGS}} on their monthly bill',
};
