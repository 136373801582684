import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import memoizeOne from 'memoize-one';

import { envApiUriMapping } from '../constants/api-uris';
import { LOCAL } from '../constants/environments';
import getEnv from './get-env';
import rollbar from './rollbar';

const HOSTNAME = window.location.hostname;

const getLink = (uri, oktaAuth) => {
  const httpLink = new HttpLink({ uri });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = oktaAuth.getAccessToken();

    const xApiKey = getEnv(HOSTNAME) === LOCAL ? process.env.DEV_CUSTOMER_BILLING_API_KEY : null;

    operation.setContext(({ headers = {} }) => {
      const allHeaders = {
        ...headers,
        authorization: `Bearer ${token}`,
      };

      if (xApiKey) {
        allHeaders['x-api-key'] = xApiKey;
      }

      return {
        headers: allHeaders,
      };
    });

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      rollbar.error(
        '[GraphQL error]: ' +
          graphQLErrors
            .map((e) => `"${e.message}", Location: ${JSON.stringify(e.locations)}, Path: ${e.path?.join('.')}`)
            .join('\n'),
      );
    }
    if (networkError) rollbar.error(`[Network error]: ${networkError}`);
  });

  return concat(authMiddleware, httpLink, errorLink);
};

const getClient = (uri, oktaAuth) =>
  new ApolloClient({
    link: getLink(uri, oktaAuth),
    cache: new InMemoryCache(),
  });

const customerBillingClient = (oktaAuth) => getClient(envApiUriMapping[getEnv(HOSTNAME)].customerBillingUri, oktaAuth);

export const getCustomerBillingClient = memoizeOne(customerBillingClient);
