import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';

const CREATE_HYBRID_TOKEN_MUTATION = gql`
  mutation createHybridAuthToken($oktaAccessToken: String!) {
    createHybridAuthToken(oktaAccessToken: $oktaAccessToken) {
      hybridAuthToken
    }
  }
`;

const HybridAuthTokenContext = React.createContext(() => {
  throw new Error('Forgot to wrap component in HybridAuthTokenProvider');
});

const HybridAuthTokenProvider = ({ children }) => {
  const { authState } = useOktaAuth();
  const { isAuthenticated, accessToken } = authState || {};

  const [createHybridAuthToken, { data }] = useMutation(CREATE_HYBRID_TOKEN_MUTATION); //eslint-disable-line no-unused-vars

  const [hybridAuthToken, setHybridAuthToken] = useState();
  useEffect(() => {
    let canceled = false;
    const createHybridToken = async () => {
      const { data: htData } = await createHybridAuthToken({
        variables: { oktaAccessToken: accessToken.accessToken },
      });
      const token = htData.createHybridAuthToken?.hybridAuthToken;
      if (!canceled) {
        setHybridAuthToken(token);
      }
    };
    if (isAuthenticated && accessToken?.accessToken) {
      createHybridToken();
    }

    return () => {
      canceled = true;
    };
  }, [createHybridAuthToken, isAuthenticated, accessToken?.accessToken]);

  return <HybridAuthTokenContext.Provider value={hybridAuthToken}>{children}</HybridAuthTokenContext.Provider>;
};

HybridAuthTokenProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

const useHybridAuthToken = () => useContext(HybridAuthTokenContext);

export {
  CREATE_HYBRID_TOKEN_MUTATION,
  HybridAuthTokenProvider,
  HybridAuthTokenContext as HybridAuthTokenContext_FOR_TESTING,
  useHybridAuthToken,
};
