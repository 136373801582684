import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { useParams } from 'react-router-dom';
import { LDNotesContainer } from '@sunrun/ui-home-energy';
import { Grid, Card, CircularProgress, CardContent } from '@material-ui/core';
import mixpanel from '../utils/mixpanel';

import { useHybridAuthToken } from '../components/hybridAuthToken';
import useEnvConfig from '../hooks/useEnvConfig';

const LD_NOTES_PAGE_QUERY = gql`
  query LDNotesPageQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      id
      serviceContractNumber
    }
  }
`;

const LDNotesPage = () => {
  const { homeEnergyReportAPIUrl } = useEnvConfig();
  const { prospectId } = useParams();
  const hybridAuth = useHybridAuthToken();

  const { loading, data } = useQuery(LD_NOTES_PAGE_QUERY, {
    variables: { prospectId },
  });

  if (loading || !hybridAuth || !data) {
    return (
      <Grid container direction="row" justify="center" spacing={1}>
        <CircularProgress />
      </Grid>
    );
  }

  const { prospect } = data;

  const mixpanelTrack = (eventName, obj) => {
    mixpanel.track(eventName, obj);
  };

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <LDNotesContainer
              authToken={hybridAuth}
              apiUrl={homeEnergyReportAPIUrl}
              serviceContractNumber={prospect.serviceContractNumber}
              mixpanelTrack={mixpanelTrack}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LDNotesPage;
