import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';
import mixpanel from '../utils/mixpanel';

const METER_INFORMATION_CARD_FRAGMENT = gql`
  fragment MeterInformationCardFields on Prospect {
    meters {
      edges {
        node {
          id
          inServiceDate
          manufacturer
          serialNumber
          status
          statusDetails {
            readingEndTimeLocal
          }
          vendorName
        }
      }
    }
  }
`;

const SingleMeter = ({ loading, meter }) => (
  <Grid container direction="row" spacing={1} item xs={12}>
    <Grid item xs={12} container spacing={2}>
      <Grid item>
        <Chip
          label={meter.status ?? (loading && 'loading')}
          color={meter.status === 'Active' ? 'primary' : 'default'}
        />
      </Grid>
    </Grid>
    <LabeledValue loading={loading} label="Manufacturer" text={meter.manufacturer || meter.vendorName} />
    <LabeledValue loading={loading} label="Serial Number" text={meter.serialNumber} />
    <LabeledValue loading={loading} label="Install Date" text={meter.inServiceDate} isISODate />
    <LabeledValue loading={loading} label="Last Read Date" text={meter?.statusDetails?.readingEndTimeLocal} isISODate />
  </Grid>
);

SingleMeter.propTypes = {
  loading: PropTypes.bool.isRequired,
  meter: PropTypes.shape({
    inServiceDate: PropTypes.string,
    manufacturer: PropTypes.string,
    serialNumber: PropTypes.string,
    status: PropTypes.string,
    statusDetails: PropTypes.shape({
      readingEndTimeLocal: PropTypes.string,
    }),
    vendorName: PropTypes.string,
  }).isRequired,
};

const MeterInformationCard = ({ loading, data }) => {
  const [filter, setFilter] = useState('Active');
  const meters = unwrapGraphQlList(data, 'prospect.meters.edges');
  meters.sort((a, b) => (a.inServiceDate < b.inServiceDate ? 1 : -1));
  const allowFilter =
    !loading &&
    meters?.length > 0 &&
    meters.some((meter) => meter.status === 'Active') &&
    meters.some((meter) => meter.status === 'Inactive');
  const filteredMeters = allowFilter && filter ? meters.filter((meter) => meter.status === filter) : meters;
  return (
    <CollapsableCard
      mixpanelEventName="Toggled Meter Information card expand/collapse"
      title={
        <Grid container spacing={4} alignItems="center">
          <Grid item>
            <Typography variant="h5">Meter Information</Typography>
          </Grid>
          {allowFilter && (
            <Grid item>
              <ButtonGroup>
                <Button
                  color={filter === 'Active' ? 'primary' : 'default'}
                  onClick={() => {
                    mixpanel.track('Meter Information Filtered', { filter: 'Active' });
                    setFilter('Active');
                  }}
                >
                  ACTIVE
                </Button>
                <Button
                  color={filter === 'Inactive' ? 'primary' : 'default'}
                  onClick={() => {
                    mixpanel.track('Meter Information Filtered', { filter: 'Inactive' });
                    setFilter('Inactive');
                  }}
                >
                  INACTIVE
                </Button>
                <Button
                  color={filter === null ? 'primary' : 'default'}
                  onClick={() => {
                    mixpanel.track('Meter Information Filtered', { filter: null });
                    setFilter(null);
                  }}
                >
                  ALL
                </Button>
              </ButtonGroup>
            </Grid>
          )}
        </Grid>
      }
    >
      <Grid container direction="column" spacing={2}>
        {filteredMeters.length ? (
          filteredMeters.map((meter) => (
            <SingleMeter key={`meter-information-${meter.serialNumber}`} loading={loading} meter={meter} />
          ))
        ) : loading ? (
          <SingleMeter
            key={`meter-information-loading`}
            loading={true}
            meter={{}} // show a loading state
          />
        ) : (
          <Typography align="center">No meter information found</Typography>
        )}
      </Grid>
    </CollapsableCard>
  );
};

MeterInformationCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      meters: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: SingleMeter.propTypes.meter,
          }),
        ),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
};

export { METER_INFORMATION_CARD_FRAGMENT };
export default MeterInformationCard;
