import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import mixpanel from '../utils/mixpanel';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import HelpIcon from '@material-ui/icons/Help';
import { INVOICE_SERVICE_CREATION_DATE } from '../constants/invoices';

const GET_INVOICE_QUERY = gql`
  query getInvoice($invoiceKey: String, $contactId: String) {
    invoice(invoiceKey: $invoiceKey, contactId: $contactId) {
      id
      signedUrl
      pdf
    }
  }
`;
const setPath = (isUrl, pdf) => {
  if (isUrl) {
    return pdf;
  }
  return `data:application/pdf;base64,${pdf}`;
};

const openPDF = (invoiceKey, small, pdfData, isSignedUrl) => {
  const invoiceWindow = window.open('', invoiceKey);
  if (invoiceWindow) {
    invoiceWindow.document.write(`<iframe width="100%" height="100%" src="${setPath(isSignedUrl, pdfData)}"></iframe>`);
    invoiceWindow.document.title = invoiceKey;
    mixpanel.track('View Invoice', {
      'Invoice Key': invoiceKey,
      Type: small ? 'Historical' : 'Current',
    });
  }
};

const ViewBillButton = ({ small, invoiceDate, invoiceKey, contactId }) => {
  const [loadInvoice, { called, loading, data, error }] = useLazyQuery(GET_INVOICE_QUERY, {
    variables: { invoiceKey, contactId },
  });

  // check to see if we already have the URL in the cache
  let pdfFromCache;
  let isSignedUrlCache = false;
  const client = useApolloClient();
  try {
    const cachedData = client.readQuery({ query: GET_INVOICE_QUERY, variables: { invoiceKey, contactId } });
    pdfFromCache = cachedData?.invoice?.signedUrl || cachedData?.invoice?.pdf;
    isSignedUrlCache = cachedData?.invoice?.signedUrl;
  } catch (err) {
    // no data in the cache
  }

  const pdfData = data?.invoice?.signedUrl || data?.invoice?.pdf || pdfFromCache;
  const isSignedUrl = data?.invoice?.signedUrl || isSignedUrlCache;
  useEffect(() => {
    if (called && pdfData) {
      openPDF(invoiceKey, small, pdfData, isSignedUrl);
    }
  }, [called, invoiceKey, small, pdfData, isSignedUrl]);

  const invoiceMigrationDate = DateTime.fromISO(INVOICE_SERVICE_CREATION_DATE);
  const isInvoiceDatePostMigration = DateTime.fromISO(invoiceDate, { setZone: true }) > invoiceMigrationDate;

  useEffect(() => {
    if (error) {
      mixpanel.track('View Invoice', {
        'Invoice Key': invoiceKey,
        Type: !isInvoiceDatePostMigration ? 'Invoice created before migration' : 'Error',
      });
    }
  }, [error, isInvoiceDatePostMigration, invoiceKey]);

  const handleClick = () => {
    if (pdfData) openPDF(invoiceKey, small, pdfData, isSignedUrl);
    else loadInvoice();
  };

  if (!invoiceKey) {
    return (
      <Tooltip title="Your invoice will be available 10 days prior to your due date.">
        <HelpIcon color="disabled" fontSize="small" />
      </Tooltip>
    );
  }

  if (small) {
    if (loading) {
      return (
        <Tooltip title="Loading invoice">
          <CircularProgress size={20} />
        </Tooltip>
      );
    }

    if (error) {
      return (
        <Tooltip title="Error loading invoice">
          <ErrorIcon color="error" fontSize="small" />
        </Tooltip>
      );
    }

    if (!isInvoiceDatePostMigration) {
      return (
        <Tooltip title="Invoice is no longer accessible">
          <HelpIcon color="disabled" fontSize="small" />
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Open invoice in new tab">
        <IconButton size="small" aria-label="Open invoice in new tab" onClick={handleClick}>
          <OpenInNewIcon color={pdfData ? 'primary' : 'secondary'} fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  } else {
    if (error) {
      return <Typography color="error">Unable to load invoice</Typography>;
    }

    if (!isInvoiceDatePostMigration) {
      return <Typography>Invoice is no longer accessible</Typography>;
    }

    return (
      <Button
        onClick={handleClick}
        endIcon={loading ? <CircularProgress size={20} /> : <OpenInNewIcon />}
        variant="text"
        color="primary"
        disabled={loading}
      >
        View Bill
      </Button>
    );
  }
};

ViewBillButton.propTypes = {
  small: PropTypes.bool.isRequired,
  invoiceDate: PropTypes.string.isRequired,
  invoiceKey: PropTypes.string,
  contactId: PropTypes.string.isRequired,
};

ViewBillButton.defaultProps = {
  invoiceKey: null,
};

export { GET_INVOICE_QUERY };
export default ViewBillButton;
