import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import Skeleton from '@material-ui/lab/Skeleton';

import useEnvConfig from 'hooks/useEnvConfig';

const MapPlaceholder = ({ children }) => (
  <Skeleton
    css={css`
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 640px;
    `}
    variant="rect"
    height={450}
  >
    {children}
  </Skeleton>
);

MapPlaceholder.propTypes = {
  children: PropTypes.any.isRequired,
};

const SiteImagesCard = ({ loading, data }) => {
  const { latitude, longitude } = data?.prospect || {};
  const { enableMaps, mapsAPIKey } = useEnvConfig();
  if (!loading && (!latitude || !longitude)) {
    return null;
  }
  return (
    <CollapsableCard title="Site Images" initiallyExpanded={false}>
      {loading ? (
        <Grid container direction="row" spacing={1}>
          <Grid container direction="column" item xs={12} lg={6}>
            <Skeleton
              css={css`
                max-width: 640px;
              `}
              variant="rect"
              height={450}
            />
          </Grid>
          <Grid container direction="column" item xs={12} lg={6}>
            <Skeleton
              css={css`
                max-width: 640px;
              `}
              variant="rect"
              height={450}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" spacing={1}>
          <Grid container direction="column" item xs={12} lg={6}>
            {enableMaps ? (
              <iframe
                title="Map View"
                css={css`
                  height: 450px;
                  max-width: 640px;
                `}
                frameBorder="0"
                // eslint-disable-next-line max-len
                src={`https://www.google.com/maps/embed/v1/view?key=${mapsAPIKey}&center=${latitude},${longitude}&zoom=20&maptype=satellite`}
                allowFullScreen
              ></iframe>
            ) : (
              <MapPlaceholder>Map View</MapPlaceholder>
            )}
          </Grid>
          <Grid container direction="column" item xs={12} lg={6}>
            {enableMaps ? (
              <img
                alt="Street View"
                css={css`
                  height: 450px;
                  max-width: 640px;
                `}
                // eslint-disable-next-line max-len
                src={`https://maps.googleapis.com/maps/api/streetview?key=${mapsAPIKey}&location=${latitude},${longitude}&size=640x640`}
              />
            ) : (
              <MapPlaceholder>Street View</MapPlaceholder>
            )}
          </Grid>
        </Grid>
      )}
    </CollapsableCard>
  );
};

SiteImagesCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      longitude: PropTypes.string,
      latitude: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool,
};

export default SiteImagesCard;
