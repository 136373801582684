import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { gql, useLazyQuery } from '@apollo/client';

import { useParams } from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDiscontinueAutopay } from '@sunrun/ui-cybersource-payments';

import mixpanel from '../utils/mixpanel';
import rollbar from '../utils/rollbar';
import useEnvConfig from '../hooks/useEnvConfig';
import { useHybridAuthToken } from '../components/hybridAuthToken';

const ACH_QUERY = gql`
  query ACHQuery($prospectId: String!) {
    prospect(prospectId: $prospectId) {
      id
      ACHEnabled
      ACHFee
    }
  }
`;
const DiscontinueAutopayModal = ({ isOpen, onClose }) => {
  const { paymentEnv } = useEnvConfig();
  const hybridAuth = useHybridAuthToken();
  const { prospectId } = useParams();
  const { execute, status, loading, error, result } = useDiscontinueAutopay(paymentEnv, hybridAuth, prospectId);
  const [updateACH, { loading: ACHLoading, error: ACHError }] = useLazyQuery(ACH_QUERY, {
    variables: { prospectId },
    fetchPolicy: 'network-only',
  });

  const closeModal = () => {
    !loading && onClose();
  };

  const discontinueAutopayAndUpdateACH = async () => {
    await execute();
    updateACH();
  };

  useEffect(() => {
    if (status === 'success') {
      mixpanel.track('Autopay Discontinued', { prospectId });
    }
    if (error) {
      rollbar.error(`Discontinue Autopay failure prospectId: ${prospectId} error: ${error}`);
      mixpanel.track('User encountered discontinue autopay error', { prospectId });
    }
  }, [status, prospectId, error]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">Discontinue Autopay</Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            position: absolute;
            right: 8px;
            top: 8px;
            color: #9e9e9e;
          `}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        css={css`
          min-width: 336px;
          padding: 16px;
        `}
        dividers
      >
        {(error || ACHError) && <DialogContentText>Something went wrong. Try again later.</DialogContentText>}
        {(loading || ACHLoading) && (
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <CircularProgress />
          </div>
        )}
        {result && status === 'success' && (
          <>
            <DialogContentText>Autopay discontinued.</DialogContentText>
          </>
        )}
        {status === 'not-requested' && (
          <DialogContentText>Are you sure you want to discontinue autopay?</DialogContentText>
        )}
      </DialogContent>
      <DialogActions
        css={css`
          margin: 0;
          padding: 8px;
        `}
      >
        <Button
          autoFocus
          onClick={discontinueAutopayAndUpdateACH}
          color="primary"
          disabled={loading || !!result || !!error}
        >
          Discontinue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DiscontinueAutopayModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DiscontinueAutopayModal;
