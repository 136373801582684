import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import LeadInfoCard, { LEAD_INFO_CARD_FRAGMENT } from './LeadInfoCard';
import ContractDetailsCard, { CONTRACT_DETAILS_CARD_FRAGMENT } from './ContractDetailsCard';
import SiteDetailsGeneralCard, { SITE_DETAILS_GENERAL_CARD_FRAGMENT } from './SiteDetailsGeneralCard';
import SiteDetailsCard, { SITE_DETAILS_CARD_FRAGMENT } from './SiteDetailsCard';
import ProjectCard, { PROJECT_CARD_FRAGMENT } from './ProjectCard';
import SiteImagesCard from './SiteImagesCard';

const SITE_DETAILS_OVERVIEW_PAGE_QUERY = gql`
  query SiteDetailsPageQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      ...LeadInfoCardFields
      ...ContactInfoCardFields
      ...SiteDetailsGeneralCardFields
      ...SiteDetailsCardFields
      ...ProjectCardFields
    }
  }
  ${LEAD_INFO_CARD_FRAGMENT}
  ${CONTRACT_DETAILS_CARD_FRAGMENT}
  ${SITE_DETAILS_GENERAL_CARD_FRAGMENT}
  ${SITE_DETAILS_CARD_FRAGMENT}
  ${PROJECT_CARD_FRAGMENT}
`;

const SiteDetailsOverview = () => {
  const { prospectId } = useParams();
  const { loading, data } = useQuery(SITE_DETAILS_OVERVIEW_PAGE_QUERY, {
    variables: { prospectId },
    returnPartialData: true,
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <LeadInfoCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ContractDetailsCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <SiteDetailsGeneralCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <SiteDetailsCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <SiteImagesCard loading={loading} data={data} />
      </Grid>
      <Grid item xs={12}>
        <ProjectCard loading={loading} data={data} />
      </Grid>
    </Grid>
  );
};

export { SITE_DETAILS_OVERVIEW_PAGE_QUERY };
export default SiteDetailsOverview;
