import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';

const PRICING_INFORMATION_CARD_FRAGMENT = gql`
  fragment PricingInformationCardFields on ServiceContract {
    id
    pricing {
      id
      c1Terms
      c2Terms
      c3Terms
      epcPrice
      pricePerKwh
      epcPricePerWatt
      approxPriceWattAc
      approxPriceWattDc
      fullSystemCost
      monthlyUpfrontPaymentAmount
      totalSolarPrepayRequired
      transferWatt
      buyoutPerWatt
      cashGrantEligible
      cashGrantPanelPricePerWatt
      cashGrantPreBuyEligible
      originalPricePerKWh
      originalRebateEstimate
      prepayPaymentForElectricity
      prepayPaymentForRecs
    }
  }
`;

const PricingInformationCard = ({ loading, data }) => {
  const {
    c1Terms,
    c2Terms,
    c3Terms,
    epcPrice,
    pricePerKwh,
    epcPricePerWatt,
    approxPriceWattAc,
    approxPriceWattDc,
    fullSystemCost,
    monthlyUpfrontPaymentAmount,
    totalSolarPrepayRequired,
    transferWatt,
    buyoutPerWatt,
    cashGrantEligible,
    cashGrantPanelPricePerWatt,
    cashGrantPreBuyEligible,
    originalPricePerKWh,
    originalRebateEstimate,
    prepayPaymentForElectricity,
    prepayPaymentForRecs,
  } = data?.prospect?.serviceContract?.pricing || {};

  return (
    <CollapsableCard title="Pricing Information">
      <Grid container direction="row" spacing={1}>
        <LabeledValue loading={loading} label="C1 Terms" text={c1Terms} />
        <LabeledValue loading={loading} label="C2 Terms" text={c2Terms} />
        <LabeledValue loading={loading} label="C3 Terms" text={c3Terms} />
        <LabeledValue loading={loading} label="EPC Price" text={epcPrice} />
        <LabeledValue loading={loading} label="Price per kWh" text={pricePerKwh} />
        <LabeledValue loading={loading} label="EPC price per watt" text={epcPricePerWatt} />
        <LabeledValue loading={loading} label="Approx Price/Watt AC" text={approxPriceWattAc} />
        <LabeledValue loading={loading} label="Approx Price/Watt DC" text={approxPriceWattDc} />
        <LabeledValue loading={loading} label="Full System cost" text={fullSystemCost} />
        <LabeledValue loading={loading} label="Monthly Upfront Payment Amount" text={monthlyUpfrontPaymentAmount} />
        <LabeledValue loading={loading} label="Total Solar Prepay Required" text={totalSolarPrepayRequired} />
        <LabeledValue loading={loading} label="Transfer $/Watt" text={transferWatt} />
        <LabeledValue loading={loading} label="Buyout per Watt" text={buyoutPerWatt} />
        <LabeledValue loading={loading} label="Cash Grant Eligible" text={cashGrantEligible} />
        <LabeledValue loading={loading} label="Cash Grant Panel Price per Watt" text={cashGrantPanelPricePerWatt} />
        <LabeledValue loading={loading} label="Cash Grant Pre-Buy Eligible" text={cashGrantPreBuyEligible} />
        <LabeledValue loading={loading} label="Original Price per kWh" text={originalPricePerKWh} />
        <LabeledValue loading={loading} label="Original Rebate Estimate" text={originalRebateEstimate} />
        <LabeledValue loading={loading} label="Prepay Discount Rate" text={prepayPaymentForElectricity} />
        <LabeledValue loading={loading} label="Prepay payment for RECs" text={prepayPaymentForRecs} />
      </Grid>
    </CollapsableCard>
  );
};

PricingInformationCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      serviceContract: PropTypes.shape({
        pricing: PropTypes.shape({
          c1Terms: PropTypes.number,
          c2Terms: PropTypes.number,
          c3Terms: PropTypes.number,
          epcPrice: PropTypes.number,
          pricePerKwh: PropTypes.number,
          epcPricePerWatt: PropTypes.number,
          approxPriceWattAc: PropTypes.number,
          approxPriceWattDc: PropTypes.number,
          fullSystemCost: PropTypes.number,
          monthlyUpfrontPaymentAmount: PropTypes.number,
          totalSolarPrepayRequired: PropTypes.bool,
          transferWatt: PropTypes.number,
          buyoutPerWatt: PropTypes.number,
          cashGrantEligible: PropTypes.bool,
          cashGrantPanelPricePerWatt: PropTypes.number,
          cashGrantPreBuyEligible: PropTypes.bool,
          originalPricePerKWh: PropTypes.number,
          originalRebateEstimate: PropTypes.number,
          prepayPaymentForElectricity: PropTypes.number,
          prepayPaymentForRecs: PropTypes.number,
        }),
      }),
    }),
  }),
  loading: PropTypes.bool,
};

export { PRICING_INFORMATION_CARD_FRAGMENT };
export default PricingInformationCard;
