import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { css } from '@emotion/core';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Skeleton from 'react-loading-skeleton';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import { PAYMENT_AUTOPAY_PATH, pathToRoute } from '../constants/routes';
import formatCurrency from '../utils/format-currency';
import { useUserAuthInfo } from '../components/auth';
import DiscontinueAutopayModal from './DiscontinueAutopayModal';
import { BLUE } from '../constants/billing-status';
import {
  billingMethodEnum,
  autopayEnrollmentStatusEnum,
  autopayButtonTextEnum,
  ACHFeeTypeEnum,
  savingsMessageEnum,
} from '../constants/autopay';

const AUTOPAY_FRAGMENT = gql`
  fragment AutopayFields on Prospect {
    id
    ACHEnabled
    ACHFee
    ACHFeeType
    autopayByCreditCardEligible
    currentOwner {
      contactId
    }
    billingMethod
    billingSystem
    PTO
  }
`;

const Autopay = ({ data, loading, isVivintSolarPostPTO }) => {
  const { isUser, isBillingEscalations } = useUserAuthInfo();
  const history = useHistory();
  const { prospectId, contactId } = useParams();
  const { ACHEnabled, ACHFee, ACHFeeType, currentOwner, billingMethod, billingSystem, PTO } = data?.prospect || {};
  const enableAutopayUnenroll = (isBillingEscalations || !!PTO) && ACHEnabled;
  const [isDiscontinueAutopayOpen, setIsDiscontinueAutopayOpen] = useState(false);
  const closeDiscontinueAutopay = () => setIsDiscontinueAutopayOpen(false);

  const isCurrentOwner = !contactId || contactId === currentOwner?.contactId;
  if (!isCurrentOwner) return null; // auto pay is only relevant for the current owner

  const isBlueBillingSystem = billingSystem === BLUE;

  const formatMonthySavingsByACHFeeType = (savingsMessage) => {
    if (ACHFee && ACHFeeType === ACHFeeTypeEnum.DOLLAR) {
      return savingsMessage.replace('{{MONTHLY_SAVINGS}}', formatCurrency(ACHFee));
    }

    if (ACHFee && ACHFeeType === ACHFeeTypeEnum.PERCENT) {
      return savingsMessage.replace('{{MONTHLY_SAVINGS}}', `${ACHFee}%`);
    }

    // default to $0.00
    return savingsMessage.replace('{{MONTHLY_SAVINGS}}', formatCurrency(0));
  };

  const getSavingsMessage = () => {
    // currently enrolled by bank account
    if (ACHEnabled && typeof billingMethod === 'string' && billingMethod === billingMethodEnum.RECURRING) {
      return formatMonthySavingsByACHFeeType(savingsMessageEnum.BANK_ENROLLED);
    }

    // currently enrolled with card or unenrolled
    return formatMonthySavingsByACHFeeType(savingsMessageEnum.CARD_ENROLLED_OR_UNENROLLED);
  };

  const navigateToEnrollInAutopay = () => {
    history.push(pathToRoute(PAYMENT_AUTOPAY_PATH, { prospectId, contactId }));
  };

  return (
    <Grid item xs>
      <Card
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <CardHeader
          title={
            <span>
              Autopay{' '}
              {ACHEnabled === undefined ? (
                loading && <Skeleton width={90} height={32} />
              ) : (
                <Chip
                  label={ACHEnabled ? autopayEnrollmentStatusEnum.ENROLLED : autopayEnrollmentStatusEnum.NOT_ENROLLED}
                />
              )}
            </span>
          }
        />
        <CardContent
          css={css`
            flex-grow: 1;
          `}
        >
          <Typography>{ACHFee === undefined ? loading && <Skeleton width={60} /> : getSavingsMessage()}</Typography>
        </CardContent>

        {isUser && (
          <CardActions>
            {!ACHEnabled && (
              <Button
                variant="text"
                onClick={navigateToEnrollInAutopay}
                color="primary"
                disabled={isVivintSolarPostPTO && !isBlueBillingSystem}
                data-testid="enroll-autopay-btn"
              >
                {autopayButtonTextEnum.ENROLL}
              </Button>
            )}
            {enableAutopayUnenroll && (
              <Button
                variant="text"
                onClick={() => setIsDiscontinueAutopayOpen(true)}
                color="primary"
                disabled={isVivintSolarPostPTO && !isBlueBillingSystem}
                data-testid="discontinue-autopay-btn"
              >
                {autopayButtonTextEnum.UNENROLL}
              </Button>
            )}
          </CardActions>
        )}
        <DiscontinueAutopayModal isOpen={isDiscontinueAutopayOpen} onClose={closeDiscontinueAutopay} />
      </Card>
    </Grid>
  );
};

Autopay.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      ACHEnabled: PropTypes.bool,
      ACHFee: PropTypes.number,
      currentOwner: PropTypes.shape({
        contactId: PropTypes.string,
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
  isVivintSolarPostPTO: PropTypes.bool,
};

Autopay.defaultProps = {
  isVivintSolarPostPTO: false,
};

export { AUTOPAY_FRAGMENT };
export default Autopay;
