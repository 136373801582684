import React from 'react';
import { useParams } from 'react-router-dom';
import { HomeEnergyReportProvider, HomeEnergyReportsList } from '@sunrun/ui-home-energy';

import { useHybridAuthToken } from '../components/hybridAuthToken';
import useEnvConfig from '../hooks/useEnvConfig';

const HomeEnergyReportListPage = () => {
  const { prospectId, contactId } = useParams();
  const { homeEnergyReportAPIUrl } = useEnvConfig();
  const hybridAuth = useHybridAuthToken();
  return (
    <HomeEnergyReportProvider>
      <HomeEnergyReportsList
        prospectId={prospectId}
        contactId={contactId}
        apiUrl={homeEnergyReportAPIUrl}
        authToken={hybridAuth}
      />
    </HomeEnergyReportProvider>
  );
};

export default HomeEnergyReportListPage;
