import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import { css } from '@emotion/core';
import { DateTime } from 'luxon';
import mixpanel from '../utils/mixpanel';

const roundTo3DigitsWhenNeeded = (number) => parseFloat(Math.round(number * 1e3) / 1e3);

const LabeledValue = ({ text, label, loading, tooltip, isISODate, link, ...rest }) => {
  let value = text;
  if (text && isISODate) {
    const dt = DateTime.fromISO(text, { setZone: true });
    value = dt.isValid ? dt.toLocaleString() : text; // fail back to the original value
  } else if (value === false) {
    value = 'No';
  } else if (value === true) {
    value = 'Yes';
  } else if (typeof value === 'number') {
    value = roundTo3DigitsWhenNeeded(value);
  }
  return (
    <Grid
      container
      direction="column"
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      data-testid={`LabeledValue [${label}] [${value}]`}
      {...rest}
    >
      <Grid item>
        <Typography
          data-testid={`LabeledValue label for ${label}`}
          variant="subtitle2"
          css={css`
            text-transform: uppercase;
          `}
        >
          {tooltip ? (
            <Grid container spacing={1}>
              <Grid item>{label}</Grid>
              <Grid item>
                <Tooltip title={tooltip} placement="top">
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            label
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography data-testid={`LabeledValue value for ${label}`} variant="body2">
          {link && (
            <Link
              href={link}
              target="_blank"
              rel="noreferrer noopener"
              data-testid="labeled-val-link"
              onClick={() => {
                mixpanel.track(`${label} link clicked`, {
                  Link: link,
                  Label: label,
                  Value: value,
                });
              }}
            >
              {value ?? (loading ? <Skeleton /> : '--')}
            </Link>
          )}
          {!link && (value ?? (loading ? <Skeleton /> : '--'))}
        </Typography>
      </Grid>
    </Grid>
  );
};

LabeledValue.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.any,
  tooltip: PropTypes.string,
  isISODate: PropTypes.bool,
};

LabeledValue.defaultProps = {
  link: null,
};

export { roundTo3DigitsWhenNeeded };
export default LabeledValue;
