import React, { useEffect } from 'react';
import { useLocation, Redirect, useParams, Route } from 'react-router-dom';
import rollbar from '../utils/rollbar';
import mixpanel from '../utils/mixpanel';
import { DASH_BOARD_PATH } from '../constants/routes';
import SitePage from '../site';
import { useUserAuthInfo } from './auth';
import useGetSiteDetails from '../hooks/useGetSiteDetails';
import { InvalidRoute } from '../components/InvalidRoute';
import CircularProgress from '@material-ui/core/CircularProgress';

const SiteAccessWrapper = ({ ...rest }) => {
  const { prospectId } = useParams();
  const { loading, data, error } = useGetSiteDetails(prospectId);
  const { isBillingEscalations } = useUserAuthInfo();
  const location = useLocation();

  const status = data?.prospect?.serviceContractStatus;
  // Only Admins and employees in the Billing Escalations group should seeing Bankruptcy accounts
  const isBankruptcyAndRestricted = status === 'Bankruptcy' && !isBillingEscalations;

  useEffect(() => {
    if (isBankruptcyAndRestricted && !loading) {
      rollbar.warn(`A user with incorrect access tried to access an restricted status: ${location.pathname}`);
      mixpanel.track('Restricted Status Attempted', { path: location.pathname });
    }
  }, [isBankruptcyAndRestricted, location, loading]);

  if (loading)
    return (
      <div
        css={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        data-testId="loading"
      >
        <CircularProgress />
      </div>
    );
  if ((!loading && !data?.prospect) || error) return <InvalidRoute />;

  return (
    <Route
      {...rest}
      render={() =>
        isBankruptcyAndRestricted ? (
          <Redirect to={{ pathname: DASH_BOARD_PATH, state: { restrictedStatus: true } }} />
        ) : (
          <SitePage loading={loading} data={data} />
        )
      }
    />
  );
};

export default SiteAccessWrapper;
