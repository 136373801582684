import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useParams, useHistory } from 'react-router';
import { AutopayEnrollForm } from '@sunrun/ui-cybersource-payments';
import { AUTOPAY_CONFIRMATION_PATH, CYBERSOURCE_IFRAME_EVENTS_PATH, pathToRoute } from '../constants/routes';
import useEnvConfig from '../hooks/useEnvConfig';
import QueryString from 'query-string';
import { useHybridAuthToken } from '../components/hybridAuthToken';
import mixpanel from '../utils/mixpanel';

const EnrollInAutopay = ({ userInfo }) => {
  const { paymentEnv } = useEnvConfig();
  const hybridAuth = useHybridAuthToken();
  const { prospectId, contactId } = useParams();
  const history = useHistory();
  const orangeWithBluePayment = userInfo.billingSystem === 'Blue' && userInfo.isVivintSolarPostPTO;
  const isOrange = userInfo.fundBridge || orangeWithBluePayment;
  // TODO: We can remove the orange check once this story is complete: https://sunrun.jira.com/browse/SRCRM-21412
  const allowCreditCardAutoPay = isOrange || userInfo.autopayByCreditCardEligible;
  const hideCCMethod = !allowCreditCardAutoPay;
  useEffect(() => {
    const redirectToConfirmation = (detail) => {
      window.location = `${pathToRoute(AUTOPAY_CONFIRMATION_PATH, { prospectId, contactId })}?${QueryString.stringify(
        detail,
      )}`;
    };

    const achSuccess = (e) => {
      // successfully made payment AND enrolled in ACH
      // need to add extra flag to details for confirmation screen to show both messages
      redirectToConfirmation({
        achAndPayment: true,
        ...e.detail,
      });
    };

    const achFailure = (e) => {
      // There was a problem enrolling in autopay AFTER making a successful payment
      redirectToConfirmation({
        achFailure: true,
        ...e.detail,
      });
    };

    window.addEventListener('PAYMENT_SUCCESS_ACH', achSuccess, false);
    window.addEventListener('PAYMENT_FAILURE_ACH', achFailure, false);

    return () => {
      window.removeEventListener('PAYMENT_SUCCESS_ACH', achSuccess);
      window.removeEventListener('PAYMENT_FAILURE_ACH', achFailure);
    };
  }, [history, prospectId, contactId]);

  if (!hybridAuth) return <LinearProgress />;
  return (
    <AutopayEnrollForm
      analytics={mixpanel}
      userInfo={userInfo}
      envName={paymentEnv}
      redirectUrl={CYBERSOURCE_IFRAME_EVENTS_PATH}
      hybridAuthToken={hybridAuth}
      showConsentScript
      hideCCMethod={hideCCMethod}
      inputValuesHidden
    />
  );
};

EnrollInAutopay.propTypes = {
  userInfo: PropTypes.shape({
    prospectId: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    mobilePhoneNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    lastDueDate: PropTypes.string,
    totalAmountDue: PropTypes.string,
    fundBridge: PropTypes.bool,
    billingSystem: PropTypes.string,
    isVivintSolarPostPTO: PropTypes.bool,
    autopayByCreditCardEligible: PropTypes.bool,
    ptoDate: PropTypes.string,
  }),
};

export default EnrollInAutopay;
