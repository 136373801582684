import { useFlags } from 'flagsmith/react';

export const useStatusTrackerFeatureFlag = () => {
  const flags = useFlags(['spotlight_status_tracker']);
  return flags.spotlight_status_tracker.enabled;
};

export const useNewPaymentsUIFlag = () => {
  const flags = useFlags(['spotlight_new_payments_ui']);
  return flags.spotlight_new_payments_ui.enabled;
};
