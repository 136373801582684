import { useEffect } from 'react';
import rollbar from '../utils/rollbar';
import useSearchParams from '../hooks/useSearchParams';

const CybersourceIframeEventEmitter = () => {
  const urlParams = useSearchParams();

  useEffect(() => {
    // ensure we are in an iframe
    if (window.frameElement) {
      const { decision, req_transaction_type: type } = urlParams;
      const rejected = decision === 'ERROR' || decision === 'DECLINE';
      const accepted = decision === 'ACCEPT';
      // One Time Payment Failure
      if (type === 'sale' && rejected) {
        // If they used the one time payment form AND it was a failure, direct them there
        window.parent.dispatchEvent(new CustomEvent('PAYMENT_FAILURE_ONE_TIME', { detail: urlParams }));
      }

      // One Time Payment Success
      if (type === 'sale' && accepted) {
        window.parent.dispatchEvent(new CustomEvent('PAYMENT_SUCCESS_ONE_TIME', { detail: urlParams }));
      }

      // ACH Enrollment Success
      if (type === 'create_payment_token' && accepted) {
        window.parent.dispatchEvent(new CustomEvent('PAYMENT_SUCCESS_ACH', { detail: urlParams }));
      }

      // ACH Enrollment Failure
      if (type === 'create_payment_token' && rejected) {
        window.parent.dispatchEvent(new CustomEvent('PAYMENT_FAILURE_ACH', { detail: urlParams }));
      }
    } else {
      rollbar.warn('Cybersource event emitter was invoked from a non-iframe window');
    }
  }, [urlParams]);

  return null;
};

export default CybersourceIframeEventEmitter;
