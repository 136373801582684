import React from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { SYSTEM_OVERVIEW_PATH, SYSTEM_PERFORMANCE_PATH, pathToRoute } from '../constants/routes';
import SystemOverview from './SystemOverview';
import SystemPerformance from './SystemPerformance';

const SystemPage = () => {
  const { prospectId } = useParams();
  return (
    <Switch>
      <Route path={SYSTEM_OVERVIEW_PATH}>
        <SystemOverview />
      </Route>
      <Route path={SYSTEM_PERFORMANCE_PATH}>
        <SystemPerformance />
      </Route>
      <Redirect to={pathToRoute(SYSTEM_OVERVIEW_PATH, { prospectId })} />
    </Switch>
  );
};

export default SystemPage;
