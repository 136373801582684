import React, { useState } from 'react';
import queryString from 'query-string';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { css } from '@emotion/core';
import { makeStyles, fade, useTheme } from '@material-ui/core/styles';
import { useOktaAuth } from '@okta/okta-react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { useSetDarkMode } from '@sunrun/mui-theme';

import useSearchParams from '../hooks/useSearchParams';
import SpotlightIcon from './SpotlightIcon';
import { DASH_BOARD_PATH } from '../constants/routes';
import mixpanel from '../utils/mixpanel';
import { useUserAuthInfo } from '../components/auth';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
      '&:focus': {
        width: '50ch',
      },
    },
  },
}));

const Header = () => {
  const setDarkMode = useSetDarkMode();
  const { oktaAuth } = useOktaAuth();
  const searchParams = useSearchParams();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState(searchParams.searchTerm ?? '');
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const { isViewer } = useUserAuthInfo();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const isMenuOpen = Boolean(anchorEl);
  const logout = () => {
    mixpanel.track('Logged Out', { Route: window.location.pathname });
    mixpanel.reset();
    oktaAuth.signOut();
  };

  return (
    <>
      <AppBar
        position="absolute"
        css={css`
          background-color: ${theme.palette.type === 'dark' ? '#333' : theme.palette.primary.main};
          z-index: 101;
          transition: ${theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })};
        `}
      >
        <Toolbar
          css={css`
            padding-right: 24;
          `}
        >
          <Link
            component={RouterLink}
            data-testid="header-logo-link"
            to={DASH_BOARD_PATH}
            underline="none"
            color="inherit"
            css={css`
              display: flex;
              flex-wrap: nowrap;
            `}
          >
            <SpotlightIcon />
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              Spotlight
            </Typography>
          </Link>
          <div
            css={css`
              flex-grow: 1;
              display: flex;
              justify-content: center;
            `}
          >
            {isViewer && (
              <div
                css={css`
                  position: relative;
                  margin-left: -78px;
                  border-radius: ${theme.shape.borderRadius}px;
                  background-color: ${fade(theme.palette.common.white, 0.15)};
                  &:hover {
                    background-color: ${fade(theme.palette.common.white, 0.25)};
                  }
                  width: 100%;
                  ${[theme.breakpoints.up('sm')]} {
                    width: auto;
                  }
                `}
              >
                <div
                  css={css`
                    padding: ${theme.spacing(0, 2)};
                    height: 100%;
                    position: absolute;
                    pointer-events: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <SearchIcon />
                </div>
                <InputBase
                  value={searchTerm}
                  placeholder="Search..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && searchTerm) {
                      history.push({
                        pathname: '/',
                        search: queryString.stringify({ searchTerm }),
                      });
                    }
                  }}
                />
              </div>
            )}
          </div>
          <Tooltip title="Toggle light/dark theme" enterDelay={300}>
            <IconButton
              color="inherit"
              onClick={() => setDarkMode(theme.palette.type === 'dark' ? false : true)}
              aria-label="Toggle light/dark theme"
              data-ga-event-category="header"
              data-ga-event-action="dark"
            >
              {theme.palette.type === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton>
          </Tooltip>
          <IconButton data-testid="logout-icon" color="inherit" onClick={handleProfileMenuOpen}>
            <PersonIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="primary-search-account-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Header;
