import { LOCAL, DEVELOP, STAGE, PRODUCTION } from './environments';

export const envApiUriMapping = {
  [LOCAL]: {
    customerBillingUri: process.env.LOCAL_BACKEND
      ? 'http://localhost:8080/graphql'
      : 'https://dev-spotlight-platform.appspot.com/graphql-api',
  },
  [DEVELOP]: { customerBillingUri: 'https://dev-spotlight-platform.appspot.com/graphql' },
  [STAGE]: { customerBillingUri: 'https://stage-spotlight-platform.appspot.com/graphql' },
  [PRODUCTION]: { customerBillingUri: 'https://prd-spotlight-platform.appspot.com/graphql' },
};
