import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const SpotlightIcon = ({ height, width, className }) => (
  <svg
    className={className}
    height={height}
    width={width}
    css={css`
      margin-right: 8px;
    `}
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 866 851"
  >
    <circle cx="437.75" cy="412.75" r="335" fill="#0087ff" />
    <path d="M438 78.5c-185 0-335 150-335 335s150 335 335 335z" fill="#001a3d" />
    <path d="M773 413c0-185-150-335-335-335 0 119 1.21 208.91 0 335z" fill="#00b085" />
    <path
      // eslint-disable-next-line max-len
      d="M286.53 481c-20.7 0-41.23-5.61-54.41-20.16a2.3 2.3 0 0 1-.59-2.49l14.36-16.09c.78-1 2.32-1.33 2.87-.59 11.24 11.63 24.64 14.92 39.54 14.92 14.13 0 25.35-4.85 25.35-15.11 0-9.86-9.85-13.92-27.85-19.17-31.39-9.3-48.07-20.14-48.07-45.12 0-24.38 21.51-37.17 49.39-37.17 18.4 0 37.78 4.46 48.21 18.2.79 1.16 1.18 2.13.4 2.9L322 375.28c-.58.56-2.13 1.34-3.11.35-10.63-10.06-23.44-11.63-32.16-11.63-15.29 0-21.1 6-21.1 13 0 9.68 7.14 13.75 31.19 20.73 27.29 7.91 43.78 16.83 43.78 43.19 0 27.29-25 40.07-54.07 40.07"
      fill="#fff"
    />
  </svg>
);

SpotlightIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

SpotlightIcon.defaultProps = {
  width: '36',
  height: '36',
};

export default SpotlightIcon;
