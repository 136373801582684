import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { css } from '@emotion/core';
import { DateTime } from 'luxon';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ViewBillButton from './ViewBillButton';

const MOST_RECENT_INVOICE_FRAGMENT = gql`
  fragment MostRecentInvoiceFields on Prospect {
    id
    billing(contactId: $contactId) {
      id
      transactions {
        id
        date
        invoiceKey
      }
    }
  }
`;

const MostRecentInvoice = ({ data }) => {
  const transactionWithMostRecentInvoice = data?.prospect?.billing?.transactions?.find(
    (transaction) => transaction.invoiceKey,
  );
  const { contactId } = useParams();
  if (
    !transactionWithMostRecentInvoice ||
    !transactionWithMostRecentInvoice.date ||
    DateTime.local()
      .diff(DateTime.fromISO(transactionWithMostRecentInvoice.date, { setZone: true }), 'months')
      .toObject().months >= 12
  ) {
    return null;
  }
  return (
    <Grid item xs>
      <Card
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <CardHeader title="Invoice" />
        <CardContent
          css={css`
            flex-grow: 1;
          `}
        ></CardContent>
        <CardActions>
          <ViewBillButton
            small={false}
            invoiceKey={transactionWithMostRecentInvoice.invoiceKey}
            invoiceDate={transactionWithMostRecentInvoice.date}
            contactId={contactId}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

MostRecentInvoice.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      billing: PropTypes.shape({
        transactions: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            invoiceKey: PropTypes.string,
          }),
        ),
      }),
    }),
  }),
};

export { MOST_RECENT_INVOICE_FRAGMENT };
export default MostRecentInvoice;
