import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';

const GATEWAY_INFORMATION_CARD_FRAGMENT = gql`
  fragment GatewayInformationCardFields on Prospect {
    id
    gateways {
      edges {
        node {
          id
          vendorName
          serialNumber
          manufacturer
        }
      }
    }
  }
`;

const SingleGateway = ({ loading, gatewayChars, gatewayName }) => (
  <Grid container direction="row" spacing={1} item xs={12}>
    <Grid item xs={12}>
      <Typography variant="h6">{gatewayName}</Typography>
    </Grid>
    <LabeledValue loading={loading} label="Manufacturer" text={gatewayChars.manufacturer} />
    <LabeledValue loading={loading} label="Serial Number" text={gatewayChars.serialNumber} />
    <LabeledValue loading={loading} label="Vendor" text={gatewayChars.vendorName} />
  </Grid>
);

SingleGateway.propTypes = {
  loading: PropTypes.bool.isRequired,
  gatewayName: PropTypes.string.isRequired,
  gatewayChars: PropTypes.shape({
    vendorName: PropTypes.string,
    serialNumber: PropTypes.string,
    manufacturer: PropTypes.string,
  }).isRequired,
};

const GatewayInformationCard = ({ loading, data }) => {
  const gateways = unwrapGraphQlList(data, 'prospect.gateways.edges');
  return (
    <CollapsableCard title="Gateway Information">
      <Grid container direction="column" spacing={2}>
        {gateways.length ? (
          gateways.map((gateway, i) => (
            <SingleGateway
              key={`gateway-${gateway.serialNumber}`}
              loading={loading}
              gatewayChars={gateway}
              gatewayName={`Gateway ${i + 1}`}
            />
          ))
        ) : loading ? (
          <SingleGateway
            key={`gateway-information-loading`}
            loading={true}
            gatewayChars={{}} // show a loading state
            gatewayName={`Gateway ?`}
          />
        ) : (
          <Typography align="center">No gateway information found</Typography>
        )}
      </Grid>
    </CollapsableCard>
  );
};

GatewayInformationCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      gateways: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: SingleGateway.propTypes.batteryChars,
          }),
        ),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
};

export { GATEWAY_INFORMATION_CARD_FRAGMENT };
export default GatewayInformationCard;
