import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import SearchContainer from './SearchContainer';
import Header from '../components/Header';

const Dashboard = () => {
  const location = useLocation();
  const [open, setOpen] = useState(Boolean(location.state?.invalidRoute));
  const [openRestrictedStatus, setOpenRestrictedStatus] = useState(Boolean(location.state?.restrictedStatus));
  return (
    <>
      <Header />
      <SearchContainer />
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={6000}>
        <Alert onClose={() => setOpen(false)} severity="info" elevation={6} variant="filled">
          That page appears to be missing. Lets start over.
        </Alert>
      </Snackbar>
      <Snackbar open={openRestrictedStatus} onClose={() => setOpenRestrictedStatus(false)} autoHideDuration={6000}>
        <Alert onClose={() => setOpen(false)} severity="info" elevation={6} variant="filled">
          You can&apos;t view that page due to customer status.
        </Alert>
      </Snackbar>
    </>
  );
};

export default Dashboard;
