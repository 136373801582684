import '@okta/okta-auth-js/polyfill';
import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
// eslint-disable-next-line
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute } from '@okta/okta-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@sunrun/mui-theme';

import LoginCallback from './components/LoginCallback';
import ErrorBoundary from './components/ErrorBoundary';
import { UserGateAndAuthProvider } from './components/auth';
import CybersourceIframeEventEmitter from './payment/CybersourceIframeEventEmitter';
import useOktaAuthConfig from './hooks/oktaAuthConfig';
import useEnvConfig from './hooks/useEnvConfig';
import { CYBERSOURCE_IFRAME_EVENTS_PATH } from './constants/routes';
import PropTypes from 'prop-types';

import '@sunrun/mui-theme/dist/fonts.css';
// not an issue with Github Actions
// error  Unable to resolve path to module './components/FlagsmithProviderContainer'  import/no-unresolved
// eslint-disable-next-line
import FlagsmithProviderContainer from './components/FlagsmithProviderContainer';

const AppRoutes = ({ oktaAuth }) => {
  const history = useHistory();
  const restoreOriginalUri = useCallback(
    async (_oktaAuth, originalUri) => {
      history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    },
    [history],
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path={CYBERSOURCE_IFRAME_EVENTS_PATH} component={CybersourceIframeEventEmitter} />
        <Route path="/implicit/callback" exact component={LoginCallback} />
        <SecureRoute component={UserGateAndAuthProvider} path="/" />
      </Switch>
    </Security>
  );
};
AppRoutes.propTypes = {
  oktaAuth: PropTypes.any,
};

// const WrappedApp = hot(() => {
const WrappedApp = () => {
  const { oktaConfig } = useEnvConfig();
  const oktaAuth = useOktaAuthConfig(oktaConfig);
  return (
    <ErrorBoundary>
      <Helmet>
        <title>Spotlight</title>
      </Helmet>
      <FlagsmithProviderContainer>
        <ThemeProvider>
          <Router>{oktaAuth ? <AppRoutes oktaAuth={oktaAuth} /> : <LinearProgress />}</Router>
        </ThemeProvider>
      </FlagsmithProviderContainer>
    </ErrorBoundary>
  );
};

ReactDOM.render(<WrappedApp />, global.document.getElementById('root'));
