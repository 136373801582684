import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CollapsableCard from '../components/CollapsableCard';
import LabeledValue from '../components/LabeledValue';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';
import mixpanel from '../utils/mixpanel';

const ARRAY_INFORMATION_CARD_FRAGMENT = gql`
  fragment ArrayInformationCardFields on Prospect {
    isVivintSolarPostPTO
    systemsCharacteristics {
      edges {
        node {
          id
          azimuth
          inverterMfg
          inverterModel
          numInverters
          numPanels
          panelMfg
          panelModel
          roofOffset
          tilt
          monitoringId
        }
      }
    }
  }
`;

const SingleArray = ({ loading, sysChars, arrayName, isVivintSolarPostPTO, enableInverterPortal }) => {
  let link = null;
  if (isVivintSolarPostPTO && enableInverterPortal && sysChars?.inverterMfg && sysChars?.monitoringId) {
    if (sysChars.inverterMfg.includes('SolarEdge')) {
      link = `https://monitoring.solaredge.com/solaredge-web/p/site/${sysChars.monitoringId}/#/dashboard`;
    } else if (sysChars.inverterMfg.includes('Enphase')) {
      // eslint-disable-next-line max-len
      link = `https://enlighten.enphaseenergy.com/systems/${sysChars.monitoringId}/arrays?range=today&view=energy_production`;
    } else if (sysChars.inverterMfg.includes('Fronius')) {
      link = `https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=${sysChars.monitoringId}`;
    } else if (sysChars.inverterMfg.includes('Tigo')) {
      link = `https://installations.tigoenergy.com/base/main/summary/sysid/${sysChars.monitoringId}`;
    }
  }

  if (link) {
    mixpanel.track('Inverter portal link activated', {
      Link: link,
      'Inverter Manufacturer': sysChars.inverterMfg,
      MonitoringId: sysChars.monitoringId,
      isVivintSolarPostPTO,
    });
  }
  return (
    <Grid container direction="row" spacing={1} item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6">{arrayName}</Typography>
      </Grid>
      <LabeledValue loading={loading} label="Number of Panels" text={sysChars.numPanels} />
      <LabeledValue loading={loading} label="Number of Inverters" text={sysChars.numInverters} />
      <LabeledValue loading={loading} label="Panel Manufacturer" text={sysChars.panelMfg} />
      <LabeledValue loading={loading} label="Panel Model" text={sysChars.panelModel} />
      <LabeledValue loading={loading} label="Inverter Manufacturer" text={sysChars.inverterMfg} link={link} />
      <LabeledValue loading={loading} label="Inverter Model" text={sysChars.inverterModel} />
      <LabeledValue loading={loading} label="Tilt" text={sysChars.tilt} />
      <LabeledValue loading={loading} label="Azimuth" text={sysChars.azimuth && Math.round(sysChars.azimuth)} />
      <LabeledValue loading={loading} label="Roof Offset" text={sysChars.roofOffset} />
    </Grid>
  );
};

SingleArray.propTypes = {
  loading: PropTypes.bool.isRequired,
  arrayName: PropTypes.string.isRequired,
  isVivintSolarPostPTO: PropTypes.bool,
  enableInverterPortal: PropTypes.bool,
  sysChars: PropTypes.shape({
    azimuth: PropTypes.string,
    inverterMfg: PropTypes.string,
    inverterModel: PropTypes.string,
    numInverters: PropTypes.number,
    numPanels: PropTypes.number,
    panelMfg: PropTypes.string,
    panelModel: PropTypes.string,
    roofOffset: PropTypes.string,
    tilt: PropTypes.string,
    monitoringId: PropTypes.string,
  }).isRequired,
};

const ArrayInformationCard = ({ loading, data, enableInverterPortal }) => {
  const systemsCharacteristics = unwrapGraphQlList(data, 'prospect.systemsCharacteristics.edges');
  const isVivintSolarPostPTO = data?.prospect?.isVivintSolarPostPTO;
  return (
    <CollapsableCard title="Array Information">
      <Grid container direction="column" spacing={2}>
        {systemsCharacteristics.length ? (
          systemsCharacteristics.map((sysChars, i) => {
            return (
              <SingleArray
                key={`array-information-${i}`}
                loading={loading}
                sysChars={sysChars}
                arrayName={`Array ${i + 1}`}
                isVivintSolarPostPTO={isVivintSolarPostPTO}
                enableInverterPortal={enableInverterPortal}
              />
            );
          })
        ) : loading ? (
          <SingleArray
            key={`array-information-loading`}
            loading={true}
            sysChars={{}} // show a loading state
            arrayName={`Array ?`}
          />
        ) : (
          <Typography align="center">No array information found</Typography>
        )}
      </Grid>
    </CollapsableCard>
  );
};

ArrayInformationCard.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      isVivintSolarPostPTO: PropTypes.bool,
      systemsCharacteristics: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: SingleArray.propTypes.sysChars,
          }),
        ),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
  enableInverterPortal: PropTypes.bool.isRequired,
};

export { ARRAY_INFORMATION_CARD_FRAGMENT };
export default ArrayInformationCard;
