import memoizeOne from 'memoize-one';
import { DateTime } from 'luxon';

export const prepareMaterialTableData = memoizeOne((values) => {
  let val = [];

  if (!values || !Array.isArray(values)) {
    val = values;
  } else {
    val = values.filter((v) => !!v);
  }

  return val ? JSON.parse(JSON.stringify(val)) : val;
});

/**
 * Takes an ISO 8601 date string and tries to format it as MM/DD/YYYY
 * while preserving the date's time zone and handling invalid date strings
 *
 * @param {string} dateString in ISO 8601 format
 * @return {string|null} formatted date string
 */
export const formatDateField = (dateString) => {
  if (typeof dateString === 'string' && dateString.length > 0) {
    // The setZone option sets returned date's timezone from the date string.
    // Incoming data currently is set set to offset 0.
    const parsed = DateTime.fromISO(dateString, { setZone: true });
    if (parsed.invalidReason) {
      return null;
    }
    return parsed.toLocaleString(DateTime.DATE_SHORT);
  }
  return null;
};

export const CustomerSupportRepTypes = {
  SALES_REP: 'Sales Representative',
  CX_PRO_PREINSTALL_REP: 'Installation Expert',
  CX_PRO_POSTINSTALL_REP: 'Activation Expert',
};

export const getDisplaySubTitle = (type) => {
  return CustomerSupportRepTypes[type] || type;
};
