import { useQuery, gql } from '@apollo/client';

const SITE_PAGE_QUERY = gql`
  query sitePageQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      id
      serviceContractNumber
      serviceContractStatus
      currentOwner {
        contactId
      }
      isVivintSolarPostPTO
      agreementType
      PTO
    }
  }
`;
const useGetSiteDetails = (prospectId) => {
  return useQuery(SITE_PAGE_QUERY, { variables: { prospectId }, returnPartialData: false });
};

export { SITE_PAGE_QUERY };
export default useGetSiteDetails;
