import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { getCustomerBillingClient } from './utils/get-apollo-client';
import { SITE_PATH, DASH_BOARD_PATH, STATUS_TRACKER_PATH } from './constants/routes';

import Dashboard from './dashboard';
import EvInstallStatusTrackerPage from './evInstallStatusTracker';
import NewVersionReloadNotification from './components/NewVersionReloadNotification';
import { HybridAuthTokenProvider } from './components/hybridAuthToken';
import { InvalidRoute } from './components/InvalidRoute';
import SiteAccessWrapper from './components/SiteAccessWrapper';

const App = () => {
  const { oktaAuth } = useOktaAuth();
  const customerBillingClient = getCustomerBillingClient(oktaAuth);

  return (
    <ApolloProvider client={customerBillingClient}>
      <HybridAuthTokenProvider>
        <Switch>
          <Route path={DASH_BOARD_PATH} exact>
            <Dashboard />
          </Route>
          <Route path={SITE_PATH}>
            <SiteAccessWrapper />
          </Route>
          <Route path={STATUS_TRACKER_PATH}>
            <EvInstallStatusTrackerPage />
          </Route>
          <InvalidRoute />
        </Switch>
        <NewVersionReloadNotification />
      </HybridAuthTokenProvider>
    </ApolloProvider>
  );
};

export default App;
