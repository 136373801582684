import React, { useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useLocation, useParams } from 'react-router';
import axios from 'axios';
import { useHybridAuthToken } from '../components/hybridAuthToken';
import SurveyContainer from './SurveyContainer';
import useEnvConfig from '../hooks/useEnvConfig';
import mixpanel from '../utils/mixpanel';
import { css } from '@emotion/core';
import { useSetDarkMode } from '@sunrun/mui-theme';

const EvInstallStatusTrackerPage = () => {
  const { surveyId, prospectId, contactId } = useParams();
  const location = useLocation();
  const setDarkMode = useSetDarkMode();
  const hybridAuth = useHybridAuthToken();

  const projectId = useMemo(() => {
    const queries = (location.search || '').replace('?', '').split('&');
    const pIdItem = queries.find((q) => {
      return q.split('=')[0] === 'projectId';
    });
    return pIdItem ? pIdItem.split('=')[1].trim() : undefined;
  }, [location]);

  const [error, setError] = useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isComplete, setComplete] = useState(false);

  const [initialStatusLoaded, setInitialStatusLoaded] = useState(false);

  const { statusTrackerAPIUrl } = useEnvConfig();

  const [showData, setShowData] = useState(false);

  useEffect(() => {
    setDarkMode(false);
  }, [setDarkMode]);

  useEffect(() => {
    (async () => {
      if (!isLoadingRequest && hybridAuth && !error && !initialStatusLoaded) {
        try {
          setIsLoadingRequest(true);

          const res = await axios.get(`${statusTrackerAPIUrl}/evInstallStatus/${prospectId}/${contactId}`, {
            headers: {
              Authorization: hybridAuth,
            },
          });

          const selfHomeAssessment = res.data.find((s) => s.name === 'selfHomeAssessment');
          if (selfHomeAssessment) {
            setInitialStatusLoaded(true);
            setComplete(selfHomeAssessment.isComplete);
          } else {
            throw new Error('Could not find selfHomeAssessment');
          }
        } catch (e) {
          setInitialStatusLoaded(true);
          setError(e);
          mixpanel.track(`Status tracker status pull error`);
        } finally {
          setIsLoadingRequest(false);
        }
      }
    })();
  }, [statusTrackerAPIUrl, prospectId, contactId, hybridAuth, isLoadingRequest, error, initialStatusLoaded]);

  const onBack = () => {
    setShowData(false);
  };

  const onFinish = () => {
    setComplete(true);
    setShowData(false);
  };

  const onError = () => {
    setError(new Error('Some error happened'));
    setShowData(false);
  };

  const onClick = () => {
    setShowData(true);
  };

  let content = null;

  if (error) {
    content = (
      <div
        css={css`
          margin-top: 20px;
        `}
      >
        <Typography align="center">Some error happened</Typography>
      </div>
    );
  } else if (isLoadingRequest || !hybridAuth || !initialStatusLoaded) {
    content = (
      <div
        css={css`
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          justify-content: center;
        `}
      >
        <CircularProgress />
      </div>
    );
  } else if (showData) {
    content = (
      <SurveyContainer
        surveyId={surveyId}
        authToken={hybridAuth}
        prospectId={prospectId}
        onBack={onBack}
        onFinish={onFinish}
        onError={onError}
        projectId={projectId}
      />
    );
  } else {
    content = (
      <div
        css={css`
          margin-top: 20px;
          padding-left: 16px;
          padding-right: 16px;
        `}
      >
        <Typography
          css={css`
            margin-bottom: 20px;
          `}
        >
          This EV charger installation home assessment should only be completed by qualified individuals and with the
          permission of the homeowner that is requesting the charger be installed. Select ‘Start Home Assessment’ to get
          started.
        </Typography>
        {isComplete ? (
          <Typography
            css={css`
              margin-bottom: 20px;
            `}
          >
            The EV charger installation home assessment for this prospect has already been completed. You can find the
            selected answers in SalesForce.
          </Typography>
        ) : (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            `}
          >
            <Button onClick={onClick} color="primary">
              Continue
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={9} lg={6}>
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            height: 100vh;
          `}
        >
          {content}
        </div>
      </Grid>
    </Grid>
  );
};

export default EvInstallStatusTrackerPage;
