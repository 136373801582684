import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery, useApolloClient } from '@apollo/client';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import mixpanel from '../utils/mixpanel';

const SIGNED_URL_QUERY = gql`
  query SignedUrl($s3Path: String) {
    signedUrl(s3Path: $s3Path) {
      id
      url
    }
  }
`;

const ViewDocumentButton = ({ s3Path, documentName, documentsTab }) => {
  const [getSignedUrl, { loading, error, data, called }] = useLazyQuery(SIGNED_URL_QUERY, {
    variables: { s3Path },
  });

  // check to see if we already have the URL in the cache
  let urlFromCache;
  const client = useApolloClient();
  try {
    const cachedData = client.readQuery({ query: SIGNED_URL_QUERY, variables: { s3Path } });
    urlFromCache = cachedData?.signedUrl?.url;
  } catch (err) {
    // no data in the cache
  }
  const url = data?.signedUrl?.url || urlFromCache;

  useEffect(() => {
    if (called && url) {
      window.open(url);
    }
  }, [called, url]);

  if (loading) {
    return (
      <Tooltip title="Loading document">
        <CircularProgress size={20} />
      </Tooltip>
    );
  }

  if (error) {
    return (
      <Tooltip title="Error loading document">
        <ErrorIcon color="error" fontSize="small" />
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Open document in new tab">
      <IconButton
        size="small"
        aria-label="Open document in new tab"
        onClick={() => {
          mixpanel.track('Opened Document', {
            'Document Name': documentName,
            'Document Tab Name': documentsTab,
          });
          if (url) window.open(url);
          else getSignedUrl();
        }}
      >
        <OpenInNewIcon color={url ? 'primary' : 'secondary'} fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

ViewDocumentButton.propTypes = {
  s3Path: PropTypes.string.isRequired,
  documentName: PropTypes.string.isRequired,
  documentsTab: PropTypes.string.isRequired,
};

export { SIGNED_URL_QUERY };

export default ViewDocumentButton;
