import React, { Component } from 'react';
import PropTypes from 'prop-types';
import rollbar from 'utils/rollbar';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    rollbar.error(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <p>Customer information couldn’t be loaded, please contact your support team.</p>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
