import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';
import DocumentsTable, { DOCUMENT_TABLE_FRAGMENT } from './DocumentsTable';

const PROPOSAL_DOCUMENTS_TABLE_QUERY = gql`
  query ProposalDocumentsTableQuery($prospectId: String!, $noFilter: Boolean) {
    prospect(prospectId: $prospectId) {
      id
      proposalDocuments(noFilter: $noFilter) {
        edges {
          node {
            ...DocumentTableFields
          }
        }
      }
    }
  }
  ${DOCUMENT_TABLE_FRAGMENT}
`;

const ProposalDocumentsTable = ({ viewAll }) => {
  const { prospectId } = useParams();
  const { data, loading } = useQuery(PROPOSAL_DOCUMENTS_TABLE_QUERY, { variables: { prospectId, noFilter: viewAll } });
  const documents = unwrapGraphQlList(data, 'prospect.proposalDocuments.edges');
  return <DocumentsTable documents={documents} loading={loading} documentsTab="Proposal" />;
};

ProposalDocumentsTable.propTypes = {
  viewAll: PropTypes.bool.isRequired,
};

export { PROPOSAL_DOCUMENTS_TABLE_QUERY };
export default ProposalDocumentsTable;
