import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import mixpanel from '../utils/mixpanel';

const CollapsableCard = ({ title, children, mixpanelEventName, className, initiallyExpanded }) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  return (
    <Card className={className}>
      <CardHeader
        action={
          <IconButton
            css={(theme) => css`
              transform: rotate(${expanded ? '180deg' : '0deg'});
              margin-left: auto;
              transition: ${theme.transitions.create('transform', { duration: theme.transitions.duration.shortest })};
            `}
            onClick={() => {
              mixpanel.track(mixpanelEventName ?? `Toggled ${title} card expand/collapse`, {
                action: expanded ? 'collapse' : 'expand',
              });
              setExpanded(!expanded);
            }}
            aria-expanded={expanded}
            aria-label={expanded ? 'collapse' : 'expand'}
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        title={title}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          css={(theme) => ({
            borderTop: '1px solid ' + theme.palette.divider,
          })}
        >
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
};

CollapsableCard.propTypes = {
  children: PropTypes.any.isRequired,
  mixpanelEventName: PropTypes.string,
  title: PropTypes.any.isRequired,
  className: PropTypes.string,
  initiallyExpanded: PropTypes.bool,
};

CollapsableCard.defaultProps = {
  initiallyExpanded: true,
};

export default CollapsableCard;
