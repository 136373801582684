export const ON_TIME = 'ON_TIME';
export const PAID_IN_FULL = 'PAID_IN_FULL';
export const CREDIT = 'CREDIT';
export const OVERDUE = 'OVERDUE';
export const IN_COLLECTIONS = 'IN_COLLECTIONS';
export const BLUE = 'Blue';

export const friendlyBillingStatuses = {
  ON_TIME: 'On Time',
  PAID_IN_FULL: 'Paid in Full',
  CREDIT: 'Credit',
  OVERDUE: 'Overdue',
  IN_COLLECTIONS: 'In Collections',
};
