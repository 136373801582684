import realMixpanel from 'mixpanel-browser';
import getEnv from './get-env';
import { STAGE, PRODUCTION } from '../constants/environments';

delete window.mixpanel; // remove from global scope, force importing from here

const HOSTNAME = window.location.hostname;
const environment = getEnv(HOSTNAME);

let mixpanel;
if (environment === PRODUCTION) {
  mixpanel = realMixpanel;
  mixpanel.init('e827ca524fe66e436c9a60b815fa35cf');
} else if (environment === STAGE) {
  mixpanel = realMixpanel;
  mixpanel.init('4b4adf547967b7d425ee23b791550dfe');
} else {
  if (process.env.LOG_MIXPANEL) {
    mixpanel = {
      reset: (...args) => console.info('[MIXPANEL reset]', ...args), // eslint-disable-line no-console
      identify: (...args) => console.info('[MIXPANEL identify]', ...args), // eslint-disable-line no-console
      register: (...args) => console.info('[MIXPANEL register]', ...args), // eslint-disable-line no-console
      unregister: (...args) => console.info('[MIXPANEL unregister]', ...args), // eslint-disable-line no-console
      people: {
        set: (...args) => console.info('[MIXPANEL set]', ...args), // eslint-disable-line no-console
      },
      track: (...args) => console.info('[MIXPANEL track]', ...args), // eslint-disable-line no-console
    };
  } else {
    mixpanel = {
      reset: () => null,
      identify: () => null,
      register: () => null,
      unregister: () => null,
      people: {
        set: () => null,
      },
      track: () => null,
    };
  }
}

const trackUser = (user) => {
  mixpanel.reset(); // ensure we are not still tracking super properties from a previous session

  mixpanel.identify(user.email);
  mixpanel.people.set({
    $email: user.email,
    $name: user.name,
    $first_name: user.given_name,
    $last_name: user.family_name,
    'Cost center': user.cost_center,
    Department: user.department,
    'Job code': user.job_code,
    Title: user.title,
  });
};

export default mixpanel;
export { trackUser };
