import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';

import tableIcons from '../constants/tableIcons';
import formatPhone from '../utils/format-phone';
import { prepareMaterialTableData } from '../utils/helpers';

const SITE_DETAILS_CONTACTS_QUERY = gql`
  query SiteDetailsContactsQuery($prospectId: String!) {
    prospect(prospectId: $prospectId) {
      id
      contacts {
        id
        contactId
        name
        phone
        email
        relation
        isPrimary
        roles
      }
    }
  }
`;

const ContactsTable = ({ title, data: dt, loading }) => {
  const data = prepareMaterialTableData(dt);

  return (
    <MaterialTable
      title={title}
      columns={[
        { title: 'Name', field: 'name' },
        { title: 'Email', field: 'email' },
        { title: 'Phone', field: 'phone', render: ({ phone }) => formatPhone(phone) },
        { title: 'Relation', field: 'relation', emptyValue: '--' },
        { title: 'Primary', field: 'isPrimary', type: 'boolean' },
        {
          title: 'Roles',
          field: 'roles',
          render: (rowData) => rowData.roles.join(', '),
        },
      ]}
      data={data}
      isLoading={loading}
      options={{
        paging: data.length > 5,
        draggable: false,
        showEmptyDataSourceMessage: !loading,
        search: true,
      }}
      icons={tableIcons}
    />
  );
};

ContactsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      relation: PropTypes.string,
      roles: PropTypes.any,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

const SiteDetailsContacts = () => {
  const { prospectId } = useParams();
  const { loading, data } = useQuery(SITE_DETAILS_CONTACTS_QUERY, {
    variables: { prospectId },
    returnPartialData: true,
  });
  const contacts = data?.prospect?.contacts || [];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ContactsTable title="Contacts" data={contacts} loading={loading} />
      </Grid>
    </Grid>
  );
};

export { SITE_DETAILS_CONTACTS_QUERY };
export default SiteDetailsContacts;
