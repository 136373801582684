import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@emotion/core';
import { PaymentAmountRadioOptions, PaymentFormLabels } from './paymentConstants';
import formatCurrency from '../../utils/format-currency';
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from '@material-ui/core';

const PaymentAmountOptions = ({
  totalAmountDue,
  selectedPaymentAmountOption,
  handlePaymentAmountOptionsOnChange,
  handleCustomPaymentAmountOnChange,
}) => {
  const handleRadioOnChange = (event) => {
    handlePaymentAmountOptionsOnChange(event.target.value);
  };

  // this needs validation and formatting the display in currency - out of scope
  const handleCustomPaymentAmount = (event) => {
    handleCustomPaymentAmountOnChange(event.target.value);
  };

  return (
    <FormControl fullWidth={true} data-testid="payment-amount-container">
      <FormLabel id="payment-amount-label">
        <Typography
          variant="h5"
          component="span"
          css={css`
            color: #000;
          `}
        >
          {PaymentFormLabels.PAYMENT_AMOUNT}
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby="payment-amount-label"
        value={selectedPaymentAmountOption}
        name="payment-amount-group"
        onChange={handleRadioOnChange}
        row
      >
        <Box sx={{ display: 'flex', gap: '40px', marginTop: '16px' }}>
          <Box>
            <Box>{PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE}</Box>
            <FormControlLabel
              data-testid="full-balance-label"
              value={PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE}
              control={<Radio />}
              label={formatCurrency(totalAmountDue)}
            />
          </Box>
          <Box>
            <Box>{PaymentAmountRadioOptions.OTHER_AMOUNT}</Box>
            <FormControlLabel
              data-testid="custom-amount-label"
              value={PaymentAmountRadioOptions.OTHER_AMOUNT}
              control={<Radio />}
              label={
                <TextField
                  inputProps={{ 'data-testid': 'custom-amount-input' }}
                  placeholder="$0.00"
                  onChange={handleCustomPaymentAmount}
                  onFocus={() => handlePaymentAmountOptionsOnChange(PaymentAmountRadioOptions.OTHER_AMOUNT)}
                />
              }
            />
          </Box>
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

PaymentAmountOptions.propTypes = {
  totalAmountDue: PropTypes.number.isRequired,
  selectedPaymentAmountOption: PropTypes.string.isRequired,
  handlePaymentAmountOptionsOnChange: PropTypes.func.isRequired,
  handleCustomPaymentAmountOnChange: PropTypes.func.isRequired,
};

export default PaymentAmountOptions;
